import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import AuthWrapper from "../AuthWrapper";
import InputField from "components/InputField";
import Checkbox from "components/Checkbox/Checkbox";
import { ReactComponent as LoginLogo } from "../../../assets/logos/login-logo.svg";
import "./ResetPassword.scss";
import {useQuery} from "Utils/hooks";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";


function ResetPassword() {

	const [code,setCode] = useState(null);
	const [phase,setPhase] = useState(1);
	const navigation = useNavigate();
	const dispatch = useDispatch();

	const resetPassword = (password) => {
		if(!password){
			return;
		}

		axiosWithAuth().post("auth/password/forgot/new",{code,password}).then(res=>{
			if(res.status===200){
				navigation("/dashboard");
				dispatch(addAlert({text:"Contraseña cambiada correctamente", type:"success"}))
			}
		}).catch(err=>{
			if(err.response.status === 403){
				dispatch(addAlert({text:"El codigo ha expirado o no es valido.", type:"error"}))
			}else{
				console.log(err);
				dispatch(addAlert({text:"Error al cambiada contraseña", type:"error"}))
			}
		})
	}

	const renderPhase = () => {
		if(phase===2){
			return <Phase2 setPhase={setPhase} resetPassword={resetPassword}/>	
		}else{
			return <Phase1 code={code} setCode={setCode} setPhase={setPhase}/>;
		}
	}

	return (
		<AuthWrapper useContainer>
			{renderPhase()}
		</AuthWrapper>
	);
}

export default ResetPassword;

const Phase1 = ({code,setCode,setPhase}) => {

	const [lcode,setLCode] = useState("");
	const query = useQuery();
	const dispatch = useDispatch();

	

	useEffect(()=>{
		setLCode(query.get("code") || "");
	},[])

	const nextPhase = (e) => {
		e.preventDefault();
		if(code){
			setPhase(2);
		}else{
			// Change to our style of alert. And show errors
			alert("There are fileds required"); 
		}
	}

	const verifyCode = () => {
		axiosWithAuth().post("auth/password/forgot/code",{code:lcode}).then(res=>{
			if(res.status===200){
				setCode(lcode);
			}
		}).catch(err=>{
			if(err.response.status === 403){
				dispatch(addAlert({text:"El codigo ha expirado o no es valido.", type:"error"}))
			}else{
				dispatch(addAlert({text:"Error al cambiada contraseña", type:"error"}))
			}
		})
	}

	useEffect(()=>{
		if(lcode.length>=8){
			verifyCode();
		}
	},[lcode])


	return (<div className="ResetPassword">
		<div className="header">
			<h1 className="title">Recuperacion<br/>de la cuenta</h1>
			<p className="subtitle">Introduce el codigo enviado a tu correo electronico</p>
		</div>

		<form className="code" action="code" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField
					name="code"
					value={lcode}
					setValue={setLCode}
					label="Codigo de verificacion"
					maxLength={8}
					readOnly={code}
				/>
			</div>
			<div className="cta-actions">
				<Button className="next" variant="secondary" type="submit" disabled={!code}>
					Siguiente
				</Button>
			</div>
		</form>

	</div>)
}

const Phase2 = ({resetPassword}) => {

	const [password,setPassword] = useState("");
	const [passwordConfirm,setPasswordConfirm] = useState("");
	const [showPassword,setShowPassword] = useState(false);
	const dispatch = useDispatch();

	const nextPhase = (e) => {
		e.preventDefault();
		if(password && (password === passwordConfirm)){
			resetPassword(password);
		}else{
			dispatch(addAlert({text:"Las contraseñas no coinciden", type:"error"}))
		}
	}

	return (<div className="ResetPassword">
		<div className="header">
			<h1 className="title">Recuperacion<br/>de la cuenta</h1>
			<p className="subtitle">Cual quieres que sea tu nueva contraseña</p>
		</div>

		<form className="password" action="register" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField 
					name="password"
					value={password}
					setValue={setPassword}
					label="Nueva contraseña"
					type={showPassword?"text":"password"}
				/>
				<InputField 
					name="passwordConfirmation"
					value={passwordConfirm}
					setValue={setPasswordConfirm}
					label="Repetir contraseña"
					type={showPassword?"text":"password"}
				/>
			</div>
			<div className="actions">
				<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
			</div>
			<div className="cta-actions">
				<Button className="next" variant="secondary" type="submit">
					Cambiar contraseña
				</Button>
			</div>
		</form>

	</div>)
}