import {logout, sessionExpired} from "Actions/UserActions";
import axios from "axios"
import {store} from "index";

const authWhitelistedUrls = ['users',"auth/login"];

export const axiosWithAuth = () => {
    const instance = axios.create({
        baseURL:process.env.REACT_APP_API_URL,
        withCredentials:true,
		headers:{
			"X-API-KEY": process.env.REACT_APP_API_KEY
		}
    });


    instance.interceptors.response.use(
        response => {
            return response;
        },
        error => {

            if(error.response && error.response.status === 401 && !authWhitelistedUrls.includes(error.config.url)) {	
				store.dispatch(sessionExpired())
            }     
			return Promise.reject(error);
        }
    );


	return instance;
}