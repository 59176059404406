import React, {useState, useEffect} from "react";
import "./SideNav.scss";
import {ArrowIcon, NavigationIcons} from "assets";
import classNames from "classnames";
import NavigationLink from "./NavigationLink";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/Button";
import {logout} from "Actions/UserActions";

const locations = {
   
	public:[
		{ path: "/dashboard", text: "Mi panel de control", iconLeft: <NavigationIcons.Dashboard/> },
		{ path: "/transactions", text: "Mis Transaciones", iconLeft: <NavigationIcons.Transactions/> },
		{ path: "/withdrawals", text: "Retiros", iconLeft: <NavigationIcons.Withdrawals/> },
		{ path: "/sharehistory", text: "Historial de acciones", iconLeft: <NavigationIcons.SharesHistory/> },
		{ path: "/products", text: "Mis Productos", iconLeft: <NavigationIcons.Products/> },
		{ path: "/affiliates", text: "Mis referidos", iconLeft: <NavigationIcons.Affiliates/> },
		{ path: "/payments", text: "Mis pagos", iconLeft: <NavigationIcons.Transactions/> }
	],
	settings:[
		{ path: "/password/change", text: "Cambiar contraseña", iconLeft: <NavigationIcons.Settings.ChangePassword/> },
		{ path: "/profile", text: "Detalles personales", iconLeft: <NavigationIcons.Settings.Settings/> },
		{ path: "/sessions", text: "Sesiones", iconLeft: <NavigationIcons.Settings.Sessions/> }
	]
}




function SideNav() {

	const [isExpanded,setIsExpanded] = useState(false);
	const [permissions,setPermissions] = useState([]);
	const [adminLinks,setAdminLinks] = useState([]);
	const user = useSelector(store=>store.UserReducer).data;
	const dispatch = useDispatch();

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
	}

	useEffect(()=>{
		fetchPermissions();
	},[])

	useEffect(()=>{
		setAdminLinks(assignAdminLinks(permissions,user.id))
	},[permissions])

	const fetchPermissions = () => {
		axiosWithAuth().get("/permissions/user").then(res=>{
			setPermissions(res.data);
		});
	}

	return (
		<div className={classNames("SideNav",isExpanded?"expanded":"collapsed")}>


			<div className="top">
				{/* <div className="info">
					Fintech-Genesis
				</div> */}

				<div className="controls" onClick={toggleExpanded}>
					<ArrowIcon/>
				</div>

			</div>

			<div className="locations">

				<div className="public">
					{locations.public.map(link => {
						return <NavigationLink
							path={link.path}
							iconLeft={link.iconLeft}
							text={link.text}
							key={link.path}
						/>
					})}
				</div>

				<div className="settings">
					{locations.settings.map(link => {
						return <NavigationLink
							path={link.path}
							iconLeft={link.iconLeft}
							text={link.text}
							key={link.path}
						/>
					})}
				</div>

				<div className="admin">
					{adminLinks.map(link => {
						return <NavigationLink
							path={link.path}
							iconLeft={link.iconLeft}
							text={link.text}
							key={link.path}
						/>
					})}
				</div>


			</div>

			<div className="bottom">
				<Button className="disconnect" action={()=>{
					dispatch(logout())
				}}>Desconectar</Button>
			</div>

		</div>
	);
}

export default SideNav;


const assignAdminLinks = (permissions, userId) => {
	const newPermissions = [];

	if (permissions.length > 0) {
		permissions.forEach(({ name, id }) => {
			switch (name) {
				case "canViewUsers":
					newPermissions.push({ path: "/admin/users", text: "Usuarios", iconLeft: <NavigationIcons.Users />, key: id });
					break;
				case "canViewProducts":
					newPermissions.push({ path: "/admin/products", text: "Productos", iconLeft: <NavigationIcons.Admin.Products />, key: id });
					break;
				case "canViewRoles":
					newPermissions.push({ path: "/admin/roles", text: "Roles", iconLeft: <NavigationIcons.Admin.Roles />, key: id });
					break;
				case "canViewPermissions":
					newPermissions.push({ path: "/admin/permissions", text: "Permisos", iconLeft: <NavigationIcons.Admin.Permissions />, key: id });
					break;
				case "canViewWalletTypes":
					newPermissions.push({ path: "/admin/wallet/types", text: "Tipos de wallet", iconLeft: <NavigationIcons.WalletType />, key: id });
					break;
				case "canViewAdminLog":
					newPermissions.push({ path: "/admin/log", text: "Registro administradores", iconLeft: <NavigationIcons.Admin.Log />, key: id });
					break;
				case "canCreateUsers":
					if (process.env.REACT_APP_CAN_AFFILIATE_USERS === "TRUE") newPermissions.push({ path: "/admin/user/new", text: "Afiliar usuario", iconLeft: <NavigationIcons.Admin.AffiliateUser />, key: id });
					break;
				case "canSeeCodesRegistration":
					newPermissions.push({ path: "/admin/affiliationcodes", text: "Codigos de afiliacion", iconLeft: <NavigationIcons.Admin.AffiliateCodes />, key: id });
					break;
				case "canViewContactRequests":
					newPermissions.push({ path: "/admin/contact", text: "Solicitudes contacto", iconLeft: <NavigationIcons.Admin.ContactRequest />, key: id });
					break;
				case "canViewWithdrawals":
					newPermissions.push({ path: "/admin/withdrawals", text: "Retiros", iconLeft: <NavigationIcons.Admin.Withdrawals />, key: id });
					break;
				default:
					return;
			}
		});

		if (userId === 1) {
			newPermissions.push({ path: "/admin/integratesystem", text: "Sistema Integrado", iconLeft: <NavigationIcons.Admin.IntegrateSystem />, key: "int" });
			newPermissions.push({ path: "/admin/webhooks", text: "Webhooks", iconLeft: <NavigationIcons.Admin.Admin />, key: "whevents" });
		}
	}

	return newPermissions
}