import classNames from "classnames";
import React from "react";
import "./List.scss";

function List({
	className,
	scrollable=false,
	children
}) {
	return (
		<div className={classNames("List",{
			[className]:className
		})}>
			<div className={classNames("List-container",{
				scrollable
			})}>
				{children}
			</div>
		</div>
	);
}

List.Item = ({
	className,
	action,
	children
}) => {
	return (
		<div className={classNames("List-Item",className, {
			clickable: action
		})} onClick={action}>
			{children}
		</div>
	)
}	


export default List;


