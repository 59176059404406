import React from "react";
import "./Button.scss";

function Button({className="",variant="primary",children,action,type="button",disabled}) {
	return (
		<button className={`Button ${variant} ${className}`} onClick={action} type={type} disabled={disabled}>
			{children}
		</button>
	);
}

export default Button;