import React from "react";
import "./DateRange.scss";
import InputField from "components/InputField/InputField";

function DateRange({className="",dateRange,setDateRange}) {

	const dateHandler = (e) => {
		const newDateRange = dateRange;
		newDateRange[e.target.name] = e.target.value;

		if(newDateRange.eDate<newDateRange.sDate){
			newDateRange.eDate=newDateRange.sDate;
		}

		setDateRange({...newDateRange});
	}

	return (
		<div className={`DateRange ${className}`}>		
			<InputField
				label="Desde"
				type="date"
				name="sDate"
				value={dateRange?.sDate}
				onChange={dateHandler}
				max={dateRange?.eDate}
			/>
			<InputField
				label="Hasta"
				type="date"
				name="eDate"
				value={dateRange?.eDate}
				onChange={dateHandler}
				min={dateRange?.sDate}
			/>
		</div>
	);
}

export default DateRange;