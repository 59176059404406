import {addAlert} from "Actions/SystemActions";
import {fetchLoggedUser} from "Actions/UserActions";
import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import React,{useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./ChangeEmail.scss";

function ChangeEmail() {	
	const [code,setCode] = useState('');
	const [error,setError] = useState(null)
	const loggedIn = useSelector(store=>store.UserReducer.loggedIn);
	const status = useSelector(store=>store.UserReducer.data?.status);

	const navigate = useNavigate();
	const dispatch = useDispatch();		

	useEffect(()=>{
		if(code.length === 8){
			axiosWithAuth().patch("users/email/"+code).then(res=>{
				if(res.status === 200){
					navigate("/dashboard");
					dispatch(fetchLoggedUser());
					dispatch(addAlert({text:"Email verificado correctamente", type:"success"}));
				}
			}).catch(err=>{				
				if(err?.response?.status === 404){
					setError("El codigo no es valido");
				}else if(err?.response?.status === 409){
					navigate("/dashboard");
					dispatch(fetchLoggedUser());
					dispatch(addAlert({text:"El Email ya ha sido verificado", type:"error"}));
				}
			})
		}
		if(code.length>0 && error){
			setError(null);
		}
	},[code])	

	return (
		<div className="ChangeEmail">			
			<div className="ChangeEmail-content">
				<Header title="Confirma tu cambio de correo" subtitle="Antes de continuar debes introducir el codigo enviado a tu correo electronico"/>	
				<InputField
					name="code"
					label="Codigo de verificacion"
					value={code}
					setValue={setCode}
					error={error}
					maxLength={8}
				/>				
			</div>
		</div>
	);
}

export default ChangeEmail;