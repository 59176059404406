import classNames from "classnames";
import React,{useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";

function NavigationLink({iconLeft,iconRight,text,path="/"}) {


	const location = useLocation();
	const [active,setActive] = useState(false);

	useEffect(()=>{
		if(location.pathname.startsWith(path) || (path === "/admin/users" && location.pathname.startsWith("admin/user"))){
			setActive(true);
		}else{
			setActive(false)
		}
	},[location])

	return (
		<Link className={classNames("NavigationLink",active?"active":null)} to={path}>		
			{iconLeft?<span className="icon icon-left">{iconLeft}</span>:null}
			<p>{text}</p>	
			
			{/* <span className="tooltip"> 
			TODO REWORK
				{text}
			</span> */}

			{iconRight?<span className="icon icon-right">{iconRight}</span>:null}
		</Link>
	);
}

export default NavigationLink;