import React,{useState,useEffect} from "react";
import {TableItem,Table} from "components/Table";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Header from "components/Header";
import "./SharesHistory.scss";
import useFetchShares from "Utils/hooks/useFetchShares";
import {SharesTable} from "components/Table";
import {useSelector} from "react-redux";


function SharesHistory() {



	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const {
		transactions,
		pagination,
		setPage
	} = useFetchShares(selectedOrganization?.id);



	return (
		<div className="SharesHistory">
			<Header title={"Historial de acciones"}/>

			<div className="wrapper">

				<SharesTable
					transactions={transactions}
					pagination={pagination}
					setPage={setPage}
				/>
		
			</div>
		</div>
	);
}

export default SharesHistory;