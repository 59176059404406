import {addAlert, updateOrganization} from "Actions/SystemActions";
import {SharesContext} from "Context/AdminDashboardContext";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import useFetchShares from "Utils/hooks/useFetchShares";
import Button from "components/Button";
import Card from "components/Card";
import Confirmation from "components/Confirmation";
import Dropdown from "components/Dropdown";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import {SharesTable} from "components/Table";
import Textarea from "components/Textarea";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

function SharesPanel() {

	const { shares } = useContext(SharesContext);
	const [showHistory,setShowHistory] = useState(false);

	const renderSharesBalance = () => {
		if(shares.length === 0){
			return 0;
		}else if(shares.length === 1){
			return shares[0].balance
		}else{
			return shares.reduce((acc,curr) => acc + Number(curr.balance) ,0)
		}
	}

	return (
		<Card className="Panel Panel-Shares">
			<Card.Title>
				Acciones
			</Card.Title>
			<Card.Content>
				<p className="total">
					{renderSharesBalance()}
				</p>
			</Card.Content>
			<Card.Controls borderless>
				<Button 
					variant="tertiary"
					action={()=>{setShowHistory(true)}}
				>
					Ver historial
				</Button>
			</Card.Controls>


			{showHistory?
			
				<SharesHistoryModal
					closeModal={() => setShowHistory(false)}
				/>
			:null}

		</Card>
	);
}

export default SharesPanel;

const SharesHistoryModal = ({closeModal}) => {
	const { userId } = useParams();
	const [showAssignSModal,setShowAssignSModal] = useState(false);
	const {
		transactions,
		fetchSharesHistory,
		pagination,
		setPage
	} = useFetchShares(userId);
	const dispatch = useDispatch();

	const addShares = (quantity,description) => {
		axiosWithAuth().post("users/shares",{receiverId:userId, description, quantity}).then(res=>{
			if(res.status===201){
				setShowAssignSModal(false);
				if(quantity>0){
					dispatch(addAlert({text:`${quantity} asignados`, type:"success"}))
				}else{
					dispatch(addAlert({text:`${quantity*-1} removidos`, type:"success"}))
				}
				setShowAssignSModal(false);
				fetchSharesHistory();
			}
		})
	}

	return (<Modal className="Modal-History Shares"
		closeModal={closeModal}
		showCloseIcon
	>
		<Card className="user-shares section">
			<div className="top">
				<Header title={"Historial de acciones"} 
					// subtitle={"Balance total: " + (sharesBalance || 0)}
				/>
				<Button
					variant="secondary"
					action={() => {
						setShowAssignSModal(true);
					}}
				>
					Agregar acciones
				</Button>
			</div>


			<SharesTable
				transactions={transactions}
				pagination={pagination}
				setPage={setPage}
			/>
			{/* Table here */}

			{showAssignSModal?
				<Modal showCloseIcon closeModal={()=>{setShowAssignSModal(false)}} >
					<div className="modal-content addShares">
						<AddSharesModal 
							addShares={addShares} 
							setShowAssignSModal={setShowAssignSModal} 
							userId={userId}
							// sharesBalance={(sharesBalance || 0)}
						/>
					</div>
				</Modal>
			:null}
		</Card>



	</Modal>
		
	);
}

const AddSharesModal = ({setShowAssignSModal,sharesBalance,userId}) => {

	const [quantity,setQuantity] = useState(null);
	const [description,setDescription] = useState("");
	const [showConfirm,setShowConfirm] = useState(false);
	const {organizations,selectedOrganization} = useSelector(store => store.SystemReducer);
	const { shares, fetchShares } = useContext(SharesContext);
	const dispatch = useDispatch();


	const addShares = () => {
		axiosWithAuth().post("users/shares",{receiverId:userId, description, quantity,organizationId:selectedOrganization.id}).then(res=>{
			if(res.status==201){				
				setShowAssignSModal(false);
				if(quantity>0){
					dispatch(addAlert({text:`${quantity} asignados`, type:"success"}))
					fetchShares();
				}else{
					dispatch(addAlert({text:`${quantity*-1} removidos`, type:"success"}))
				}
				setShowAssignSModal(false);
			}
		})
	}

	const handleAdd = () => {
		if(!selectedOrganization.id){
			dispatch(addAlert({text:"Debes seleccionar una organizacion"}));
		}else if(!quantity || quantity===0){
			dispatch(addAlert({text:"La cantidad es 0", type:"error"}))
		}else if( shares[0] && (Number(shares[0].balance) + Number(quantity)) < 0){
			dispatch(addAlert({text:"El nuevo balance es inferior a 0", type:"error"}))
		}else{
			setShowConfirm(true);
		}
	}

	return (<Modal className="addShares">
		<Card>
			<Header title="Añadir acciones"/>
			<div className="add-area">
				<InputField
					label="Cantidad"
					value={quantity}
					setValue={setQuantity}
					type="number"
					min={1}
					max={25}
				/>
				<Dropdown 
					title={selectedOrganization?.name || "Organizacion"}
				>
					{Object.values(organizations).map(org => <Dropdown.Item 
						key={org.id}
						action={()=>{
							dispatch(updateOrganization(org.id))
						}}
					
					>
						{org.name}
					</Dropdown.Item>)}

				</Dropdown>


				<div className="info">
				{shares[0]?<>
						<p>Balance nuevo: {Number(shares[0].balance) + Number(quantity)}</p>
						<p>UUID: {shares[0].uuid}</p>
				</>:<>
					<p>Este usuario aun no tiene una wallet de shares para esa orgnanizacion. Si decides continuar esta sera creada</p>
				</>}
				</div>

				<Textarea 
					label="Descripcion"
					value={description}
					setValue={setDescription}
					maxLength={500}
				/>
			</div>
			



			<div className="controls">
				<Button variant="secondary" action={()=>{setShowAssignSModal(null)}}>Cancelar</Button>
				<Button variant="primary" action={handleAdd}>Agregar</Button>
			</div>

			{showConfirm?
				<Confirmation
					title="Asignar producto?"
					description={`Desea asignar la cantidad de "${quantity}" al usuario?`}
					closeModal={()=>{setShowConfirm(false)}}
					accept={()=>{addShares(quantity,description)}}
				/>

			:null}

		</Card>
	</Modal>)
}