import {addAlert} from "Actions/SystemActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Header from "components/Header/Header";
import {WithdrawalsTable} from "components/Table";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import WithdrawalModal from "Modals/WithdrawalModal";
import Checkbox from "components/Checkbox/Checkbox";
import "./Withdrawals.scss";

function Withdrawals() {

	const [withdrawals,setWithdrawals] = useState([]);
	const [withdrawalModal,setWithdrawalModal] = useState(null); 
	const [page,setPage] = useState(1);
	const [status,setStatus] = useState("pending");
	const [pagination,setPagination] = useState({});
	const dispatch = useDispatch();

	useEffect(()=>{
		fetchWithdrawals();
	},[page,status])

	const fetchWithdrawals = () => {
		axiosWithAuth().get("/withdrawals/admin/",{
			params:{
				// userId:2 // TBI
				page,
				status
			}
		}).then(res => {
			if(res.status===200){
				setPagination(JSON.parse(res.headers["pagination"] || {}))
				setWithdrawals(res.data)
			}
		}).catch(err=>{
			if(err.response.status === 404){
				dispatch(addAlert({text:"No se han encontrado retiros"}));
				setPagination({});
				setWithdrawals([])
			}else{
				console.log(err);
				dispatch(addAlert({text:"Se ha producido un error", type:"error"}));
			}
		})
		
	}


	return (
		<div className="Withdrawals">
			<Header title="Administracion retiros"/>

			<div className="Withdrawals-content">
				<div className="controls">
					<div className="type">
						<Checkbox
							value={status === "pending"}
							action={()=>{setStatus("pending")}}
							label="Pendientes"
						/>
						<Checkbox
							value={status === "rejected"}
							action={()=>{setStatus("rejected")}}
							label="Rechazadas"
						/>
						<Checkbox
							value={status === "completed"}
							action={()=>{setStatus("completed")}}
							label="Completadas"
						/>
					</div>
				</div>

				<WithdrawalsTable
					pagination={pagination}
					withdrawals={withdrawals}
					setPagination={setPagination}
					setPage={setPage}
					setWithdrawalModal={setWithdrawalModal}
				/>
			</div>

			{withdrawalModal?
				<WithdrawalModal
					withDrawal={withdrawalModal}
					setWithdrawal={setWithdrawalModal}
					isAdmin
				/>
			:null}

		</div>
	);
}

export default Withdrawals;