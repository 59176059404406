import React,{useEffect} from "react";
import Button from "components/Button";
import Modal from "../Modal/Modal";
import "./Confirmation.scss";


function Confirmation({title,description,accept,closeModal,children}) {
	// Todo - make this interact with modal in a more nice way, combining animations when opening and closing

	const handleAccept = () => {
		accept(true);
		closeModal();
	}

	useEffect(()=>{
		window.addEventListener('keydown', handelKeyPress)
		return () => window.removeEventListener('keydown', handelKeyPress)
	},[]);

	const handelKeyPress = (e) => {
		// if(e.keyCode === 13){ TBI
		// 	handleAccept();
		// } else 
		if(e.keyCode === 27){
			closeModal();
		}
	}

	return (
		<Modal className="confirmation">
			<div className="title">{title}</div>
			<div className="description">
				{description}
			</div>
			<div className="content">
				{children}	
			</div>

			<div className="confirmation-controls" >
				<Button className="cta cancel" variant="secondary" action={closeModal}>Cancelar</Button>
				<Button className="cta accept" action={handleAccept}>Acceptar</Button>
			</div>
		</Modal>
	);
}

export default Confirmation;