import React, {useEffect, useState} from "react";
import Header from "components/Header";
import Button from "components/Button";
import {Table, TableItem} from "components/Table";
import Dropdown from "components/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {getAllRoles} from "Actions/RolesActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Modal from "components/Modal";
import InputField from "components/InputField";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/crossIcon.svg";
import "./Roles.scss";
import Confirmation from "components/Confirmation";
import {addAlert} from "Actions/SystemActions";

const columnsRoles = {
	"id":"id",
	"name":"Nombre",
	"":""
}

function Roles() {

	const [selectedRole,setSelectedRole] = useState(null);
	const [editableRole,setEditableRole] = useState(false);
	const [usersPerRole,setUsersPerRoles] = useState([]);
	const [showSearchModal,setShowSearchModal] = useState(false);
	const [showAddRoleModal,setShowAddRoleModal] = useState(false);
	const [showAddConfirm,setAddConfirm] = useState(false);
	const [showEditConfirm,setEditConfirm] = useState(false);
	const [showAssignConfirm,setAssignConfirm] = useState(null);
	const [showUnassignConfirm,setUnassignConfirm] = useState(null);
	const [query,setQuery] = useState("");
	const [roleData,setRoleData] = useState({
		name:""
	});
	const roles = useSelector(store=>store.RolesReducer).roles;


	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(getAllRoles());
	},[])

	useEffect(()=>{
		if(roles.length>0){
			setSelectedRole(roles[0]);
		}
	},[roles]);

	useEffect(()=>{
		// TODO - If its not the same role clean the list of users
		if(selectedRole?.id){
			getUserRoles();
			setEditableRole(selectedRole);
		}
	},[selectedRole]);

	useEffect(()=>{
		if(query.length>3){
			getUserRoles();
		}else if(query.length===0){
			getUserRoles();
		}
	},[query])

	const applyChanges = (e) => {
		// console.log("Saving changes",editableRole);
		axiosWithAuth().patch("roles",{
			...editableRole
		}).then(res=>{
			dispatch(getAllRoles());
			dispatch(addAlert({text:"Rol editado correctamente", type:"success"}))
		})
	}

	const discardChanges = (e) => {
		setEditableRole(selectedRole);
	}

	const getUserRoles = () => {
		if(selectedRole?.id){
			const _query = query?"?query="+query:""
	
			axiosWithAuth().get(`roles/${selectedRole?.id}/users` + _query).then(res=>{
				setUsersPerRoles(res.data);
			}).catch(err=>{
				if(err.response.status === 404){
					setUsersPerRoles([...Array(10)])
				}
			})
		}
	}

	const addRole = () => {
		axiosWithAuth().post("roles",roleData).then(res=>{
			dispatch(getAllRoles());
			dispatch(addAlert({text:"Rol añadido correctamente", type:"success"}))
			setShowAddRoleModal(false);
			setRoleData({});
		}).catch(err=>{
			if(err.response.status === 404){
				setUsersPerRoles([...Array(10)])
			}
		})
	}

	const assignRole = (userId) => {
		if(selectedRole?.id){
			axiosWithAuth().post(`roles/assign`, {roleId:selectedRole.id, userId}).then(res=>{
				getUserRoles();
				dispatch(addAlert({text:"Rol asignado correctamente", type:"success"}))
			}).catch(err=>{
				if(err.response.status === 409){
					dispatch(addAlert({text:"El usuario ya ha sido asignado a ese rol", type:"error"}))
				}
			})
		}
	}

	const unassignRole = (userId) => {
		if(selectedRole?.id){
			axiosWithAuth().post(`roles/unassign`, {roleId:selectedRole.id, userId}).then(res=>{
				getUserRoles();
				dispatch(addAlert({text:"Usuario desasignado del rol", type:"success"}))
			}).catch(err=>{
				if(err.response.status === 409){
					dispatch(addAlert({text:"El usuario no tiene el rol asignado", type:"error"}))
				}
			})
		}
	}

	return (
		<div className="Roles">
			<Header title={"Roles"} subtitle={selectedRole?.name}/>
			<div className="wrapper">
				<div className="controls">
					<Dropdown title={"Roles"}>
						{roles.map(role=>{
							return <Dropdown.Item key={role.id} action={()=>{setSelectedRole(role)}}>
								{role.name}
							</Dropdown.Item>
						})}
						<Dropdown.Item action={()=>{setShowAddRoleModal(true)}}>
							+ Añadir
						</Dropdown.Item>
					</Dropdown>

					{editableRole?
						<div className="role-info">
							<InputField
								name="id"
								label="id"
								value={editableRole.id}
								readOnly
							/>
							<InputField
								name="name"
								label="Nombre *"
								value={editableRole.name}
								setValue={(nVal)=>{setEditableRole({...editableRole, name:nVal})}}
								maxLength={45}
							/>
							<InputField
								name="createdAt"
								label="Creado"
								value={new Date(editableRole.createdAt)}
								readOnly
							/>
							<InputField
								name="createdBy"
								label="Creado por"
								value={editableRole.createdBy}
								readOnly
							/>
							<InputField
								name="updatedAt"
								label="Actualizado"
								value={new Date(editableRole.updatedAt)}
								readOnly
							/>
							<InputField
								name="updatedBy"
								label="Actualizado por"
								value={editableRole.updatedBy}
								readOnly
							/>
							<div className="bottom-wrapper">
								{editableRole.id===1?null:<Button>Eliminar</Button>}
								<span className="info-field">(*) Campos editables</span>
							</div>
						</div>
					:null}

					<div className="actions">
						<Button variant="secondary" action={discardChanges}>
							Cancelar
						</Button>
						<Button variant="primary" action={() => {setEditConfirm(true)}}>
							Guardar
						</Button>
					</div>
				</div>


				<div className="table-wrapper">
					<div className="table-wrapper-controls">
							<InputField
								name="query"
								value={query}
								setValue={setQuery}
							/>
						<Button variant="secondary" action={()=>{setShowSearchModal(true)}}>+ Añadir</Button>
					</div>
					<Table columns={columnsRoles} data={usersPerRole}>
							{usersPerRole.map((row,index)=>{
							return <TableItem key={index}>
								<td>{row?.id}</td>
								<td className="name">{row?.name}</td>
								<td className="actions">
									{row?.id?
										<span className="icon" onClick={()=>{setUnassignConfirm(row.id)}}>
											<RemoveIcon/>
										</span>
									:null}
								</td>
							</TableItem>
						})}
					</Table>
				</div>
			</div>


			{showSearchModal?
				<Modal showCloseIcon closeModal={()=>{setShowSearchModal(false)}} >
					<div className="modal-content search">
						<SearchUsers assignRole={setAssignConfirm}/>
					</div>
				</Modal>
			:null}
			{showAddRoleModal?
				<Modal showCloseIcon closeModal={()=>{setShowAddRoleModal(false)}} >
					<div className="modal-content addRole">
						<Header title="Añadir Rol"/>
						<InputField
							name="name"
							value={roleData.name}
							setValue={(nVal)=>{setRoleData({...roleData, name:nVal})}}
						/>
						<div className="controls">
							<Button variant="secondary" action={()=>{setShowAddRoleModal(false)}}>Cancelar</Button>
							<Button variant="primary" action={()=>{setAddConfirm(true)}}>Añadir</Button>
						</div>
					</div>
				</Modal>
			:null}

			{showAddConfirm?<Confirmation
				title="Crear rol"
				description={`Se creara el siguiente rol\n${roleData?.name}`}
				closeModal={()=>{setAddConfirm(false);}}
				accept={()=>{addRole()}}
			/>:null}

			{showEditConfirm?<Confirmation
				title="Editar rol"
				description={`Se Editara el siguiente rol\n${editableRole?.name}`}
				closeModal={()=>{setEditConfirm(false);}}
				accept={()=>{applyChanges()}}
			/>:null}

			{showAssignConfirm?<Confirmation
				title="Asignar rol"
				description={`Se asignara el rol ${editableRole?.name}\nAl usuario ${showAssignConfirm}`}
				closeModal={()=>{setAssignConfirm(null);}}
				accept={()=>{assignRole(showAssignConfirm)}}
			/>:null}

			{showUnassignConfirm?<Confirmation
				title="Desasignar rol"
				description={`Se desasignara el rol ${editableRole?.name}\nAl usuario ${showUnassignConfirm}`}
				closeModal={()=>{setUnassignConfirm(null);}}
				accept={()=>{unassignRole(showUnassignConfirm)}}
			/>:null}

		</div>
	);
}

export default Roles;

const SearchUsers = ({assignRole}) => {

	const [query,setQuery] = useState("");
	const [results,setResults] = useState([]);

	useEffect(()=>{
		if(query.length > 3){
			axiosWithAuth().get("users/admin?query="+query).then(res=>{
				setResults(res.data);
			})
		}
	},[query])

	return (<>
		<Header title="Añadir usuarios"/>
		<div className="search-area">
			<InputField 
				value={query}
				setValue={setQuery}
				maxLength={256}
			/>

			<Table columns={{"id":"id",
				"fullName":"Nombre",
				"email":"Correo Electronico",
				"abbasoonCode":"codigo",
				"":""
				}} className="query-results">
				{results.map(result=><TableItem key={result.id}>
					<td>{result.id}</td>
					<td>{result.fullName}</td>
					<td>{result.email}</td>
					<td>{result.abbasoonCode}</td>
					{/* TODO - Find a way to verify if user has been already assigned */}
					<td onClick={() => {assignRole(result.id)}}>A</td>
				</TableItem>)}
			</Table>
		</div>
	</>)
}