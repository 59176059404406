import React from "react";
import "./InputField.scss"
import {LockIcon} from "assets";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";

function InputField({
	value,
	setValue,
	name,
	type="text",
	showPassword,
	label,
	error,
	minLength,
	maxLength,
	className="",
	max,
	min,
	pattern,
	readOnly,
	slot,
	required,
	step,
	placeholder,
	onChange,
	autoComplete=true,
	autoFocus=false
}) {

	const handleChange = (e) => {
		e.preventDefault();
		if(onChange){
			onChange(e);
		}else{
			setValue(e.target.value)
		}
	}
	
	return (
		<div className={`input-wrapper ${className}`}>
			
			
			{label &&
			<div className="label">
				{label}
				{readOnly && <span>
					<LockIcon/>
				</span>}


				{error && <span className="error"> - {error}</span>}
			</div>}

			<div className="input-field">
				<input 			
					className={`${error?"error":""} ${slot?"hasSlot":""}`}
					name={name} 
					type={ 	type === "password"?showPassword?"number":"password":type} 
					value={value} 
					onChange={handleChange}
					minLength={minLength}
					maxLength={maxLength}
					max={max}
					min={min}
					pattern={pattern}
					readOnly={readOnly}
					required={required}
					step={step}
					placeholder={placeholder}
					autoComplete={autoComplete?null:"new-off"}
					autoFocus={autoFocus}
				/>
				{slot}
			</div>
		</div>
	);
}



export default InputField;


InputField.PhoneInput = ({
	label="Numero de telefono",
	className,
	phone,
	setPhone,
	error,
	readOnly=false,
	slot
}) => {
	return (
		<div className={classNames("input-wrapper",className)}>
		
			{label &&
			<div className="label">
				{label}
				{readOnly && <span>
					<LockIcon/>
				</span>}
				{error && <span className="error"> - {error}</span>}
			</div>}

			<div className="input-field">
				<PhoneInput
					className="number hasSlot"
					country={"us"}
					value={phone}
					onChange={setPhone}
				/>
				{slot}
			</div>
		</div>
	)
}