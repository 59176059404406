import Button from "components/Button/Button";
import Header from "components/Header/Header";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import "./ContactPage.scss"
import InputField from "components/InputField/InputField";
import TextArea from "components/Textarea/Textarea";
import Checkbox from "components/Checkbox/Checkbox";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";

const initUser={
	name:"",
	email:"",
	phone:"",
	referredBy:"",
	comments:"",
	acceptContact:false,
	contacted:false
}
function ContactPage() {

	const navigate = useNavigate();
	const [userData,setUserData] = useState(initUser);
	const dispatch = useDispatch();


	const handleSubmit = (e) => {
		e.preventDefault();

		axiosWithAuth().post("/contact/request",userData).then(res => {
			if(res.status === 201){
				dispatch(addAlert({text:"Enviado exitosamente", type:"success"}));
				setUserData(initUser);
			}
		}).catch(err=>{
			console.log(err);
			dispatch(addAlert({text:"A ocurrido un error. Intentalo de nuevo", type:"error"}))
		})

	}

	const handleChange = (e) => {
		setUserData({...userData, [e.target.name]:e.target.value})
	}

	return (
		<div className="ContactPage page">

			<div className="wrapper">
				
				<Header title={"Ponte en contacto!"} subtitle={"Dejanos tu informacion y nos pondremos en contacto contigo"}/>

				<form className="contact" action="contactInformation" onSubmit={handleSubmit}>
					
					<div className="row">
						<InputField
							value={userData.name}
							onChange={handleChange}
							label="Nombre(*)"
							name="name"
						/>
						<InputField
							value={userData.phone}
							onChange={handleChange}
							label="Telefono(*)"
							name="phone"
							type="phone"
					/>
					</div>
					<InputField
						value={userData.email}
						onChange={handleChange}
						label="Correo electronico(*)"
						name="email"
						type="email"
					/>
					<InputField
						value={userData.referredBy}
						onChange={handleChange}
						label="Persona que dio la informacion(*)"
						name="referredBy"
					/>
					<TextArea
						value={userData.comments}
						onChange={handleChange}
						label="Comentarios"
						name="comments"
						rows={5}
					/>

					<Checkbox
						value={userData.acceptContact}
						label={"Acepto ser contactado"}
						toggleValue={(val)=>{setUserData({...userData,acceptContact:val})}}

					/>


					<div className="bottom-wrapper">
						<div className="info-field">(*) Campos obligatorios</div>
						<Button type="submit" className="cta-submit"
							disabled={ !(userData.email && userData.phone && userData.acceptContact) }
						>
							Enviar
						</Button>
					</div>

				</form>


			</div>

			<div className="return">
				<Button action={()=>{navigate("/")}} variant="tertiary">
					Ir a inicio
				</Button>
			</div>

		</div>
	);
}

export default ContactPage;