import Card from "components/Card";
import React from "react";
import {useNavigate} from "react-router";

function AdminLogWidget() {

	const navigate = useNavigate();
	return (<>
	
		<Card className="AdminLog" 
			action={() => {navigate("/admin/system/log")}}
			centered
		>
			Registros de administracion
		</Card>

	</>);
}

export default AdminLogWidget;

