import {
	FETCH_USERS_START,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAILURE,
} from "../Actions/UsersActions.js"

const initialState = {
	users:[],
	pagination:{},
	error:null,
	getStatus:null,
	postStatus:null,
	response: null,
}

const UserReducer = (state=initialState,action) => {
	switch(action.type){

		case FETCH_USERS_START:
			return {...state, getStatus:true, response:null, error:null, pagination:{}}
		case FETCH_USERS_SUCCESS:
			return {...state, getStatus:false, users:action.payload.data, response:action.payload.response, pagination:action.payload.pagination}
		case FETCH_USERS_FAILURE:
			return {...state, getStatus:false, error:action.payload.data, response:action.payload.response}


		default:
			return state;
	}
}

export default UserReducer;