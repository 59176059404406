// useFetchTransactions.js

import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useState,useEffect} from "react";
import {useSelector} from "react-redux";

const useFetchTransactions = (
	userId = null
) => {
	
	const [status,setStatus] = useState("completed");
	const [transactions,setTransactions] = useState([]);
	const [pagination,setPagination] = useState(null);
	const [page,setPage] = useState(1);
	const [dateRange,setDateRange] = useState({
		eDate:'',
		sDate:''
	})

	const selectedWallet = useSelector(store=>store.UserReducer).selectedWallet;
	const [query,setQuery] = useState('')

	useEffect(()=>{
		if(selectedWallet?.uuid){
			fetchTransactions()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[selectedWallet,dateRange,status,page]);

	const fetchTransactions = () => {
		setTransactions([]); // Necessary to clean older trnasaction
		axiosWithAuth().get("/transactions/"+selectedWallet.uuid,{
			params:{
				page:page,
				status:status,
				query:query,
				startDate: dateRange.sDate || null,
				endDate: dateRange.eDate || null
		}}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}))
			setTransactions(res.data);
		}).catch(err=>{
			if(err?.response?.status === 404){
				setTransactions([]);
				setPagination({})
			}
		})
	}

	const handleSearch = () => {
		fetchTransactions();
	}

	return { 
		transactions, 
		pagination, 
		setPage,
		status,
		setStatus,
		query,
		setQuery,
		handleSearch,
		dateRange,
		setDateRange,
		fetchTransactions,
	};


}

export default useFetchTransactions;
