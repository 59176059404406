import React,{useEffect} from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/crossIcon.svg";
import "./Modal.scss";

function Modal({children, showCloseIcon, closeModal,className}) {
	
	useEffect(()=>{
		window.addEventListener('keydown', handleClose)
		return () => window.removeEventListener('keydown', handleClose)
	},[]);

	const handleClose = (e) => {
		if(e.keyCode === 27){
		  closeModal()
		}
	}

	return (<div className="backdrop" onClick={!showCloseIcon?closeModal:null}>
			{showCloseIcon?
				<CloseIcon className="closeIcon" onClick={closeModal}/>
			:null}

			<div className={`Modal modal-wrapper ${className}`} onClick={(event)=>{event.stopPropagation()}}>
				{children}
			</div>
	</div>);
}

export default Modal;