import React from "react";
import "./TopNav.scss"
import Dropdown from "components/Dropdown";
import NavigationLink from "./NavigationLink";
import {useDispatch, useSelector} from "react-redux";
import {updateOrganization} from "Actions/SystemActions";

function TopNav() {
	return (
		<div className="TopNav">
			<div className="info">
				Fintech-Genesis
			</div>

			<div className="rightPannel">
				<OrganizationMenu/>
				<ProfileMenu/>
			</div>
		</div>
	);
}

function OrganizationMenu(){

	const organizations = useSelector(store => store.SystemReducer).organizations;
	const selectedOrganization = useSelector(store => store.SystemReducer).selectedOrganization;
	const dispatch = useDispatch();

	const selectOrganization = (id) => {
		dispatch(updateOrganization(id))
	}

	return <div className="Menu Menu-organization">

		<Dropdown title={selectedOrganization?.name || "Organizacion"}
			keepOpenOnSelection
		>
			<Dropdown.Item
				action={()=>{selectOrganization()}}
			>
				Todas
			</Dropdown.Item>
				{Object.values(organizations).map(org => {
					return <Dropdown.Item
						key={org.id}
						action={()=>{selectOrganization(org.id)}}
					>
						{org.name}
					</Dropdown.Item>
				})}
		</Dropdown>
	</div>
}

function ProfileMenu(){
	return <div className="Menu Menu-profile">

		<Dropdown title="Perfil"
			keepOpenOnSelection
		>
			<Dropdown.Item>
				<NavigationLink
					path="/password/change"
					text="Cambiar Contraseña"
				/>
			</Dropdown.Item>

			<Dropdown.Item>
				<NavigationLink
					path="/profile"
					text="Detalles personales"
				/>
			</Dropdown.Item>
			<Dropdown.Item>
				<NavigationLink
					path="/sessions"
					text="Sessiones"
				/>
			</Dropdown.Item>
		</Dropdown>
	</div>
}

export default TopNav;