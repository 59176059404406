import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import { ReactComponent as LoginLogo } from "../../../assets/logos/login-logo.svg";
import AuthWrapper from "../AuthWrapper";
import InputField from "components/InputField";
import Checkbox from "components/Checkbox/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, login} from "Actions/UserActions";
import "./Login.scss";

function Login() {

	const [lUserData,setLUserData] = useState({
		email:'',
		password:''
	});
	const [showPassword,setShowPassword] = useState(false);
	const [emailError,setEmailError] = useState(null);
	const [passwordError,setPasswordError] = useState(null);
	const {error, data, loggedIn} = useSelector(store => store.UserReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	useEffect(()=>{
		dispatch(clearErrors());
	},[]);

	useEffect(()=>{
		if(data?.uuid){
			return navigate('/dashboard');
		}
	},[data,loggedIn]);

	useEffect(()=>{
		if(error?.code == 404 && error?.path == "login"){
			setEmailError(error.message);
		}else if(error?.code == 401 && error?.path == "login"){
			setPasswordError(error.message);
		}
		if(!error){
			setEmailError(null);
			setPasswordError(null);
		}
	},[error]);

	useEffect(()=>{
		if(emailError){
			setEmailError(null);
		}
		if(passwordError){
			setPasswordError(null);
		}
	},[lUserData]);

	const handleLogin = (e) => {
		e.preventDefault();
		dispatch(login(lUserData));
	}

	return (
		<AuthWrapper useContainer>
			<div className="Login">
				<div className="header">
					<h1 className="title">Iniciar session</h1>
					<p className="subtitle">Primero para continuar</p>
					<LoginLogo/>
				</div>
				<form className="login" action="login" onSubmit={handleLogin}>
					<div className="inputs">
						<InputField 
							name="email"
							value={lUserData.email}
							setValue={(nVal)=> setLUserData({...lUserData, email:nVal})}
							label="Email"
							error={emailError}
						/>
						<InputField 
							name="password"
							value={lUserData.password}
							setValue={(nVal)=> setLUserData({...lUserData, password:nVal})}
							label="Password"
							type={showPassword?"text":"password"}
							error={passwordError}
						/>
					</div>
					<div className="actions">
						<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
						<Link to="/forgot_password">
							Olvidaste tu contrasena?
						</Link>
					</div>
					<Button className="login" variant="secondary" type="submit">
						Entrar
					</Button>
				</form>

			</div>
		</AuthWrapper>
	);
}

export default Login;