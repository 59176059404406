import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import AuthWrapper from "../AuthWrapper";
import InputField from "components/InputField";
import Checkbox from "components/Checkbox/Checkbox";
import { ReactComponent as LoginLogo } from "../../../assets/logos/login-logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {register} from "Actions/UserActions";
import "./Register.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Dropdown from "components/Dropdown";
import {addAlert} from "Actions/SystemActions";
import {useQuery} from "Utils/hooks";

function Register() {

	const [rUserData,setRUserData] = useState({
		fullName:'',
		username:'',
		email:'',
		password:'',
		phone:'',
		dob:'',
		city:'',
		referrerCode:''
	});
	const [phase,setPhase] = useState(1);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [allowedRegistration,setAllowedRegistration] = useState(false);

	const userData = useSelector(store => store.UserReducer);

	useEffect(()=>{
		const { response } = userData;
		if(response?.config.url === "auth/register"){
			switch (response?.status){
				case 201:
					return navigate('/login');
					// Go to succesfull login page
				case 401:
					dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
				default: 
					dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		}
	},[userData])

	const handleRegister = () => {
		dispatch(register({...rUserData, referrerCode:rUserData.referrerCode === ''?null:rUserData.referrerCode}));
	}

	const renderPhase = () => {

		if(process.env.REACT_APP_REQUIERE_REGISTRATION_CODE === "TRUE" && !allowedRegistration){
			return <PhaseValidateRegistration rUserData={rUserData} setRUserData={setRUserData} setAllowedRegistration={setAllowedRegistration}/>
		}

		if(phase===2){
			return <Phase2 rUserData={rUserData} setRUserData={setRUserData} setPhase={setPhase}/>	
		}else if(phase===3){
			return <Phase3 rUserData={rUserData} setRUserData={setRUserData}  setPhase={setPhase} handleRegister={handleRegister}/>
		}else{
			return <Phase1 rUserData={rUserData} setRUserData={setRUserData} setPhase={setPhase}/>;
		}
	}

	return (
		<AuthWrapper useContainer>
			{renderPhase()}
		</AuthWrapper>
	);
}

export default Register;

const Phase1 = ({rUserData,setRUserData,setPhase}) => {

	const dispatch = useDispatch();

	const nextPhase = (e) => {
		e.preventDefault();
		if(rUserData.fullName && rUserData.phone && rUserData.dob){
			setPhase(2);
		}else{
			// Change to our style of alert. And show errors
			dispatch(addAlert({text:"Aun faltan campos por rellenar", type:"error"}))
		}
	}

	return (<div className="Register phase1">
		<div className="header">
			<h1 className="title">Completa tu registro</h1>
			<p className="subtitle">Primero cuentanos sobre ti!</p>
			<LoginLogo />
		</div>

		<form className="register" action="register" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField
					name="fullname"
					value={rUserData.fullName}
					setValue={(nVal) => setRUserData({...rUserData, fullName: nVal})}
					label="Nombre completo"
				/>
				<InputField
					name="city"
					value={rUserData.city}
					setValue={(nVal) => setRUserData({...rUserData, city: nVal})}
					label="Ciudad"
				/>
				<div className="cols-2">
					<InputField
						name="phone"
						value={rUserData.phone}
						setValue={(nVal) => setRUserData({...rUserData, phone: nVal})}
						label="Numero de telefono"
						type="tel"
						maxLength={20}
					/>
					<InputField
						name="dateOfBirth"
						value={rUserData.dob}
						setValue={(nVal) => setRUserData({...rUserData, dob: nVal})}
						label="Fecha de nacimiento"
						type="date"
					/>
				</div>
				<Dropdown title={
					rUserData.gender==="M"?"Masculino":
					rUserData.gender==="F"?"Femenino":
					rUserData.gender==="X"?"Otro"
					:"Sexo"
				} variant="secondary">
					<Dropdown.Item 
						action={()=>{setRUserData({...rUserData,gender:'M'})}}
						selected={rUserData.gender==='M'}
						>Masculino</Dropdown.Item>
					<Dropdown.Item 
						action={()=>{setRUserData({...rUserData,gender:'F'})}}
						selected={rUserData.gender==='F'}
						>Femenino</Dropdown.Item>
					<Dropdown.Item 
						action={()=>{setRUserData({...rUserData,gender:'X'})}}
						selected={rUserData.gender==='X'}
						>Otro</Dropdown.Item>
				</Dropdown>
			</div>
			<div className="cta-actions">
				<Button className="next register1" variant="secondary" type="submit">
					Siguiente
				</Button>
			</div>
		</form>

	</div>)
}

const Phase2 = ({rUserData,setRUserData,setPhase}) => {

	const [showPassword,setShowPassword] = useState(false);

	const nextPhase = (e) => {
		e.preventDefault();
		if(rUserData.username && rUserData.password){
			setPhase(3);
		}else{
			// Change to our style of alert. And show errors
			alert("There are fileds required"); 
		}
	}
	const prevPhase = () => {
		setPhase(1);
	}

	return (<div className="Register phase2">
		<div className="header">
			<h1 className="title">Completa tu registro</h1>
			<p className="subtitle">Como quieres que el mundo te conozca?</p>
			<LoginLogo />
		</div>

		<form className="register" action="register" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField
					name="username"
					value={rUserData.username}
					setValue={(nVal) => setRUserData({...rUserData, username: nVal})}
					label="Nombre de usuario"
				/>
				<InputField
					name="email"
					value={rUserData.email}
					setValue={(nVal) => setRUserData({...rUserData, email: nVal})}
					label="Correo electronico"
				/>
				<InputField 
					name="password"
					value={rUserData.password}
					setValue={(nVal)=> setRUserData({...rUserData, password:nVal})}
					label="Password"
					type={showPassword?"text":"password"}
				/>
			</div>
			<div className="actions">
				<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
			</div>
			<div className="cta-actions">
				<Button className="next" variant="tertiary" action={prevPhase}>
					Atras
				</Button>
				<Button className="next" variant="secondary" type="submit">
					Siguiente
				</Button>
			</div>
		</form>

	</div>)
}

const Phase3 = ({rUserData,setRUserData,setPhase,handleRegister}) => {

	const [referrerCode,setReferrerCode] = useState('');
	const [error,setError] = useState(null);
	const [validated,setValidated] = useState(false);
	const dispatch = useDispatch();
	const query = useQuery();

	useEffect(()=>{
		if(query.get("code")){
			setReferrerCode(query.get("code"));
		}
	},[]);
	
	const nextPhase = (e) => {
		e.preventDefault();

		if(validated || referrerCode.length === 0){
			handleRegister();
		}else if( referrerCode.length > 0){
			dispatch(addAlert({text:"Debes validar el codigo", type:"error"}))
		}

	
	}

	useEffect(()=>{
		setRUserData({...rUserData, referrerCode:referrerCode});
	},[referrerCode]);

	useEffect(()=>{
		if(referrerCode.length>0 && error){
			setError(null);
		}
	},[referrerCode])

	const prevPhase = () => {
		setPhase(2);
	}

	const validateCode = () => {
		axiosWithAuth().post("/auth/validateRefcode",{code:referrerCode}).then(res=>{
			if(res.status === 200){
				setValidated(true);
			}else{
			}
		}).catch(err=>{
			if(err?.response?.status === 404){
				setError("No existe ningun usuario con ese codigo abbasoon");
				setValidated(false);
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		})
	}

	return (<div className="Register phase2">
		<div className="header">
			<h1 className="title">Completa tu registro</h1>
			<p className="subtitle">Ya casi! Solo dinos si alguien te refirio</p>
			<LoginLogo />
		</div>

		<form className="register" action="register" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField 
					name="referrerCode"
					value={referrerCode}
					setValue={setReferrerCode}
					label="Codigo de referido"
					slot={<Button action={()=>{validateCode()}}>Validar</Button>}
					error={error}
					className={validated?"valid":""}
				/>
			</div>
			<div className="actions">
				<p>Dejalo en blanco si nadie te ha refirido</p>
			</div>
			<div className="cta-actions">
				<Button className="next" variant="tertiary" action={prevPhase}>
					Atras
				</Button>
				<Button className="next" variant="secondary" type="submit" disabled={!validated && String(referrerCode).length>0}>
					Registrarse!
				</Button>
			</div>
		</form>

	</div>)
}

const PhaseValidateRegistration = ({rUserData,setRUserData,setAllowedRegistration,handleRegister}) => {

	const [registrationCode,setRegistrationCode] = useState('');
	const [error,setError] = useState(null);
	const [validated,setValidated] = useState(false);
	
	const dispatch = useDispatch();

	const query = useQuery();

	useEffect(()=>{
		if(query.get("registrationCode")){
			setRegistrationCode(query.get("registrationCode"));
		}
	},[]);
	
	const nextPhase = (e) => {
		e.preventDefault();
		
		if(validated || registrationCode.length === 0){
			handleRegister();
		}else if( registrationCode.length > 0){
			dispatch(addAlert({text:"Debes validar el codigo", type:"error"}))
		}
	}

	useEffect(()=>{
		if(registrationCode.length === 8){
			validateCode();
		}else if(registrationCode.length>0 && error){
			setError(null);
		}
	},[registrationCode])

	const validateCode = () => {
		axiosWithAuth().get("auth/codes/registration/verify/"+registrationCode).then(res=>{
			if(res.status === 200){
				dispatch(addAlert({type:"success", text:"El codigo ha sido verificado de manera exitosa."}));
				setAllowedRegistration(true);
				setRUserData({...rUserData,registrationCode})
			}
		}).catch(err=>{
			if(err?.response.status === 404){
				dispatch(addAlert({type:"error", text:"El codigo es incorrecto, verifica de nuevo el codigo."}));
			}
			if(err?.response.status === 410){
				dispatch(addAlert({type:"error", text:"El codigo ya expiro, solicita un nuevo codigo."}));
			}
		})
	}

	return (<div className="Register phaseRegistratinoValidation">
		<div className="header">
			<h1 className="title">Introduce tu codigo de registro</h1>
			<p className="subtitle">Revisa tu correo electronico o ponte en contacto con un lider para obtener tu codigo de registro</p>
		</div>

		<form className="register" action="register" onSubmit={nextPhase}>
			<div className="inputs">
				<InputField 
					name="referrerCode"
					value={registrationCode}
					setValue={setRegistrationCode}
					label="Codigo de registro"
					slot={<Button action={()=>{validateCode()}}>Validar</Button>}
					error={error}
					maxLength={8}
					className={validated?"valid":""}
				/>
			</div>
		</form>

	</div>)
}