import {addAlert} from "Actions/SystemActions";
import {fetchLoggedUser, logout} from "Actions/UserActions";
import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import React,{useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useQuery} from "Utils/hooks";
import "./VerifyAccount.scss";

function VerifyAccount() {

	const [code,setCode] = useState('');
	const [error,setError] = useState(null)
	const loggedIn = useSelector(store=>store.UserReducer.loggedIn);
	const status = useSelector(store=>store.UserReducer.data?.status);
	const query = useQuery();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(()=>{
		if(query.has("code")){
			setCode(query.get("code"));
		}
	},[])

	useEffect(()=>{	
		if(status === "active"){
			navigate("/onboarding")
		}
	},[status]);

	useEffect(()=>{
		if(code.length === 8){
			axiosWithAuth().post("auth/complete_verification",{code}).then(res=>{
				if(res.status === 201){
					if(loggedIn){
						navigate("/onboarding");
						dispatch(fetchLoggedUser());
						dispatch(addAlert({text:"Cuenta verificada correctamente", type:"success"}));
					}else{
						navigate("/login");
						dispatch(addAlert({text:"Cuenta verificada correctamente. Por motivos de seguridad nos gustaria que introdujeras tu contraseña", type:"success"}));
					}
				}
			}).catch(err=>{
				if(err?.response?.status === 404){
					setError("El codigo no es valido");
				}else if(err?.response?.status === 409){
					navigate("/onboarding");
					dispatch(fetchLoggedUser());
					dispatch(addAlert({text:"La cuenta ya ha sido activada", type:"error"}));
				}else{
					dispatch(addAlert({text:"Ha ocurrido un error. Intenta de nuevo", type:"error"}));
				}
			})
		}
		if(code.length>0 && error){
			setError(null);
		}
	},[code])

	const resendVerification = () => {
		axiosWithAuth().get("auth/resend_verification").then(res=>{
			if(res.status === 200){
				dispatch(addAlert({text:"Nuevo correo de verificacion enviado.", type:"success"}));
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				setError("La cuenta ya esta verificada. Recarga la pagina");
			}
			dispatch(addAlert({text:"Ha occurido un error", type:"error"}));
		})
	}


	return (
		<div className="VerifyAccount">
			<div className="VerifyAccount-content">
				<Header title="Verifica tu cuenta" subtitle="Antes de continuar debes introducir el codigo enviado a tu correo electronico"/>	
				<InputField
					name="code"
					label="Codigo de verificacion"
					value={code}
					setValue={setCode}
					error={error}
					maxLength={8}
				/>

				{loggedIn && 
				<Button action={resendVerification} className="resendEmail">
					Reenviar codigo de verificacion
				</Button>
				}

			</div>


			{loggedIn?
				<Button
					className="disconnect"
					action={() => {
						dispatch(logout())
						navigate("/")
					}}
					variant="tertiary"
				>
					Desconectar
				</Button>
			:
				<Button
					className="disconnect"
					action={() => {
						navigate("/")
					}}
					variant="tertiary"
				>
					Volver atras
				</Button>
			
			}

		</div>
	);
}

export default VerifyAccount;