import Button from "components/Button";
import Card from "components/Card";
import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";

function OrganizationsWidget() {

	const organizations = Object.values(useSelector(store => store.SystemReducer).organizations)
	const navigate = useNavigate();

	return (<>
	
		<Card className="OrganizationsCount">
			
			<Card.Title>
				Organizaciones
			</Card.Title>

			<Card.Content>
				<p>{organizations.length}</p>
			</Card.Content>

			<Card.Controls
				borderless
			>
				<Button
					action={() => {
						navigate('/admin/system/organizations')
					}}
					variant="tertiary"
				>
					Ver detalles
				</Button>
			</Card.Controls>
					
		</Card>

	</>);
}

export default OrganizationsWidget;

