import React,{useState} from "react";
import Header from "components/Header";
import Modal from "components/Modal";
import "./PaymentModal.scss";
import InputField from "components/InputField";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/Button";
import {addAlert} from "Actions/SystemActions";
import Dropdown from "components/Dropdown";
import Textarea from "components/Textarea";
import {formatCurrency, formatDate} from "Utils/TextFormat";
import Checkbox from "components/Checkbox";

function PaymentModal({isFullPage=false,handlePayment,paymentInfo,closeModal}) {
	
	if(isFullPage){
		return (
			<div className="payment-wrapper">
				<Content handlePayment={handlePayment} paymentInfo={paymentInfo}/>
			</div>
		)
	}else{
		return (
			<Modal className="payment-wrapper" showCloseIcon closeModal={closeModal}>
				<Content handlePayment={handlePayment} paymentInfo={paymentInfo}/>
			</Modal>
		);
	}
}

export default PaymentModal;

function Content({handlePayment,paymentInfo}){

	const [loginConfirm,setLoginConfirm] = useState(false);
	const [password,setPassword] = useState('');
	const [selectedWallet,setSelectedWallet] = useState();
	const [showPassword, setShowPassword] = useState(false);
	const userWallets = useSelector(store=>store.UserReducer.userWallets);
	
	const dispatch = useDispatch();

	const verifyLogin = () => {
		axiosWithAuth().post("auth/password/verify",{password}).then(res=>{
			if(res.status === 200){
				setLoginConfirm(true);
			}
		}).catch(err=>{
			if(err.response.status === 403){
				dispatch(addAlert({text:"Contraseña no valida",type:"error"}))
			}
		})
	}

	const makePayment = () => {
		handlePayment(selectedWallet.uuid);
	}

	if(!loginConfirm){
		return <div className="loginConfirm modalContent">
			<Header title="Verifica tu cuenta" subtitle="Para poder realizar pagos debemos verificar que eres el dueño de la cuenta"/>

			<div className="loginForm">
				<InputField
					label="Contraseña"
					name="password"
					value={password}
					type={showPassword?"text":"password"}
					setValue={setPassword}
				/>
				<Checkbox
					value={showPassword}
					toggleValue={setShowPassword}
					label="Mostrar contraseña"
				/>
			</div>

			<div className="buttonGroup">
				<Button action={verifyLogin}>Verificar</Button>
			</div>

		</div>
	}else{
		return <div className="walletConfirm modalContent">
			<Header title="Elige la wallet con la que deseas pagar" subtitle={paymentInfo?.uuid}/>
		
			
			<div className="paymentInfo">
				<div className="row gp-1">
					<InputField
						label="ID Pago"
						value={paymentInfo.uuid}
						readOnly
					/>
					<InputField
						label="ID Orden de compra"
						value={paymentInfo.orderId}
						readOnly
					/>
				</div>
				<div className="row gp-1">
					<InputField
						label="Fecha de creacion"
						value={formatDate(paymentInfo.createdAt)}
						readOnly
					/>
					<InputField
						label="Fecha de creacion"
						value={formatDate(paymentInfo.createdAt)}
						readOnly
					/>
				</div>

				<InputField
					label="Tienda de origen"
					value={paymentInfo.origin}
				/>

				{paymentInfo.description?
					<Textarea
						label="Descripcion"
						value={paymentInfo.description}
						readOnly
					/>
				:null}
			</div>
			
			
			<Dropdown title={selectedWallet?selectedWallet.walletType:"Wallet"} variant="secondary">
				{userWallets.map(uWallet => {
					return <Dropdown.Item key={uWallet.uuid} 
					action={()=>{setSelectedWallet(uWallet)}}
					selected={selectedWallet === uWallet}
					>
						{uWallet.walletType}
					</Dropdown.Item>
				})}
			</Dropdown>

			<div className="balanceInfo">
				<InputField
					label="Cantidad a pagar"
					value={formatCurrency(paymentInfo?.amount)}
					readOnly
				/>
				<InputField
					label="Balance DESPUES de pago"
					value={
						selectedWallet?
							formatCurrency(Number(selectedWallet?.balance) - Number(paymentInfo?.amount))
						:"Selecciona tipo de wallet"
					}
				/>
				<p className="actual-balance">
					Balance actual <span className="balance">{formatCurrency(Number(selectedWallet?.balance))}</span>
				</p>
			</div>	

			<div className="buttonGroup">
				{paymentInfo.status !== "completed"?
					<Button 
						action={makePayment}
						disabled={
							Number(selectedWallet?.balance - Number(paymentInfo?.amount)) < 0
							&& paymentInfo.status !== "pending"
						}
					>Pagar ahora</Button>
				:null}
			</div>
		</div>
	}


	
}