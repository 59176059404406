import { axiosWithAuth } from "Utils/axiosWithAuth";

export const FETCH_USERS_START =  "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS =  "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE =  "FETCH_USERS_FAILURE";

export const getAllUsers = (params) => {
	return (dispatch) => {
		dispatch({ type:FETCH_USERS_START });
		axiosWithAuth().get("users/admin",{
			params: params
		}).then(res=>{
			dispatch({ 
				type:FETCH_USERS_SUCCESS, 
				payload:{
					data:res.data, 
					response:res,
					pagination: JSON.parse(res.headers["pagination"] || {})
				}
			});
		}).catch(err => {
			if(err?.response.status === 404){
				dispatch({ 
					type:FETCH_USERS_SUCCESS, 
					payload:{
						data: [], 
						response: err.response,
						pagination: {}
					}
				});
			}else{
				if(err.message){
					dispatch({ type:FETCH_USERS_FAILURE, payload: {error:err.message}});
				}
				if(err.response){
					dispatch({ type: FETCH_USERS_FAILURE, payload:{error:err.response.data, response:err.response} });
				}
			}


		})
	}
}
