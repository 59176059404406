import React, {useEffect, useState} from "react";
import "./Table.scss";

export function Table({columns,data=[],children}) {

	const [keys,setKeys] = useState([]);

	useEffect(()=>{
		setKeys(Object.keys(columns));
	},[columns]);

	return (
		<div className="Table">
			<table>
				<thead>
					<tr>
						{keys.map(key=>(
							<th key={key}>{columns[key]}</th>
						))}
					</tr>
				</thead>

				<tbody>				
					{children?
						children
					:data.map((row,index)=>{
						return <TableItem row={row} keys={keys} key={index}></TableItem>
					})}


				</tbody>
			</table>
		</div>
	);
}


export function TableItem({action,className,row,keys,children}){

	if(children){
		return <tr onClick={action} className={className?className:""}>{children}</tr>
	}else{
		return (<tr>
			{keys.map(key=>(
				<td key={key}>{row[key]}</td>
			))}
		</tr>)
	}
}