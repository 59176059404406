
import {formatCurrency, formatDate} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {EyeIcon} from "assets";
import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import {Table,TableItem} from "components/Table";
import React, {useEffect, useState} from "react";
import "./Payments.scss";
import {useLocation, useNavigate, useParams} from "react-router";
import {useQuery} from "Utils/hooks";

function Payments() {

	const [payments,setPayments] = useState([]);
	const [paymentModal,setPaymentModal] = useState(null);
	const queryParams = useQuery();
	const [query,setQuery] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	
	useEffect(()=>{
		fetchPayments();
	},[query]);

	useEffect(()=>{
		if(queryParams.has("paymentId")){
			const paymentId = queryParams.get("paymentId")
			setQuery("uuid:"+paymentId);

			queryParams.delete("paymentId");
       		navigate({ ...location, search: queryParams.toString() }, { replace: true });
		}
	},[queryParams]);

	const fetchPayments = () => {
		axiosWithAuth().get('/users/payments', {
			params:{
				query
			}
		}).then(res => {
			setPayments(res.data);
		}).catch(err=>{
			console.log(err);
		})
	}

	return (
		<div className="Payments">
			<Header title="Pagos"/>
			<div className="wrapper">

				<div className="controls">
					<InputField
						value={query}
						setValue={setQuery}
					/>
				</div>

				<Table columns={["","id","Origen","Order ID","Cantidad"]}>
					{payments.map(p => <TableItem key={p.uuid}>
						<td>
							<EyeIcon
								onClick={()=>{setPaymentModal(p)}}
							/>
						</td>
						<td>
							{p.uuid}
						</td>
						<td>
							{p.origin}
						</td>
						<td>
							{p.orderId}
						</td>
						<td>
							{formatCurrency(p.amount)}
						</td>
					</TableItem>)}
				</Table>
			</div>

			{paymentModal && <PaymentModal payment={paymentModal} closeModal={()=>{setPaymentModal(null)}}/>}
		</div>
	);
}

export default Payments;

const PaymentModal = ({payment,closeModal}) => {
	
	const navigate = useNavigate();

	return <Modal className="Payment card">
		<Header title="Pago" subtitle={payment.uuid} closeModal={closeModal}/>
		<div className="row gp-1">
			<InputField
				name="uuid"
				label="ID de pago"
				value={payment.uuid}
				readOnly
			/>
			<InputField
				name="orderId"
				label="Order Id"
				value={payment.orderId}
				readOnly
			/>
		</div>

		<div className="row gp-1">
			<InputField
				name="origin"
				label="origin"
				value={payment.origin}
				readOnly
			/>
			<InputField
				name="amount"
				label="Total"
				value={formatCurrency(payment.amount)}
				readOnly
			/>
		</div>

		<div className="row gp-1">
			<InputField
				name="createdAt"
				label="Fecha de pago"
				value={formatDate(payment.paidAt) || getStatusText(payment.status)}
				readOnly
			/>
			<InputField
				name="createdAt"
				label="Fecha de creacion"
				value={formatDate(payment.createdAt)}
				readOnly
			/>
		</div>

		<div className="controls">
			{(payment.status !== "paid" && payment.status !== "cancelled") && <Button 
				variant="primary"
				action={()=>{navigate("/payment/"+payment.uuid)}}
			>
				Reintentar pago
			</Button>}
			<Button 
				variant="primary"
				action={()=>{navigate("/payment/"+payment.uuid)}}
			>
				Reintentar pago
			</Button>

			<Button
				variant="secondary"
				action={()=>{closeModal()}}
			>Volver</Button>
		</div>
	</Modal>
}

const getStatusText = (status) => {
	const textAliases = {
		"paid":"Pagado",
		"cancelled":"Cancelado",
		"pending":"Pendiente"
	}
	return textAliases[status]
}