import {useEffect, useState} from "react";
import { useLocation } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoggedUser, fetchLoggedUserWallets, fetchShares, fetchUserPermissions} from "Actions/UserActions";
import Router from "Views/Router";
import './App.scss';
import {
	Alerts,
	SideNav,
	TopNav
} from "components";


const hideNav = ["login","register","forgot_password","contact","about","404","verify_account","onboarding","access"];

function App() {

	const [showNav,setShowNav] = useState(false);
	const history = useLocation();
	const initialLoad = useSelector(store=>store.UserReducer.initialLoad);
	const userData = useSelector(store=>store.UserReducer.data);
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(fetchLoggedUser());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	useEffect(()=>{
		if(userData.uuid && userData.status === "active"){
			dispatch(fetchLoggedUserWallets());
			dispatch(fetchShares());
			dispatch(fetchUserPermissions())
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[userData,selectedOrganization])
	
	useEffect(()=>{
		const location = history.pathname;
		if(location !== "/" && !hideNav.includes(location.split("/")[1])){
			setShowNav(true);
		}else{
			setShowNav(false)
		}
	},[history])
	
	if(initialLoad){
		return <div className="loading">Loading</div>
	}else{
		return (
		<div className="App">
				{showNav && <SideNav/>}
				<Alerts/>
				<div className="mainPanel">
					{showNav && <TopNav/>}

					<div className="content">
						<Router/>
					</div>
					
				</div>
			</div>
		);
	}
}

export default App;
