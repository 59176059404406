import {formatCurrency, formatDate} from "Utils/TextFormat";
import Checkbox from "components/Checkbox";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import React, {useEffect, useState} from "react";
import "./Order.scss";
import {Table} from "components/Table";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Button from "components/Button";
import {useLocation, useNavigate, useParams} from "react-router";
import {EyeIcon} from "assets";

function Order() {

	const {state} = useLocation();
	const {id} = useParams();
	const [order,setOrder] = useState(null);
	const [loading,setLoading] = useState(true);
	const [memberships,setMemberships] = useState([]);
	const [showMembership,setShowMembership] = useState(null);
	const [products,setProducts] = useState([]);
	const [showProduct,setShowProduct] = useState(null);

	useEffect(()=>{
		if(state){
			setOrder(state.order);
			setLoading(false);
		}else{
			fetchOrder();
		}
	},[state]);

	useEffect(()=>{
		fetchMemberships();
		fetchProducts();
	},[order]);
	
	const fetchOrder = () => {
		axiosWithAuth().get("/users/orders/"+id).then(res=>{
			setOrder(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setOrder(null);
			}
		}).finally(() => {
			setLoading(false)
		})
	};

	const fetchMemberships = () => {
		// Todo - merge this with admin api call. Do it as a dispatcher look into orders for ideas
		axiosWithAuth().get(`/users/orders/${id}/memberships`).then(res=>{
			setMemberships(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setMemberships([]);
			}
		})
	}

	const fetchProducts = () => {
		// Todo - merge this with admin api call. Do it as a dispatcher look into orders for ideas
		axiosWithAuth().get(`/users/orders/${id}/products`).then(res=>{
			setProducts(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setProducts([]);
			}
		})
	}

	if(loading){
		return <div className="loading">Cargando orden de compra...</div>;
	}

	if(!order){
		return <div className="notFound">
			La orden de compra no has sido encontrada. Asegurate que la url esta bien. Si estas seguro ponte en contacto con un administrador.
		</div>;
	}

	return (
		<div className="Order">
			<Header title={"Orden de compra"} subtitle={order.orderId}/>

			<div className="content">
				<div className="card p-1 info">
					<div className="row gp-1">
						<InputField
							label="Id Orden"
							value={order.orderId}
							name="orderId"
							readOnly
						/>
						<InputField
							label="Fecha creacion"
							value={formatDate(order.createdAt)}
							name="createdAt"
							readOnly
						/>
					</div>

					<Textarea
						label="Descripcion"
						value={order.description}
						name="description"
						readOnly
					/>

					<Checkbox
						label="Sincronizado integrado (si aplica)"
						value={order.syncedIntegrate}
					/>
				</div>

					<div className="row gp-1 -fw -wrap">
						{memberships.length === 0?null:
								<div className="card p-1 memberships">
								<Header title="Membresias"/>

									<div className="memberships-wrapper">
		
									{memberships.map(m => {
										return <div className="membership">
											<EyeIcon
												onClick={()=>{setShowMembership(m)}}
											/>
											<p className="id">{m.id}</p>
											<p className="monthYear">{m.monthYear}</p>
											
											{m.syncedIntegrate?
											 <p className="integrated">
												Ya integrado!
											 </p>
											:null}
										</div>
									})}
									
								</div>
							</div>
						}

						{products.length === 0?null:
								<div className="card p-1 products">
								<Header title="Productos"/>

									<div className="products-wrapper">
									{products.map(p => {
										return <div className="product">
											<EyeIcon
												onClick={()=>{setShowProduct(p)}}
											/>
											<p className="name">
												<span className="quantity">{p.quantity}</span> x {p.name}
											</p>
											<p className="price">
												{p.price} c/u
											</p>
										</div>
									})}
									<div className="product-total">
										Total: {formatCurrency(
											products.reduce((acc,val) => {
												return acc + val.price * val.quantity
											},0))}
									</div>
								</div>
							</div>
						}

					</div>
			</div>
		
		
		
			{showMembership?
				<MembershipModal 
					membership={showMembership} 
					orderId={id}
					setShowMembership={setShowMembership}/>
			:null}
			{showProduct?
				<ProductModal 
					product={showProduct}
					setShowProduct={setShowProduct}/>
			:null}
		
		</div>
	);
}

function MembershipModal({membership,orderId,setShowMembership}){

	return <Modal showCloseIcon closeModal={()=>{setShowMembership(null)}} >
		<div className="modal-content primary membership">
			<Header title="Membresia" subtitle={orderId}/>

			<div className="row gp-1">
				<InputField
					label="Id"
					value={membership.id}
					name="id"
					readOnly
				/>
				<InputField
					label="Id Pago"	
					value={membership.paymentId}
					name="paymentId"
					readOnly
				/>
			</div>

			<div className="row gp-1">
				<InputField
					label="Mes"
					value={membership.monthYear}
					name="monthYear"
					readOnly
				/>
				<InputField
					label="Creada en"	
					value={formatDate(membership.createdAt)}
					name="createdAt"
					readOnly
				/>
			</div>
			
			<div className="controls">
				<Button action={()=>{setShowMembership(null)}}>Cerrar</Button>
			</div>
		</div>
	</Modal>
}

function ProductModal({product,setShowProduct}){

	const [benefits,setBenefits] = useState([]);

	useEffect(()=>{
		if(product?.id){
			fetchProductBenfits();
		}
	},[]);

	const fetchProductBenfits = () => {
		axiosWithAuth().get(`/products/${product.id}/benefits`).then(res => {
			setBenefits(res.data);
		}).catch(err => {
			console.log(err);
		})
	}

	return <Modal showCloseIcon closeModal={()=>{setShowProduct(null)}} className="Product">
		<div className="modal-content primary product">
			<Header title="Producto"/>

			<div className="row gp-1"> 
				<InputField
					label="Nombre"
					value={product.name}
					name="name"
					readOnly
				/>
				<InputField
					label="Precio Unidad"
					value={formatCurrency(product.price)}
					name="price"
					readOnly
				/>
			</div>

			<Textarea
				label="Descripcion"
				value={product.description}
				name="description"
				readOnly
			/>



			{benefits.length>0 && 
				<div className="benefitsSection">
					<Header
						title="Beneficios"
					/>
					<div className="benefitsWrapper">
						{benefits.map(b => <div className="benefit" key={b.id}>
							<p className="title">
								{b.name}
							</p>
							<p className="description">
								{b.description}
							</p>
						</div>	
						)}
					</div>
				</div>
			}

			{/* TODO - Add image component */}
			<div className="controls">
				<Button action={()=>{setShowProduct(null)}}>Cerrar</Button>
			</div>
		</div>
	</Modal>
}


export default Order;