import classNames from "classnames";
import React from "react";
import "./Card.scss";

function Card({
	children,
	centered=false,
	action,
	className
}) {
	return (
		<div 
			className={classNames(
				"Card",
				className,
				{centered}
			)}
			onClick={action}
		>
			{children}
		</div>
	);
}

Card.Title = ({
	children,
	borderless,
	align="left"
}) => {
	return (<div 
	className={classNames("Card-title",{borderless})}
	style={{
		textAlign:align
	}}
	
	>
		{children}
	</div>)
}

Card.Content = ({
	children,
	row=false,
	centered=false
}) => {
	return (<div className={classNames("Card-content",{row,centered})}>
		{children}
	</div>)
}

Card.Controls = ({children,borderless}) => {
	return (<div className={classNames("Card-controls",{borderless})}>
		{children}
	</div>)
}

export default Card;