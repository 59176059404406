import React, {useEffect, useState} from "react";
import Header from "components/Header/Header";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./IntegrateSystem.scss";
import {useQuery} from "Utils/hooks";
import {Route, Routes} from "react-router";
import {NavLink} from "react-router-dom";
import {MembershipModal, OrderModal, PaymentModal} from "Modals/IntegrateModals";
import {OrdersTable,PaymentsTable,MembershipsTable} from "components/Table/Common/IntegrateTables";
import Card from "components/Card";
import classNames from "classnames";
import useIntegrateSystemData from "Utils/hooks/useIntegrateSystemData";
import {Table} from "components/Table";
import {EyeIcon} from "assets";
import {TableItem} from "components/Table";
import {List} from "components";
import {formatDate} from "Utils/TextFormat";
import Button from "components/Button";

function IntegrateSystem() {

	const [integrates,setIntegrates] = useState(["abbafreedom"]);
	const [integrate,setIntegrate] = useState(integrates[0]);

	useEffect(()=>{
		setIntegrate(integrates[0])
	},[]);

	return (
		<div className="IntegrateSystem">
			<Header title={"Sistema Integrado " + integrateValues[integrate] || ""}  />
			<div className="options">
				{/* TODO - Dropdown with integrate modules ---  */}
			</div>

			<div className="content">
				<IntCard
					title="Ordenes de compra"
					type="orders"
					integrate={integrate}
				/>
				<IntCard
					title="Pagos"
					type="payments"
					integrate={integrate}
				/>
				<IntCard
					title="Posiciones"
					type="positions"
					integrate={integrate}
				/>
			</div>
		</div>
	);
}

export default IntegrateSystem;

const integrateValues = {
	"abbafreedom":"Abba Freedom"
}


const intTitle = {	
	id:{
		"orders": "Orden",
		"payments": "Pago",
		"positions": "Posicion",
	}
}

export const IntCard = ({
	title,
	setModal,
	integrate,
	type
}) => {

	const {
		data,
		page,
		prevPage,
		nextPage,
		pagination,
		loading,
		error
	} = useIntegrateSystemData(type,integrate);

	return <Card className={classNames("Int",`Int-${type}`)}>
		<Card.Title>
			<p>{title}</p>
			<p>{pagination?.total && "Total: " + pagination.total}</p>
		</Card.Title>

		<Card.Content>
			<List xscrollable>
				{data.map(d => <List.Item key={d.id}
					action={()=>{
						console.log("Open modal orders for", d.id)
					}}
				>

					<p className="id">
						{intTitle?.id[type]}: {d.id}
					</p>

					{
						d.gen1 && <div className="gen">
							Nivel maximo: {getMaxLevel(d)}
						</div>
					}

					<p className="date">
						{formatDate(d.createdAt)}
					</p>
				
				
				</List.Item>
				)}
			</List>
		</Card.Content>

		<Card.Controls>
			{pagination?.lastPage &&  page + " de " + pagination.lastPage}
				<Button
					disabled={page === 1}
					action={prevPage}
				>
					Anterior
				</Button>
				<Button
					disabled={page >= pagination?.lastPage || !pagination?.lastPage}
					action={nextPage}
				>
					Siguiente
				</Button>
		</Card.Controls>
	</Card>


}

const getMaxLevel = (position) => {

	const {gen1,gen2,gen3,gen4,gen5} = position;
	const levels = { gen1, gen2, gen3, gen4, gen5 };

	let maxLevelReached = 0;

	Object.keys(levels).forEach((key, index) => {
		if (levels[key] !== null) {
			maxLevelReached = index + 1; // `index + 1` maps to the actual level number (1-based index)
		}
	});



	return maxLevelReached;
	
}


