import React, {useState,useEffect} from "react";
import Button from "components/Button";
import InputField from "components/InputField";
import Header from "components/Header";
import "./ChangePassword.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";
import Checkbox from "components/Checkbox";

function ChangePassword() {

	const [showPassword,setShowPassword] = useState(false);
	const [data,setData] = useState({
		newPassword:"",
		oldPassword:"",
		confirmPassword:""
	});
	const [error,setError] = useState(null);
	const dispatch = useDispatch();

	const changePassword = (e) => {
		e.preventDefault();
		if(data.newPassword === data.confirmPassword){
			axiosWithAuth().post("auth/password/change",{newPassword:data.newPassword,oldPassword:data.oldPassword}).then(res=>{
				if(res.status == 200){
					const sessions = String(res.data.message).split(" ").pop();
					dispatch(addAlert({text:`Contraseña cambiada correctamente.
					${sessions} sesion(es) terminadas.`, type:"success"}));
				}
			}).catch(err=>{
				if(err?.response?.status === 403){
					setError("La contraseña no es correcta");
				}
			})
		}else{
			dispatch(addAlert({text:"Las contraseñas no coinciden", type:"error"}));
		}
	}

	useEffect(()=>{
		if(error && data.oldPassword.length>0){
			setError(null);
		}
	},[data])

	return (
		<div className="ChangePassword">
			<Header title="Cambio de contraseña" subtitle={"Coloca una contraseña Utillizando números, mayusculas y simbolos."}/>
			<form className="newPassword" action="changePassword" onSubmit={changePassword}>
				<div className="inputs">
					<InputField
						name="oldPassword"
						value={data.oldPassword}
						setValue={(nVal) => setData({...data, oldPassword:nVal})}
						label="Contraseña actual"
						type={showPassword?"text":"password"}
						error={error}
					/>
					<InputField
						name="newPassword"
						value={data.newPassword}
						setValue={(nVal) => setData({...data, newPassword:nVal})}
						label="Nueva Contraseña"
						type={showPassword?"text":"password"}
					/>
					<InputField
						name="newPasswordConfirm"
						value={data.confirmPassword}
						setValue={(nVal) => setData({...data, confirmPassword:nVal})}
						label="Confirmar Contraseña"
						type={showPassword?"text":"password"}
					/>
					<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
				</div>
				<div className="cta-actions">
					<Button className="sendCode" variant="primary" type="submit">
						Cambiar contraseña
					</Button>
				</div>
			</form>

		</div>
	);
}

export default ChangePassword;
