import React from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "components/Button";
import { ReactComponent as LoginLogo } from "../../assets/illustrations/login.svg";
import { ReactComponent as LogoText } from "../../assets/logos/logo-text.svg";
import {useSelector} from "react-redux"; 
import "./Homepage.scss";
import {useEffect} from "react";

function Homepage() {
	
	const loggedIn = useSelector(store=>store.UserReducer.loggedIn);
	const navigate = useNavigate();

	useEffect(()=>{
		if(loggedIn){
			navigate("/dashboard")
		}
	},[loggedIn])

	return (
		<div className="Homepage">
			<div className="header">
				{/* <LogoText/> */}
				<img src={require("../../assets/logos/logo-text.png")} className="logo-text"/>
			</div>
			<div className="Homepage-content">
				<div className="illustration">
					<LoginLogo/>
				</div>
				<div className="actions">
					<h1 className="title">Bienvenido</h1>
					{/* <p className="subtitle">Lorem Ipsum is simply dummy text of the printing and</p> */}
					<div className="actions">
						<Link to="/login">
							<Button className="btn login">Iniciar sesion</Button>
						</Link>
						<Link to="/register">
							<Button variant="secondary" className="btn register">Registrarse</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Homepage;