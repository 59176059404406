import {
	FETCH_PRODUCTS_START,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_FAILURE,
	EDIT_PRODUCTS_START,
	EDIT_PRODUCTS_SUCCESS,
	EDIT_PRODUCTS_FAILURE
} from "../Actions/ProductsActions"

const initialState = {
	products:[],
	error:null,
	getStatus:null,
	postStatus:null,
	response: null,
}

const ProductsReducer = (state=initialState,action) => {
	switch(action.type){

		case FETCH_PRODUCTS_START:
			return { ...state, getStatus:true, response:null, error:null}
		case FETCH_PRODUCTS_SUCCESS:
			return { ...state, getStatus:false, products:action.payload.data,  response:action.payload.response}
		case FETCH_PRODUCTS_FAILURE:
			return { ...state, getStatus:false, error:action.payload.error,  response:action.payload.response}


		case EDIT_PRODUCTS_START:
			return { ...state, postStatus:true, response:null, error:null}
		case EDIT_PRODUCTS_SUCCESS:
			return { ...state, postStatus:false, response:action.payload.response}
		case EDIT_PRODUCTS_FAILURE:
			return { ...state, postStatus:false, error:action.payload.error,  response:action.payload.response}


		default:
			return state;
	}
}

export default ProductsReducer;