import {
	ADD_ALERT, 
	CLEAR_ORGANIZATION, 
	POP_ALERT,
	UPDATE_ORGANIZATION,
} from "../Actions/SystemActions"

const initialState = {
	alerts:[],
	organizations:{
		// Fetch from db and put it in state
		1:{
			id:1,
			name:"Abbareal"
		},
		2:{
			id:2,
			name:"Abbasunrise"
		},
		3:{
			id:3,
			name:"Abbacity"
		}
	},
	selectedOrganization:{}
}

const SystemReducer = (state=initialState,action) => {
	switch(action.type){
		case ADD_ALERT:
			return {...state, alerts:[...state.alerts,action.payload]}

		case POP_ALERT:
			return {...state, alerts:state.alerts.slice(1)}

		case CLEAR_ORGANIZATION:
			return {
				...state,
				selectedOrganization: {}
			}
			
		case UPDATE_ORGANIZATION:
			if(state.organizations[action.payload]){
				return {
					...state,
					selectedOrganization: state.organizations[action.payload]
				}
			}else{
				return state;
			}
			
		default:
			return state;
	}
}

export default SystemReducer;