import React from "react";
import {Table} from "components/Table";
import Pagination from "components/Pagination/Pagination";
import {TableItem} from "../Table";
import {formatDate} from "Utils/TextFormat";

export function SharesTable({transactions,pagination,setPage}) {

	return (
		<>
			<Table columns={{
				uuid:"id",
				amount:"Cantidad",
				lastBalance:"Cantidad previa",
				description:"Descripcion",
				Tipo:"Tipo",
				receiverId:"receptor",
				createdAt:"Fecha"
			}}>
				
				{transactions.map(transaction=> 
				
				<TableItem key={transaction.uuid}>
					<td>{transaction.uuid}</td>
					<td>{transaction.amount}</td>
					<td>{transaction.lastBalance}</td>
					<td>{transaction.description}</td>	
					<td>{transaction.amount>0?"Recarga":"Cobro"}</td>
					<td>{transaction.receiverId}</td>
					<td>{formatDate(transaction.createdAt)}</td>
				</TableItem>
				)}
			</Table>

		{pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null}
		</>
	);
}
