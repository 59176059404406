import Header from "components/Header";
import {TableItem} from "components/Table";
import {Table} from "components/Table";
import React from "react";
import {useSelector} from "react-redux";

function OrganizationsPage() {
	const organizations = Object.values(useSelector(store => store.SystemReducer).organizations)
	return (
		<div className="Organizations">
			<Header
				title="Organizaciones"
			/>
			<div className="content">
				<Table columns={["id","name"]}>
					{organizations.map(org => <TableItem key={org.id}>
						<td>{org.id}</td>
						<td>{org.name}</td>
					</TableItem>
					)}
				</Table>
			</div>
		</div>
	);
}

export default OrganizationsPage;