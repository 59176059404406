// useFetchShares.js
import {addAlert} from "Actions/SystemActions";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {axiosWithAuth} from "Utils/axiosWithAuth";


const useFetchShares = (
	userId = null
) => {

	const [transactions,setTransactions] = useState([]);
	const [pagination,setPagination] = useState({});
	const [page,setPage] = useState(1);
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchSharesHistory()
	}, [selectedOrganization?.id,page]);

	
	const fetchSharesHistory = () => {
		const endpoint = userId ? `users/admin/${userId}/shares/history` : "users/shares/history"
		axiosWithAuth().get(endpoint,{
			params:{
				organizationId: selectedOrganization?.id,
				page
			}
		}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}))
			setTransactions(res.data);
		}).catch(err => {
			if(err.response.status === 404){
				setTransactions([])
				dispatch(addAlert({text:`No se han encontrado transacciones${selectedOrganization?.id?" para esa organizacion":""}`}))
			}else{
				dispatch(addAlert({text:"Ha ocurrido un errror", type:"error"}))
			}
		})
	}


	return { transactions, pagination, setPage, fetchSharesHistory };
};

export default useFetchShares;
