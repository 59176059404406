import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import React, {useEffect, useState} from "react";
import "./WithdrawalModal.scss"
import {formatCurrency, formatDate} from "Utils/TextFormat";
import Confirmation from "components/Confirmation/Confirmation";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";

function WithdrawalModal({
	withDrawal,
	setWithdrawal,
	isAdmin=false
}) {

	return (
		<Modal showCloseIcon closeModal={()=>{setWithdrawal(null)}} >
			<div className="modal-content primary withdrawal">
				
				<Header title="Retiro" subtitle={isAdmin?withDrawal.id:null}/>
				
				{isAdmin?
					<RenderAdminFields w={withDrawal} setWithdrawal={setWithdrawal}/>
					:<RenderFields w={withDrawal}/>
				}

				{!isAdmin?<div className="controls">
					<Button action={()=>{setWithdrawal(null)}}>Cerrar</Button>
				</div>:null}
			</div>
		</Modal>
	);
}

export default WithdrawalModal;


const RenderFields = ({w}) => {

	console.log({w})

	return <>
	
		<div className="row">
			<InputField
				label="Id transaccion"
				value={w.transactionId}
				readOnly
			/>
			<InputField
				label="Tipo"
				value={w.type}
				readOnly
			/>
			<InputField
				label="Cantidad"
				value={formatCurrency(w.amount)}
				readOnly
			/>
		</div>
		<InputField
			label="Numero de confirmacion"
			value={w.confirmationNumber || ''}
			readOnly
		/>

		<div className="row">
			<InputField
				label="Fecha de creacion"
				value={formatDate(w.createdAt)}
				readOnly
			/>
			<InputField
				label="Fecha de actualizacion"
				value={formatDate(w.createdAt)}
				readOnly
			/>
		</div>

		<Textarea
			label="Descripcion de la solicitud"
			value={w.transactionDescription || ''}
			readOnly
		/>

		<Textarea
			label="Descripcion"
			value={w.description || ''}
			readOnly
		/>
	</>

}

const RenderAdminFields = ({w,setWithdrawal}) => {
	
	const [data,setData] = useState({
		description:'',
		confirmationNumber:''
	});
	const [showCloseConfirmation,setShowCloseConfirmation] = useState(false);
	const [showUpdateConfirmation,setShowUpdateConfirmation] = useState(false);
	const [showRejectConfirmation,setShowRejectConfirmation] = useState(false);
	const dispatch = useDispatch();

	const completeWithdrawal = () => {
		axiosWithAuth().patch(`/withdrawals/admin/${w.id}/complete`,data).then(res=>{
			if(res.status === 200){
				setWithdrawal(null)
				dispatch(addAlert({type:"success",text:"Retiro completado exitosamente"}))
			}
		}).catch(err=>{
			if(err.response.status === 409){
				dispatch(addAlert({type:"error",text:"El retiro ya ha sido completado"}))
			}else{
				console.log(err);
				dispatch(addAlert({type:"error",text:"Ha ocurrido un error"}))
			}

		})
	}

	const rejectWithdrawal = () => {
		axiosWithAuth().patch(`/withdrawals/admin/${w.id}/reject`,data).then(res=>{
			if(res.status === 200){
				setWithdrawal(null)
				dispatch(addAlert({type:"success",text:"Retiro rechazado exitosamente"}))
			}
		}).catch(err=>{
			if(err.response.status === 409){
				dispatch(addAlert({type:"error",text:"El retiro ya ha sido completado"}))
			}else{
				console.log(err);
				dispatch(addAlert({type:"error",text:"Ha ocurrido un error"}))
			}

		})
	}

	const handleChange = (e) => {
		setData({...data, [e.target.name] : e.target.value})
	}

	useEffect(()=>{
		console.log(w)
		setData({
			description:w.description,
			confirmationNumber:w.confirmationNumber
		})
	},[w])

	console.log(data)


	return <>
	
		<div className="row">
			<InputField
				label="Id transaccion"
				value={w.transactionId}
				readOnly
			/>
			<InputField
				label="Tipo"
				value={w.type}
				readOnly
			/>
			<InputField
				label="Cantidad"
				value={formatCurrency(w.amount)}
				readOnly
			/>
		</div>

		<div className="row">
			<InputField
				label="User ID"
				value={w.userId}
				readOnly
			/>
			<InputField
				label="Correo electronico"
				value={w.email}
				readOnly
			/>
			<InputField
				label="Telefono"
				value={w.phone}
				readOnly
			/>

		</div>

		<div className="row">
			<InputField
				label="Fecha de creacion"
				value={formatDate(w.createdAt)}
				readOnly
			/>
			<InputField
				label="Fecha de actualizacion"
				value={formatDate(w.createdAt)}
				readOnly
			/>
		</div>


		<InputField
			label="Numero de confirmacion"
			value={data.confirmationNumber || ''}
			onChange={handleChange}
			name="confirmationNumber"
		/>

		<Textarea
			label="Descripcion solicitud"
			value={w.transactionDescription || ''}
			name="transaction.description"
			readOnly
		/>

		<Textarea
			label="Descripcion retiro"
			value={data.description || ''}
			onChange={handleChange}
			maxLength={500}
			name="description"
		/>

		<div className="controls">


			<Button
				action={()=>{
					if((data.description || data.confirmationNumber) && w.status === "pending"){
						setShowCloseConfirmation(true)
					}else{
						setWithdrawal(null)
					}
				}}
			>Cancelar</Button>

			{w.status === "pending"?
			<>
			
				<Button
					variant="secondary"
					disabled={(!data.description)}
					action={()=>{setShowRejectConfirmation(true)}}
				>Rechazar retiro</Button>
				<Button
					action={()=>{setShowUpdateConfirmation(true)}}
					disabled={(!data.description || !data.confirmationNumber)}
				>Guardar Cambios</Button>
			</>
			:null}
		</div>


		{showUpdateConfirmation?<Confirmation
			title="Completar retiro?"
			description={`Numero de confirmacion:${data.confirmationNumber}. Descripcion ${data.description}`}
			closeModal={()=>{setShowUpdateConfirmation(null);}}
			accept={completeWithdrawal}
		/>:null}

		{showRejectConfirmation?<Confirmation
			title="Rechazar retiro?"
			description={`Estas seguro que deseas rechazar este retiro? La cantidad sera devuelta al usuario original.`}
			closeModal={()=>{setShowRejectConfirmation(null);}}
			accept={rejectWithdrawal}
		/>:null}

		{showCloseConfirmation?<Confirmation
			title="Cancelar cambios?"
			description={`Se perderan todos los datos no guardados`}
			closeModal={()=>{setShowCloseConfirmation(null);}}
			accept={()=>{setWithdrawal(null)}}
		/>:null}
	
	</>
}