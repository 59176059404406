import React, {useState,useEffect, useRef} from "react";
import Header from "components/Header";
import Button from "components/Button";
import {Table} from "components/Table";
import InputField from "components/InputField";
import "./Affiliates.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {AffiliatesTable} from "components/Table";
import Tree from "react-d3-tree";
import {useDispatch, useSelector} from "react-redux";
import {useCenteredTree} from "Utils/hooks";
import {addAlert} from "Actions/SystemActions";

function Affiliates() {

	const [affiliates,setAffiliates] = useState([]);
	const [pagination,setPagination] = useState(null);
	const [page,setPage] = useState(1);
	const [query,setQuery] = useState("");
	const userData = useSelector(store=>store.UserReducer.data);

	useEffect(()=>{
		if(query.length===0 || query.length >= 3){
			getAffiliates();
		}
	},[page,query]);
	
	const getAffiliates = () => {
		axiosWithAuth().get("/users/affiliates",{
			params:{
				page,
				query
			}
		}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}));
			setAffiliates(res.data);
		}).catch(err=>{
			if(err?.response?.status === 404){
				setAffiliates([]);
				setPagination({})
			}else{
				console.log(err);
			}
		})
	}

	return (
		<div className="Affiliates">
			<Header title={"Mis Afiliados"}/>
			<div className="wrapper">
				{/* <div className="options">
					<div className="search">
						<InputField
							name="search"
							value={query}
							setValue={(nVal) => {setQuery(nVal)}}
							placeholder={"Buscar afiliado"}
						/>

					</div>
				</div>
{/* 
				<AffiliatesTable
					setPage={setPage}
					affiliates={affiliates}
					pagination={pagination}
				/> */} 

				<AffiliatesTree
					userData={userData}
				/>
			
			</div>
		</div>
	);
}

export default Affiliates;


export const AffiliatesTree = ({userData,isAdmin=false}) => {
	

	const [tree,setTree] = useState(
		{
			name: userData.abbasoonCode,
			attributes: {
				"Nombre": userData.fullName
			},
		}
	);
	const dispatch = useDispatch();

	const fetchDirectAffiliates = async (affiliateCode) => {
		const endpoint = isAdmin?`/users/admin/${userData.id}/affiliates/tree`:`/users/${affiliateCode}/directReferrals`
		return axiosWithAuth().get(endpoint, {
			params:{
				abbasoonCode: affiliateCode
			}
		}).then(res => {
			return res.data?.map(a => {
				return {
					name: a.abbasoonCode,
					attributes: {
						"Nombre": a.fullName,
					},
				}
			})
		}).catch(err => {
			if(err?.response.status === 404){
				return [];
			}else{
				console.log(err);
			}
		})
	}

	const [translate, containerRef] = useCenteredTree();

	const nodeClickHandler = async (e) => {
		if(!e.data?.children){
			const children = await fetchDirectAffiliates(e.data.name) || [];
			updateTreeData(e.data.name,children);
		}else{
			dispatch(addAlert({text: "Ese usuario no ha referido a nadie todavia."}))
		}
	}

	const updateTreeData = (abbasoonCode, children) => {
		const newTreeData = { ...tree };

		const findNode = (currentNode) => {
			if(currentNode.name === abbasoonCode)	{
				currentNode.children = children;
				return true;
			}

			// If not, take all the children an go into every one
			if(currentNode.children){
				for(const child of currentNode.children){
					if(findNode(child)){
						return true;
					}
				}
			}

			return false;
		}		

		findNode(newTreeData);
		setTree(newTreeData);
	}



	if(!tree){
		return <div className="loading">
			Cargando
		</div>
	}

	return (
		<div className="treeWrapper" ref={containerRef}>
			<Tree
				data={tree}
				pathFunc={"step"}
				orientation="vertical"
				translate={translate}
				onNodeClick={nodeClickHandler}
				nodeSize={{x: 200, y: 200}} 
			/>
		</div>
	)

}