import React, { useState, useEffect } from "react";
import Card from "components/Card";
import Modal from "components/Modal";
import Button from "components/Button";
import { axiosWithAuth } from "Utils/axiosWithAuth";
import InputField from "components/InputField";
import Dropdown from "components/Dropdown";

function ProductBenefitModal({ setProductItemBenefits, productItemBenefits }) {
    const [product_Benefit_Types_list, setProduct_Benefit_Types_list] = useState([]);
    const [addedBenefitType, setAddedBenefitType] = useState({});
    const [delBenefitTypes, setDelBenefitTypes] = useState([]);

    const handleAddBenefitType = (benefitType) => {
        setAddedBenefitType({
            ...addedBenefitType,
            [benefitType.id]: {
                id: 0,
                name: benefitType.name,
                benefitId: benefitType.id,
                productId: productItemBenefits.id
            }
        })
    }

    const handleRemove = (id) => {
        const newaddedBenefitType = addedBenefitType;
        if (newaddedBenefitType[id]) {
            const delBenefitType = newaddedBenefitType[id];
            if (delBenefitType.id > 0) {
                delBenefitTypes.push(delBenefitType.id);
                setDelBenefitTypes(delBenefitTypes);
            }
        }
        delete newaddedBenefitType[id];
        setAddedBenefitType({ ...newaddedBenefitType });
    }

    const getProduct_Benefit =(productId)=>{
		axiosWithAuth().get("/products/benefits/product_benefits?resultsPerPage=10000&query=productId:"+productId).then(res=>{
			if(res.status===200){
				let datos={};
				res.data.map(product_Benefit=>{
						datos[product_Benefit.benefitId]={
							id: product_Benefit.id,
							benefitId: product_Benefit.benefitId,							
							productId: product_Benefit.productId,
                            name:product_Benefit.name			
						}
				})	
				setAddedBenefitType(datos);
			}
		}).catch(err=>{
			console.log(err);
		})
	}

    const getProduct_Benefit_Types = () => {
        axiosWithAuth().get("/products/benefits/search?resultsPerPage=10000").then(res => {
            if (res.status === 200) {
                setProduct_Benefit_Types_list(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const handleUpdate = () => {
		const BenefitTypesAdd=[];
		Object.values(addedBenefitType).map(p =>{
			if(p.id===0){
				BenefitTypesAdd.push({benefitId:p.benefitId, productId:p.productId })
			}
		});		
		if(BenefitTypesAdd?.length>0){
			axiosWithAuth().post("/products/benefits/product_benefits",{product_benefits:BenefitTypesAdd}).then(res=>{
				if(res.status===200){
					setProductItemBenefits(null);
				}
			}).catch(err=>{
				console.log(err);
			})
		}	
		if(delBenefitTypes?.length>0){
			axiosWithAuth().patch("/products/benefits/product_benefits/del",{product_benefits:delBenefitTypes}).then(res=>{
				if(res.status===200){
					setProductItemBenefits(null);
				}
			}).catch(err=>{
				console.log(err);
			})
		}
		if(BenefitTypesAdd?.length==0 && delBenefitTypes?.length==0){
			setProductItemBenefits(null);
		}										
	}

    useEffect(() => {
        getProduct_Benefit_Types();
    }, []);

    useEffect(()=>{
		getProduct_Benefit(productItemBenefits.id);
	},[productItemBenefits]);

    return (
        <Modal className="edit" showCloseIcon closeModal={() => { setProductItemBenefits(null) }}>
            <Card>
                <Card.Title>
                    Benefits
                </Card.Title>
                <Card.Content>                   
                        <div className="edit-area">
                            <InputField
                                label="id"
                                value={productItemBenefits.id}
                                readonly
                            />
                            <Dropdown title={"Tipo de beneficio"}>
                                {product_Benefit_Types_list.map(benefitType => {
                                    if (!Object.keys(addedBenefitType).includes("" + benefitType.id)) {
                                        return <Dropdown.Item key={benefitType.id} action={() => { handleAddBenefitType(benefitType) }}>
                                            <p>{benefitType.name}</p>
                                        </Dropdown.Item>
                                    }
                                })}
                            </Dropdown>
                        </div>
                        <div className="content">
                            {Object.values(addedBenefitType).map(p => <div className="addedProduct">
                                <InputField
                                    className="name"
                                    value={p.name}
                                    readOnly
                                />
                                <InputField
                                    className="quantity"
                                    name={p.benefitId}
                                    value={p.benefitId}
                                    readOnly
                                    slot={<Button action={() => { handleRemove(p.benefitId) }}>
                                        Remove
                                    </Button>}
                                />
                            </div>)}
                        </div>                  
                </Card.Content>
                <Card.Controls borderless>
                        <Button variant="secondary" action={() => { setProductItemBenefits(null) }}>Cancelar</Button>
                        <Button variant="primary" action={handleUpdate}>Actualizar</Button>
                </Card.Controls>
            </Card>
        </Modal>
    );
}

export default ProductBenefitModal;