import React, {useEffect, useState} from "react";
import Header from "components/Header/Header";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./BlockableActions.scss";
import Button from "components/Button/Button";
import {Table} from "components/Table";
import Checkbox from "components/Checkbox";
import {TableItem} from "components/Table";
import {formatDate} from "Utils/TextFormat";
import Modal from "components/Modal/Modal";
import Confirmation from "components/Confirmation/Confirmation";

function BlockableActions({userId}) {

	const [blockableActionsItems,setBlockableActionsItems] = useState([]);		
	useEffect(()=>{
		getBlockableActions(userId);
	},[userId])

	const getBlockableActions = (userId) => {			
		axiosWithAuth().get(`users/blocks/${userId}`).then(res => {
			setBlockableActionsItems(res.data);
		})
	}		
	return (
		<div className="BlockableActions">
			<Header title="Acciones bloqueadas"/>
			<div className="content">				
				<BlockableActionsItems blockableActionsItems={blockableActionsItems} userId={userId}/>				
			</div>
		</div>
	);
}

export default BlockableActions;

const BlockableActionsItems = ({blockableActionsItems,userId}) => {		
	const unassign = (userId,blockableActionsId) => {			
		axiosWithAuth().patch(`users/blocks/${userId}/unassign`,{blockableActionsId:blockableActionsId}).then(res => {				
			window.location.reload();
		})
	}
	const assign = (userId,blockableActionsId) => {			
		axiosWithAuth().patch(`users/blocks/${userId}/assign`,{blockableActionsId:blockableActionsId}).then(res => {				
			window.location.reload();
		})
	}
	return (
		<Table 
			columns={[
						"Id",
						"Nombre",
						"Descripción",
						""					
			]}>
			{blockableActionsItems?.map(o => <TableItem key={o.id}>
				<td>{o.id}</td>
				<td>{o.name}</td>
				<td>{o.description}</td>	
				<td>
					<Checkbox
							label={o.blockableActionsId != null? "Habilitar": "Bloquear"}
							value={o.blockableActionsId != null? true: false}
							action={()=>{o.blockableActionsId != null? unassign(userId,o.id) : assign(userId,o.id) }}
					/>						
				</td>			
				</TableItem>)}
		</Table> 
	)
}