import React, {useState} from "react";
import classNames from "classnames";
import "./Image.scss"
import Modal from "components/Modal";

function Image({
	className,
	type,
	src="",
	alt="",
	action=null,
	...restProps
}) {

	const [previewModal,setPreviewModal] = useState(false);


	const handleClick = () => {
		if(action){
			action();
		}else{
			setPreviewModal(true);
		}
	}

	return (<>
		<div 
			className={classNames("Image",{type,className})}
			onClick={handleClick}
		>
			<img
				src={src}
				alt={alt}
				{...restProps}
			/>
		</div>

		{previewModal?
			<PreviewModal
				closeModal={()=>{setPreviewModal(false)}}
				src={src}
			/>
		:null}

	</>);
}


const PreviewModal = ({closeModal, src}) => {
	return <Modal className="ImagePreview" closeModal={closeModal} showCloseIcon>
		<img src={src} alt="Full preview"/>
	</Modal>
}

export default Image;