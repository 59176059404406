import React, {useState, useRef} from "react";
import "./FileUpload.scss";
import {CrossIcon} from "assets";
import {axiosWithAuth} from "Utils/axiosWithAuth";



import axios from "axios";
import Button from "components/Button";
import Modal from "components/Modal";
import Image from "components/Image/Image";

export default function FileUpload() {
	return (
		<div className="FileUpload">
			{/* TBI */}
		</div>
	);
}

const ImageUpload = ({imageUrl,path,onUpload}) => {
	const [file,setFile] = useState(null);
	const [previewUrl,setPreviewUrl] = useState(null);
	const [showPreviewModal,setShowPreviewModal] = useState(false);
	const inputRef = useRef();

	const onFileUpload = (e) => {
		setFile(e.target.files[0]);
		setPreviewUrl(URL.createObjectURL(e.target.files[0]));
	}

	const pickFile = () => {
		inputRef.current.click();
	}

	const uploadFile = async() => {

		try {
			const signedData = (await axiosWithAuth().get("auth/signed_url",{
				params:{
					path:path,
					contentType:file.type
				}
			})).data;

			if(!signedData.url){
				throw "noSignedUrl";
			}

			const formData = new FormData();
			formData.append("Content-Type", file.type);
			Object.entries(signedData.fields).forEach(([k, v]) => {
				formData.append(k, v);
			});
			formData.append("file",file);

			const location = (await axios.post(signedData.url,formData))

			if(location.status === 204){
				onUpload(`https://${signedData.fields.bucket}.s3.amazonaws.com/${path.replace("?","%3F").replace("=","%3D")}`)
			}
		} catch (error) {
			console.log(error);
		}
	}


	return <div className="fileUpload-image">
				
		<input type="file" name="productImage" onChange={onFileUpload} accept="image/*" ref={inputRef}/> 


		<Image
			src={previewUrl||imageUrl} className="preview"
		/>

		{/* {(file||imageUrl)?
			<div className="preview-wrapper" onClick={()=>{setShowPreviewModal(true)}}>
				<img src={previewUrl||imageUrl} className="preview"/>
			</div>
		:null} */}

		<div className="actions">
			<Button action={pickFile}>Elegir imagen</Button>
			{(file)?<Button action={uploadFile} variant="tertiary">Subir imagen</Button>:null}
		</div>

		{showPreviewModal?
			<Modal className="fullPreview">
				<span className="closeIcon" onClick={()=>{setShowPreviewModal(false)}} >
					<CrossIcon/>
				</span>
				<img src={previewUrl||imageUrl}/>
			</Modal>
		:null}

	</div>
}

FileUpload.Image = ImageUpload;