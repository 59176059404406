export const ADD_ALERT =  "ADD_ALERT";

export const addAlert = (alert) => {
	return (dispatch) => {
		dispatch({ type:ADD_ALERT, payload:alert });
	}
}

export const POP_ALERT =  "POP_ALERT";

export const popAlert = () => {
	return (dispatch) => {
		dispatch({ type:POP_ALERT });
	}
}

export const UPDATE_ORGANIZATION =  "UPDATE_ORGANIZATION";
export const CLEAR_ORGANIZATION =  "CLEAR_ORGANIZATION";

export const updateOrganization = (organizationId) => {
	if(!organizationId){
		return (dispatch) => {
			dispatch({ type:CLEAR_ORGANIZATION });
		}
	}
	return (dispatch) => {
		dispatch({ type:UPDATE_ORGANIZATION, payload:organizationId });
	}
}