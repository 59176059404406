import React,{useState,useEffect} from "react";
import "./Pagination.scss";

function Pagination({page=1,setPage,maxPages,wings=2}) {


	const [displayPages,setDisplayPages] = useState([]);

	useEffect(()=>{
		setDisplayPages(getNeighbourPages());
	},[page,maxPages]);

	const getNeighbourPages = () => {


		let pages = [];
		var lSide = page-wings;
		var rSide = page+wings+1;
	
		if(lSide<=2){
			rSide += (1-lSide) + 1;
			lSide=2;
		}
		if(rSide>maxPages){
			lSide -= (rSide - maxPages);
			if(lSide<=2){
				lSide=2;
			}
			rSide = maxPages;
		}

		// console.log({lSide,rSide})

		for(let x = lSide;x<rSide;x++){
			pages.push(x);
		}
		return pages;
	}

	return (
		<div className="Pagination">
			{maxPages>1?
				<div className={`page ${page==1?"selected":""}`} onClick={()=>{setPage(1)}}>1</div>
			:null}

			{displayPages[0] != 2?
				<div className="spacer"></div>
			:null}

			{displayPages.map((_page,index)=>{
				return <div key={index} className={`page ${page==_page?"selected":""}`} onClick={()=>{setPage(_page)}}>{_page}</div>
			})}

			{displayPages[4] != maxPages-1?
				<div className="spacer"></div>
			:null}

			<div className={`page ${page==maxPages?"selected":""}`} onClick={()=>{setPage(maxPages)}}>{maxPages}</div>
		</div>
	);
}

export default Pagination;

