import React, {useEffect, useState} from "react";
import Header from "components/Header/Header";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./IntegrateSystem.scss";
import Button from "components/Button/Button";
import {Table} from "components/Table";
import {TableItem} from "components/Table";
import {formatDate} from "Utils/TextFormat";
import Modal from "components/Modal/Modal";
import Confirmation from "components/Confirmation/Confirmation";

function IntegrateSystem() {

	const [pendingOrders,setPendingOrders] = useState([])
	const [showSyncConfirm,setShowSyncConfirm] = useState(false);

	useEffect(()=>{
		fetchPendingOrders();
	},[])

	const fetchPendingOrders = () => {
		// Only returns the most recent ones as a preview
		axiosWithAuth().get("integratesystem/orders/pending/").then(res => {
			setPendingOrders(res.data);
		})
	}

	const syncPendingOrders = () => {
		axiosWithAuth().post("integratesystem/sync").then(res => {
			console.log(res)
		})
	}


	return (
		<div className="IntegrateSystem">
			<Header title="Sistema Integrado"/>

			<div className="content">
				<div className="actions">
					<Button action={()=>{setShowSyncConfirm(true)}}>
						Enviar ordenes a integrado
					</Button>
				</div>
				{/* Hide if no pending orders */}
				<PendingOrders pendingOrders={pendingOrders}/>
			</div>


			{showSyncConfirm?<Confirmation
				title="Desea enviar estas ordenes al integrado?"
				description={`Este tardara un tiempo.`}
				closeModal={()=>{setShowSyncConfirm(false);}}
				accept={syncPendingOrders}
			/>:null}

		</div>
	);
}

export default IntegrateSystem;


const PendingOrders = ({pendingOrders}) => {
	console.log(pendingOrders)
	const [showModal,setShowModal] = useState(false);
	
	return (
		<div className="section pendingOrders">
			<Header title="Transacciones pendientes"/>
			

			<div className="pendingOrders-wrapper">
				{pendingOrders?.map(o => <div className="order-preview">
					<p className="orderId">
						{o.orderId}
					</p>
					<p className="date">{formatDate(o.createdAt,"dateOnly")}</p>

				</div>)}
			</div>

			<div className="actions">
				<Button action={()=>{setShowModal(true)}} variant="tertiary">Ver todas</Button>
			</div>

			{showModal?<Modal className="allPendingOrders" closeModal={()=>{setShowModal(false)}} showCloseIcon>
				<div className="content">
					<Header title="Ordenes pendientes"/>
					<Table 
						columns={[
							"User Id",
							"Order Id",
							"Creada en"
					]}>
						{pendingOrders?.map(o => <TableItem key={o.createdAt}>
							<td>{o.userId}</td>
							<td>{o.orderId}</td>
							<td>{formatDate(o.createdAt)}</td>
						</TableItem>)}
					</Table> 
				</div>
			</Modal>:null}

		</div>
	)
}