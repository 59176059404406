import React, {useCallback, useState} from 'react';
/**
 * 
 * @returns Object with url search params
 */
 export function useQuery(){
	return new URLSearchParams(window.location.search);
}

/**
 * Snippet copied from https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
 * @param {*} callback function that will be called upon the end of the timeout
 * @param {*} delay Time in milliseconds to set the timeout to
 */
 export function useTimeout(callback, delay) {
	

	const timeoutRef = React.useRef(null);
	const savedCallback = React.useRef(callback);

	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	React.useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === 'number') {
			timeoutRef.current = window.setTimeout(tick, delay);
			return () => window.clearTimeout(timeoutRef.current);
		}
	}, [delay]);

	return timeoutRef;
};

export function useMultistepForm(steps){

	const [currentStepIndex,setCurrentStepIndex] = React.useState(0);

	const nextStep = () => {
		setCurrentStepIndex(i => {
			if(i < steps.length - 1){
				return currentStepIndex + 1
			}else{
				return i
			}
		});
	}
	
	const prevStep = () => {
		setCurrentStepIndex(i => {
			if(i > 0){
				return currentStepIndex - 1
			}else{
				return i
			}
		});
	}


	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		isFirstStep: currentStepIndex === 0,
		isLastStep: currentStepIndex === steps.length - 1,
		nextStep,
		prevStep
	}





}

/**
 * Hook obtained from https://codesandbox.io/p/sandbox/react-tree-visualizer-hs3l2?file=%2Fsrc%2Fhelpers.js%3A13%2C1
 * @param {*} defaultTranslate 
 * @returns 
 */
export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
	const [translate, setTranslate] = useState(defaultTranslate);
	const containerRef = useCallback((containerElem) => {
		if (containerElem !== null) {
		const { width, height } = containerElem.getBoundingClientRect();
		setTranslate({ x: width / 2, y: height / 5 });
		}
	}, []);
	return [translate, containerRef];
	};