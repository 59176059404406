import React, {useEffect, useState} from "react";
import Header from "components/Header";
import Button from "components/Button";
import {Table, TableItem} from "components/Table";
import Dropdown from "components/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {getAllRoles} from "Actions/RolesActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./Permissions.scss";
import Checkbox from "components/Checkbox";
import {addAlert} from "Actions/SystemActions";

function Permissions() {


	const [selectedRole,setSelectedRole] = useState(null);
	const [permissions,setPermissions] = useState([]);
	// const [permissionsPerRole,setPermissionsPerRole] = useState([]);
	const roles = useSelector(store=>store.RolesReducer).roles;

	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(getAllRoles());
	},[])

	useEffect(()=>{
		if(roles.length>0){
			setSelectedRole(roles[0]);
			
		}
	},[roles]);

	useEffect(()=>{
		// TODO - If its not the same role clean the list of users
		if(selectedRole?.id){
			fetchPermissions();
		}
	},[selectedRole]);

	const fetchPermissions = () => {
		if(selectedRole?.id){
			axiosWithAuth().get("/permissions/"+selectedRole.id).then(res=>{
				setPermissions(res.data);
			})
		}
	}

	const togglePermission = (permission) => {

		if(selectedRole?.id === 1){
			dispatch(addAlert({text:"No se pueden modificar los permisos del " + selectedRole?.name, type:"error"}))
		}else if(permission.assigned){
			axiosWithAuth().post("/permissions/unassign",{
				permissionId:permission.id,
				roleId:selectedRole.id
			}).then(res=>{
				fetchPermissions();
			})
		}else{
			axiosWithAuth().post("/permissions/assign",{
				permissionId:permission.id,
				roleId:selectedRole.id
			}).then(res=>{
				fetchPermissions();
			})
		}
	}

	return (
		<div className="Permissions">
			<Header title={"Permisos"} subtitle={selectedRole?.name}/>
			<div className="wrapper">
				<div className="controls">
					<Dropdown title={"Roles"}>
						{roles.map(role=>{
							return <Dropdown.Item key={role.id} action={()=>{setSelectedRole(role)}}>
								{role.name}
							</Dropdown.Item>
						})}
					</Dropdown>
				</div>


				<Table columns={{
					"Nombre":"name",
					"categoria":"category",
					"":""
				}}>
					{permissions.map(row=>{
						return <TableItem>
							<td>{row.name}</td>
							<td>{row.category}</td>
							<td className="checkbox-column">
								<Checkbox
									name="permission"
									value={row.assigned}
									toggleValue={()=>{togglePermission(row)}}
								/>

							</td>
						</TableItem>
					})}
				</Table>
			</div>
		</div>
	);
}

export default Permissions;