import React from "react";
import { ReactComponent as DesktopIcon } from "../../assets/icons/desktop.svg";
import { ReactComponent as AndroidIcon } from "../../assets/icons/android.svg";
import { ReactComponent as CheckRoundedIcon } from "../../assets/icons/check-rounded.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/crossIcon.svg";
import Header from "components/Header";
import "./Sessions.scss";
import {useState} from "react";
import {useEffect} from "react";
import {axiosWithAuth} from "Utils/axiosWithAuth";

// const sessions = [
// 	{id:0,ip:"107.135.128.118",agent:"Windows PC"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// 	{id:0,ip:"24.115.48.10",agent:"Android"},
// ]

function Sessions() {

	const [sessions,setSessions] = useState([]);

	useEffect(()=>{
		fetchSessions();
	},[])	

	const fetchSessions = () => {
		axiosWithAuth().get("/auth/sessions").then(res=>{
			setSessions(res.data);
			console.log(res.data);
		}).catch(err=>{
			console.log(err);
		})
	}

	const closeSession = (sid) => {
		axiosWithAuth().delete("/auth/sessions",{
			data:{sid}
		}).then(res=>{
			if(res.status===201){
				fetchSessions();
			}
		}).catch(err=>{
			console.log(err);
		})
	}


	return (	
		<div className="Sessions"> 
			<Header title={"Sessiones activas"}/>
			<div className="sessions-wrapper">
				{sessions.map(({id,sid,ipv4,browser,expire})=><Session key={id} sid={sid} closeSession={closeSession} ip={ipv4} agent={browser} expires={expire}/>)}
			</div>
		</div>
	);
}

export default Sessions;	

function Session({sid,agent,ip,expires,closeSession}){

	const [sanitAgent,setSanitAgent] = useState("");
	const [expiresIn,setExpiresIn] = useState("");

	useEffect(()=>{
		if(agent.split("#")[2].includes("Android")){
			setSanitAgent("Android")
		}else if(agent.split("#")[1].includes("Windows")){
			setSanitAgent("Windows")
		}else{
			setSanitAgent(`${agent.split("#")[2]} - ${agent.split("#")[0]}`)
		}

		let today = new Date().toISOString().slice(0, 10)
		const endDate    = new Date(expires)
		const diffInMs   = new Date(endDate) - new Date(today)
		const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

		setExpiresIn(parseInt(diffInDays))

	},[])

	const renderDeviceIcon = () => {
		if(sanitAgent==="Windows"){
			return <DesktopIcon/>
		}else if(sanitAgent === "Android"){
			return <AndroidIcon/>
		}
	}
	
	return (<div className="session">
		<div className="controls">
			<CrossIcon onClick={()=>{closeSession(sid)}}/>
		</div>
		<div className="media">
			{renderDeviceIcon()}
		</div>
		<div className="info">
			<p className="agent">{sanitAgent}</p>
			<p className="location">{ip}</p>
			
			<div className="info-bottom">
				{ip==="::1"?
					<p className="thisdevice">
						<span className="iconLeft">
							<CheckRoundedIcon/>
						</span>
						Este dispositivo
					</p>	
				:null}
				<div className="date">
					<p>Expires in: {expiresIn} days</p>
				</div>
			</div>
		</div>
	</div>)
}

