import { axiosWithAuth } from "Utils/axiosWithAuth";

export const FETCH_ROLES_START =  "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCESS =  "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE =  "FETCH_ROLES_FAILURE";

export const getAllRoles = (data) => {
	return (dispatch) => {
		dispatch({ type:FETCH_ROLES_START });
		axiosWithAuth().get("roles").then(res=>{
			dispatch({ type:FETCH_ROLES_SUCCESS, payload:{data:res.data, response:res}});
		}).catch(err => {
			if(err.message){
				dispatch({ type:FETCH_ROLES_FAILURE, payload: {error:err.message}});
			}
			if(err.response){
				dispatch({ type: FETCH_ROLES_FAILURE, payload:{error:err.response.data, response:err.response} });
			}
		})
	}
}