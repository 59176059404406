import React from "react";
import Header from "components/Header";
import { ReactComponent as ComingSoonIllustration } from "../../assets/illustrations/cominSoon.svg";
import "./ComingSoon.scss"

function ComingSoon() {
	return (
		<div className="ComingSoon">
			<div className="ilustration-wrapper">
				<ComingSoonIllustration/>
			</div>
			<div className="text-wrapper">
				<Header title="Proximamente disponible!" subtitle="Estamos trabajando para crear una mejor experiencia!"/>
			</div>
		</div>
	);
}

export default ComingSoon;