import {UserInfoContext} from "Context/AdminDashboardContext";
import Button from "components/Button";
import Card from "components/Card";
import React, {useContext, useState} from "react";
import InputField from "components/InputField";
import * as Edit from "./EditModal"
import {useDispatch} from "react-redux";
import usePermissions from "Utils/hooks/usePermissions";
import {Illustrations} from "assets";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";
import {useParams} from "react-router";

function UserInfoPanel() {

	const { userInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext


	const [editEmail,setEditEmail] = useState(false);
	const [editPhone,setEditPhone] = useState(false);
	const [editAbbasoonCode,setEditAbbasoonCode] = useState(false);
	const [editReferrerId,setEditReferrerId] = useState(false);
	const [editFields,setEditFields] = useState(false);
	const [editPassword,setEditPassword] = useState(false);
	const {hasPermission} = usePermissions();
	const dispatch = useDispatch();
	const { userId } = useParams();

	const resendEmail = () => {
		axiosWithAuth().get("users/admin/resend/verification",{
			params:{userId:userId}
		}).then(res=>{
			if(res.status===200){
				dispatch(addAlert({text:`Correo de verificacion enviado correctamente`, type:"success"}))
			}
		}).catch(err=>{
			dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}));
		})
	}
	return (
		<Card className="Panel Panel-UserInfo">
			<Card.Content>
				<div className="media">
					<div className="img">
						<Illustrations.Avatar/>
					</div>
				</div>

				<div className="info">
					<InputField
						name="fullName"
						label="Nombre"
						value={userInfo?.fullName || ""}
						readOnly
					/>
					<InputField
						name="email"
						label="Correo electronico"
						value={userInfo?.email || ""}
						slot={
							hasPermission("canEditUserDangerousFields") && 
							<Button
								action={()=>{setEditEmail(true)}}
							>
								Editar
							</Button>
						}
						readOnly
					/>
					<InputField
						name="phone"
						label="Telefono"
						value={userInfo?.phone || ""}
						slot={
							hasPermission("canEditUserDangerousFields") &&
							<Button
								action={()=>{setEditPhone(true)}}
							>
								Editar
							</Button>
						}
						readOnly
					/>
					<InputField
						name="abbasoonCode"
						label="Codigo de afiliado"
						value={userInfo?.abbasoonCode || ""}
						slot={
							hasPermission("canEditUserDangerousFields") &&
							<Button
								action={()=>{setEditAbbasoonCode(true)}}
							>
								Editar
							</Button>
						}
						readOnly
					/>
					<InputField
						name="referrerId"
						label="Codigo de referido"
						value={userInfo?.referrerId || ""}
						slot={
							hasPermission("canEditReferrerId") &&
							<Button
								action={()=>{setEditReferrerId(true)}}
							>
								Editar
							</Button>
						}
						readOnly
					/>
					<InputField
						name="nit"
						label="Numero de Identificacion"
						value={userInfo?.nit}
						readOnly
					/>
					<div className="row -wrap-phone extra gp-1">
						<Button
							action={()=>{
								setEditFields(true);
							}}
						>
							Editar resto de campos
						</Button>


						<Button action={()=>{
								setEditPassword(true);
							}}>
							Editar Contraseña
						</Button>

					</div>
				</div>
			</Card.Content>

			<Card.Controls>
				{userInfo?.status === "pending" &&
					<Button action={resendEmail}>
						Reenviar codigos de verificaion
					</Button>		
				}
			</Card.Controls>

			{editFields?
				<Edit.UserFieldsModal
					closeModal={() => {
						setEditFields(false);
					}}
				/>
			:null}

			{editPhone?
				<Edit.UserPhoneModal
					phone={userInfo.phone}
					closeModal={()=>{
						setEditPhone(false)
					}}
				/>
			:null}

			 {editEmail?
				<Edit.UserEmailModal
					email={userInfo.email}
					closeModal={()=>{
						setEditEmail(false)
					}}
				/>
			:null}

			{editAbbasoonCode?
				<Edit.UserAbbasoonCodeModal
					abbasoonCode={userInfo.abbasoonCode}
					closeModal={()=>{
						setEditAbbasoonCode(false)
					}}
				/>
			:null}

			{editReferrerId?
				<Edit.UserReferrerIdModal
					referrerId={userInfo.referrerId}
					closeModal={()=>{
						setEditReferrerId(false)
					}}
				/>
			:null}

			{editPassword?
				<Edit.UserPasswordModal
					closeModal={()=>{
						setEditPassword(false)
					}}
				/>
			:null}

		</Card>
	);
}

export default UserInfoPanel;


