import React from "react";
import "./AboutPage.scss";
import {LoginIllustration} from "assets";
import Header from "components/Header/Header";
import {useNavigate} from "react-router-dom";
import Button from "components/Button/Button";

function AboutPage() {

	const navigate = useNavigate();

	return (
		<div className="AboutPage">
			<div className="hero section">
				<Header title="Abbareal"/>
				<LoginIllustration/>
			</div>

			<div className="section description">
				<Header title="Que es Abbareal"/>
				<p>
					Programa Corporativo en Alianza con la Empresa Privada, Publica y Master
					Card. Busca a Empleados, Pequeños Emprendedores, Micro, Pequeña y
					Mediana Empresa que requieran de Apoyo Eduativo y Financiero para ingresar
					a La Estrategia de Negocios vinculada entre mas de 12 países de América
					Latina y el Caribe.
				</p>
			</div>
			<div className="spacer"></div>
			<div className="section objective">
				<Header title="Objetivo"/>
				<p>
					Apoyar para que las ideas de negocio se puedan materializar. Fomentando con
					nuestra Educacion y Estrategia Financiera una estabilidad economica. Para
					obtener el tiempo necesario de la etapa de maduracion de su
					emprendimiento.
				</p>
			</div>
			<div className="section target">
				<Header title="A quien va dirigido?"/>
				<p>
					Dirigido a personas individuales, pequeños emprendedores, micro, pequeña y
					mediana empresa. Que deseen asegurar la canasta basica de su familia, pago
					de servicios, casa, educacion para tus hijos, crecimiento de su negocio y lo mas
					importante un retiro acceptable en un futuro cercano.
				</p>
			</div>

			<div className="section row">
				<div className="item">
					<Header title="Caracteristicas"/>
					<li>El financiamiento es exclusivo para la Estrategia de Negocios Ofertada y algunos servicios profesionales requeridos para el proceso</li>
					<li>El rango de montos a financiar depende de la necesidad y el tamano de negocio de cada uno de los participantes. Iniciando desde $1,080.00 dólares</li>
					<li>Comision por ingreso: $40.00 (Unico Pago)</li>
					<li>No existe ningun tipo de interes por dicho financiamiento</li>
					<li>El monto a financiar dependera de un analisis tecnico y financiero, tomando en cuenta laproyeccion de cada persona, familia o empresa. En algunos casos aplicara un pago entre un 5% a 10% sobre el monto total</li>
				</div>
				<div className="item">
					<Header title="Requisitos"/>
					<li>No existe un maximo de edad</li>
					<li>Copia de Identificacion Personal</li>
					<li>Informacion del Tipo de Financiamiento Solicitado</li>
					<li>Comprobante de Direccion Actual</li>
					<li>El monto a financiar dependera de un analisis tecnico y financiero, tomando en cuenta la proyeccion de cada persona, familia o empresa</li>
				</div>
				<div className="item">
					<Header title="Beneficio Importante"/>
					<li>Retiro de Efectivo en cualquier cajero MasterCard</li>
					<li>Pagos en cualquier Tienda que reciba Master Card</li>
					<li>Pagos Electronicos</li>
					<li>Puede recibir su salario semanal, quincenal o mensual en est</li>
					<li>tarjeta</li>
					<li>Puede recargar su tarjeta en miles de establecimiento</li>
					<li>cercanos a donde usted se encuentre</li>
					<li>Remesas Familiares de Estados Unidos a Mexico</li>
					<li>En esta tarjeta recibira las ganancias obtenidas en esta Estrategia de Negocio</li>
				</div>

			</div>

			<div className="bottom-wrapper">
				<Button action={()=>{
					navigate("/contact")
				}}>
					Dejanos tu Informacion y ponte en contacto!
				</Button>
			</div>

		</div>
	);
}

export default AboutPage;