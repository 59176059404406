import React,{useState,useEffect} from "react";
import Button from "components/Button";
import Header from "components/Header";
import "./AffiliateUser.scss";
import Dropdown from "components/Dropdown";
import InputField from "components/InputField";
import Checkbox from "components/Checkbox";
import {useDispatch} from "react-redux";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";
import Confirmation from "components/Confirmation";

const USER_FIELDS = {				
	fullName:"",
	username:"",
	email:"",
	password:"",
	phone:"",
	dob: "",
	country:"",
	state:"",
	city:"",
	referrerCode:"",
	gender:""
}

function AffiliateUser() {

	const [nUserData,setnUserData] = useState(USER_FIELDS);
	const [error,setError] = useState(null);
	const [validated,setValidated] = useState(false);
	const [showPassword,setShowPassword] = useState(false);
	const [single,setSingle] = useState("");
	const [cancelConfirm,setCancelConfirm] = useState(false);


	const dispatch = useDispatch();

	useEffect(()=>{
		if(nUserData.referrerCode.length>0 && error){
			setError(null);
		}
	},[nUserData.referrerCode])

	const formatDate = (date) => {
		if(date?.includes("/")){
			var parts = date.split("/");
			if(parts[0].length === 1) parts[0] = '0' + parts[0]
			if(parts[1].length === 1) parts[1] = '0' + parts[1]
			
			return `${parts[2]}-${parts[0]}-${parts[1]}`
		}

		return null
	}

	useEffect(()=>{
		if(single){
			const fieldsArr = single.split(",");
			const date = formatDate(fieldsArr[7]);

			const fields = {
				fullName:fieldsArr[2] || "",
				username:fieldsArr[3] || "",
				email:fieldsArr[4] || "",
				password:"123321",
				phone:fieldsArr[5] || "",
				dob: date || "",
				country:fieldsArr[8] || "",
				state:fieldsArr[9] || "",
				city:fieldsArr[10] || "",
				referrerCode:fieldsArr[1] || "",
				gender:fieldsArr[6]
			};
			setnUserData(fields);
			setShowPassword(true)
		}
	},[single])

	const checkDisabled = () => {

		if(!nUserData?.dob
			|| !nUserData?.gender
			|| !nUserData?.email
			|| !nUserData?.phone
			|| !nUserData?.fullName
		){
			return true;
		}

		if(nUserData.referrerCode?.length>0 && !validated){
			return true;
		}

		return false
	}

	const validateCode = () => {
		axiosWithAuth().post("/auth/validateRefcode",{code:nUserData.referrerCode}).then(res=>{
			if(res.status === 200){
				setValidated(true);
			}
		}).catch(err=>{
			if(err?.response?.status === 404){
				setError("No existe ningun usuario con ese codigo");
				setValidated(false);
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		})
	}

	const affiliateUser = (e) => {


		e.preventDefault();
		axiosWithAuth().post("/auth/admin/affiliate",{
			...nUserData,
			phone: `+${nUserData.phone}`
		}).then(res=>{
			if(res.status === 201){
				dispatch(addAlert({text:"Usuario afiliado de manera correcta", type:"success"}));
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				const conflictHash={
					"phone":"numero de telefono",
					"email":"correo electronico",
					"username":"nombre de usuario (username)",
					"fullName":"nombre completo",
				}

				if(err.response.data.conflict){
					dispatch(addAlert({text:`Ha ocurrido un error. Ese ${conflictHash[err.response.data.conflict]} ya esta en uso`, type:"error"}))
				}else{
					dispatch(addAlert({text:`Ha ocurrido un error. Alguno de tus campos ya esta en uso`, type:"error"}))
				}
			}else{
				console.log(err)
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
			}
		})
	}

	return (
		<div className="AffiliateUser">
			<Header title="Afiliar usuario"/>

			<div className="wrapper">
				<form action="affiliateUser" onSubmit={affiliateUser}>
					<InputField
						label="Comma separated string"
						value={single}
						setValue={setSingle}

					/>
					{/* User identity */}
					<InputField
						name="fullname"
						value={nUserData.fullName}
						setValue={(nVal) => setnUserData({...nUserData, fullName: nVal})}
						label="Nombre completo*"
						required
					/>

					<div className="cols-r2">
						<InputField
							name="city"
							value={nUserData.city}
							setValue={(nVal) => setnUserData({...nUserData, city: nVal})}
							label="Ciudad"
						/>

						<InputField
							name="state"
							value={nUserData.state}
							setValue={(nVal) => setnUserData({...nUserData, state: nVal})}
							label="Estado"
						/>

						<InputField
							name="country"
							value={nUserData.country}
							setValue={(nVal) => setnUserData({...nUserData, country: nVal})}
							label="Pais"
						/>
					</div>

					<div className="cols-r2">
						<InputField
							name="dateOfBirth"
							value={nUserData.dob}
							setValue={(nVal) => setnUserData({...nUserData, dob: nVal})}
							label="Fecha de nacimiento*"
							type="date"
						/>

						<InputField 
							name="referrerCode"
							value={nUserData.referrerCode}
							setValue={(nVal) => setnUserData({...nUserData, referrerCode: nVal})}
							label="Codigo de referido"
							slot={<Button disabled={nUserData.referrerCode?.length===0} action={()=>{validateCode()}}>Validar</Button>}
							error={error}
							className={validated?"RefCode valid":"RefCode"}
						/>
		
					</div>

					<div className="cols-r2">
						<InputField
							name="username"
							value={nUserData.username}
							setValue={(nVal) => setnUserData({...nUserData, username: nVal})}
							label="Nombre de usuario*"
							required
						/>

						<InputField
							name="email"
							value={nUserData.email}
							setValue={(nVal) => setnUserData({...nUserData, email: nVal})}
							label="Correo electronico*"
							required
						/>

					</div>

					<InputField.PhoneInput
						name="phone"
						label="Numero de telefono*"
						phone={nUserData.phone}
						setPhone={(nVal) => setnUserData({...nUserData, phone: nVal})}
					/>
					
					<InputField 
						name="password"
						value={nUserData.password}
						setValue={(nVal)=> setnUserData({...nUserData, password:nVal})}
						label="Contraseña*"
						required
						type={showPassword?"text":"password"}
					/>

					<div className="actions">
						<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
					</div>

					<div className="dpw-wrapper">
						<Dropdown title={
							nUserData.gender==="M"?"Masculino":
							nUserData.gender==="F"?"Femenino":
							nUserData.gender==="X"?"Otro"
							:"Genero*"
						} variant="secondary">
							<Dropdown.Item 
								action={()=>{setnUserData({...nUserData,gender:'M'})}}
								selected={nUserData.gender==='M'}
								>Masculino</Dropdown.Item>
							<Dropdown.Item 
								action={()=>{setnUserData({...nUserData,gender:'F'})}}
								selected={nUserData.gender==='F'}
								>Femenino</Dropdown.Item>
							<Dropdown.Item 
								action={()=>{setnUserData({...nUserData,gender:'X'})}}
								selected={nUserData.gender==='X'}
								>Otro</Dropdown.Item>
						</Dropdown>
					</div>

					<div className="controls">
						<Button variant="secondary"
							action={()=>{setCancelConfirm(true)}}
						>Cancelar</Button>
						<Button 
							type="submit"
							disabled={checkDisabled()}
						>Guardar</Button>
					</div>

				</form>
			</div>


			{cancelConfirm?<Confirmation
				title="Desea limpiar el formulario?"
				description={`Se eliminaran todos los datos guardados`}
				closeModal={()=>{
					setCancelConfirm(false);
				}}
				accept={()=>{
					setnUserData(USER_FIELDS)
					setSingle("")
				}}
			/>:null}

		</div>
	);
}

export default AffiliateUser;