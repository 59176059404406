import Card from "components/Card";
import React from "react";
import {useNavigate} from "react-router";

function WebhooksWidget() {

	const navigate = useNavigate();

	return (<>
	
		<Card className="Webhooks" 
			action={() => {navigate("/admin/system/webhook")}}
			centered
		>
			Webhooks
		</Card>

	</>);
}

export default WebhooksWidget;

