import React from "react";
import {formatDate} from "Utils/TextFormat";
import Checkbox from "components/Checkbox";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import Textarea from "components/Textarea";
import "./OrderModal.scss";
import {useNavigate} from "react-router";
import Button from "components/Button";

function OrderModal({order,isAdmin,setViewOrder}) {

	const navigate = useNavigate();

	return (
		<Modal showCloseIcon closeModal={()=>{setViewOrder(null)}} >
			<div className="modal-content primary order">
				<Header title={"Orden de compra"} subtitle={"order.orderId"}/>

				<div className="info">
					<div className="row gp-1">
						<InputField
							label="Id Orden"
							value={order.orderId}
							name="orderId"
							readOnly
						/>
						<InputField
							label="Fecha creacion"
							value={formatDate(order.createdAt)}
							name="createdAt"
							readOnly
						/>
					</div>

					<Textarea
						label="Descripcion"
						value={order.description}
						name="description"
						readOnly
					/>

					<Checkbox
						label="Sincronizado integrado (si aplica)"
						value={order.syncedIntegrate}
					/>
				</div>


				<div className="controls">
					<Button 
						variant="secondary"
						action={() => {setViewOrder(null)}}
					>
						Cerrar
					</Button>
					<Button
						action={()=>{
							navigate("/products/order/"+order.orderId,{
								state:{
									order
								}
							})
						}}
					>
						Ver mas detalles
					</Button>
				</div>
			

			</div>
		
				
		</Modal>
	);
}

export default OrderModal;