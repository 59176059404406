import React from "react";
import "./Checkbox.scss"

function Checkbox({label,name,className,value,toggleValue,action,variant="",readonly}) {
	const handleClick = (e) => {
		if(readonly) return;

		if(action){
			// Used for radio groups
			action();
		}else{
			// Used for toggle boxes
			toggleValue(!value);
		}
	}

	return (
		<div className={`checkbox ${className}`} onClick={handleClick}>
			
			<div className={`checkbox-wrapper ${variant}`}>
				<span className={`indicator ${value?"checked":""}`}></span>
			</div>


			{label?
				<label htmlFor={name}>{label}</label>
			:null}

			
		</div>
	);
}

export default Checkbox;