import React from "react";
import Header from "components/Header";
import Dropdown from "components/Dropdown";
import InputField from "components/InputField";
import {useDispatch, useSelector} from "react-redux";
import "./ProfileSettings.scss";
import Button from "components/Button";
import {useState} from "react";
import {useEffect} from "react";
import Confirmation from "components/Confirmation";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";
import Modal from "components/Modal";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import Checkbox from "components/Checkbox";
import EditPhoneModal from "Modals/EditPhoneModal";
import {VerifyPhoneModal} from "../../Modals/EditPhoneModal";
import {fetchLoggedUser} from "Actions/UserActions";

function ProfileSettings() {

	const [newData,setNewData] = useState({});
	const [showConfirm,setShowConfirm] = useState(false);
	const {fullName,username,email,gender,city,country,state,dob,abbasoonCode,nit,referrerId,phone,validatedPhone} = useSelector(store=>store.UserReducer.data);
	const [showPhoneModal,setShowPhoneModal] = useState(false);
	const [showVerifyPhoneModal,setShowVerifyPhoneModal] = useState(false);
	const [newEmail,setNewEmail] = useState('');
	const [showConfirmEmail,setShowConfirmEmail] = useState(false);
	const dispatch = useDispatch();

	useEffect(()=>{
		setNewData({fullName,username,gender,city,dob,abbasoonCode,country,state,referrerId})
	},[fullName,username,gender,city,dob,abbasoonCode,referrerId]);

	const updateProfile = () => {
		axiosWithAuth().patch("users",newData).then(res=>{
			if(res.status === 200){
				dispatch(addAlert({text:"Usuario editado correctamente", type:"success"}));
			}
		}).catch(err=>{
			if(err?.response?.status === 404){
				dispatch(addAlert({text:"Usuario no encontrado", type:"error"}));
			}
		})
	}

	const changeEmail = () => {
		axiosWithAuth().post("users/email",{latestEmail:email,newEmail}).then(res=>{
			if(res.status === 200){
				setNewEmail("");
				setShowConfirmEmail(true);
				
			}
		}).catch(err=>{
			if(err?.response?.status === 409){
				dispatch(addAlert({text:"El correo electrónico ya está registrado en el sistema.", type:"error"}));
			}
			else if(err?.response?.status === 400){
				dispatch(addAlert({text:"El correo electrónico es diferente al actual.", type:"error"}));
			}
		})
	}

	return (
		<div className="ProfileSettings">
			<Header title={"Detalles personales"}/>


			<div className="card p-1 userDetails">
				<div className="row -wrap gp-1 inputs">
					<InputField
						name="fullName"
						label="Nombre completo"
						value={newData.fullName}
						setValue={(nVal) => {setNewData({...newData,fullName:nVal})}}
					/>
					<InputField
						name="username"
						label="Usuario"
						value={newData.username}
						setValue={(nVal) => {setNewData({...newData,username:nVal})}}
					/>
					<InputField
						name="email"
						label="Correo electronico"
						value={email}
					/>
				</div>

				<div className="row -wrap gp-1 inputs">
					<InputField
						name="city"
						label="Ciudad"
						value={newData.city}
						setValue={(nVal) => {setNewData({...newData, city:nVal})}}
					/>
					<InputField
						name="state"
						label="Estado"
						value={newData.state}
						setValue={(nVal) => {setNewData({...newData, state:nVal})}}
					/>
					<InputField
						name="country"
						label="Pais"
						value={newData.country}
						setValue={(nVal) => {setNewData({...newData, country:nVal})}}
					/>
					<InputField
						name="dateOfBirth"
						value={String(newData.dob).split("T")[0] || null}
						setValue={(nVal) => {setNewData({...newData, dob:nVal})}}
						label="Fecha de nacimiento"
						type="date"
					/>
				</div>

				<div className="row -wrap gp-1 inputs">
					<InputField
						name="abbasoonCode"
						label="Codigo de abbasoon"
						value={abbasoonCode}
						readonly
					/>
					<InputField
						name="referrrerId"
						value={referrerId}
						label="Codigo de referido"
						readonly
					/>
					<InputField
						name="nit"
						value={nit}
						label="NIT"
						readonly
					/>
				</div>

				<Dropdown title={
						newData.gender==="M"?"Masculino":
						newData.gender==="F"?"Femenino":
						newData.gender==="X"?"Otro"
						:"Sexo"
				} variant="secondary">
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'M'})}}
							selected={newData.gender==='M'}
							>Masculino</Dropdown.Item>
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'F'})}}
							selected={newData.gender==='F'}
							>Femenino</Dropdown.Item>
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'X'})}}
							selected={newData.gender==='X'}
							>Otro</Dropdown.Item>
				</Dropdown>

				<div className="actions">
					<Button action={() =>{setShowConfirm(true)}}>Guardar</Button>
				</div>
			</div>

			<div className="card p-1 phoneCard">
				<Header subtitle="Cambiar telefono"/>

		
				<InputField
					label="Numero de telefono"
					value={phone}
					readonly
				/>
				<Checkbox
					label="Verificado"
					value={validatedPhone}
					readonly
				/>

				<div className="row -wrap gp-1 actions">
					{!validatedPhone && <Button
						variant="secondary"
						action={()=>{setShowVerifyPhoneModal(true)}}
					>
						Verificar numero
					</Button>
					}
					<Button
						action={()=>{setShowPhoneModal(true)}}
					>
						Cambiar numero
					</Button>
				</div>

			</div>
			{/* TODO PENDING VALIDATION */}
			{/* <div className="card p-1 phoneCard">
				<Header subtitle="Cambiar email"/>		
				<InputField
					label="Email"
					value={newEmail}	
					setValue={setNewEmail}				
				/>				
				<div className="row -wrap gp-1 actions">				
					<Button
					disabled={newEmail.length < 1}						
						action={()=>{changeEmail()}}
					>
						Cambiar email
					</Button>
				</div>
			</div> */}
			{showConfirmEmail?<Confirmation
				title="Se ha enviado un correo con un código de verificación para validar el nuevo correo electrónico."				
				closeModal={()=>{
					setShowConfirmEmail(false);
				}}
				accept={()=>{setShowConfirmEmail(false)}}
			/>:null}
			{showConfirm?<Confirmation
				title="Esta seguro que desea editar su informacion personal?"				
				closeModal={()=>{
					setShowConfirm(false);
				}}
				accept={()=>{updateProfile()}}
			/>:null}

			{showPhoneModal && <EditPhoneModal 
				existingPhone={phone} 
				closeModal={()=>{
					setShowPhoneModal(false);	
				}}
				onSuccess = {() => {
					// Refetch logged user to refresh data
					dispatch(fetchLoggedUser())
				}}
			
			/>}

			{showVerifyPhoneModal && <VerifyPhoneModal 
				onSuccess = {() => {
					dispatch(fetchLoggedUser())
				}}
				closeModal={()=>{
					setShowVerifyPhoneModal(false)
				}}
			/>}
		</div>
	);
}

export default ProfileSettings;

