import React from "react";
import {Route, Routes} from "react-router";
import PrivateRoute from "./PrivateRoute";
import {
	Dashboard,
	Homepage,
	Affiliates,
	ChangePassword,
	ChangeAvatar,
	Products,
	Sessions,
	Transactions,
	Transaction,
	Login,
	Register,
	ForgotPassword,
	ResetPassword,
	Permissions,
	ProductsAdmin,
	Roles,
	User,
	Wallet,
	Users,
	ConfirmTransaction,
	MkRechargeHistory,
	MkRemoveHistory,
	FamilyCard,
	VerifyAccount,
	ProfileSettings,
	ComingSoon,
	NotFound,
	SharesHistory,
	Services,
	AffiliateUser,
	AffiliationCodes,
	ContactPage,
	ContactPageAdmin,
	AboutPage,
	Withdrawals,
	WithdrawalsAdmin,
	IntegrateSystemAdmin,
	IntegrateSystem,
	Order,
	Payments,
	PaymentPage,
	ChangeEmail,
	Onboarding,
	System,
	Access
} from ".";

function Router() {
	return (
		<Routes>
			<Route exact path="/login" element={<Login/>}></Route>
			<Route exact path="/register" element={<Register/>}></Route>
			<Route exact path="/forgot_password" element={<ForgotPassword/>}></Route>
			<Route exact path="/forgot_password/code" element={<ResetPassword/>}></Route>
			<Route exact path="/" element={<Homepage/>}></Route>	
			<Route exact path="/access" element={<PrivateRoute>
				<Access/>
			</PrivateRoute>}></Route>	

		


			<Route exact path="/transactions" element={
				<PrivateRoute>
					<Transactions/>
				</PrivateRoute>
			}></Route>
			<Route exact path="/sharehistory" element={
				<PrivateRoute>
					<SharesHistory/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/profile" element={
				<PrivateRoute>
					<ProfileSettings/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/verify_account" element={
				<VerifyAccount/>
			}></Route>						
			<Route exact path="/change_email" element={
				<ChangeEmail/>
			}></Route>

			<Route exact path="/payment/:paymentId" element={
				<PrivateRoute>
					<PaymentPage/>
				</PrivateRoute>
			}/>

			<Route exact path="/payments/*" element={
				<PrivateRoute>
					<Payments/>
				</PrivateRoute>
			}/>

			<Route exact path="/transactions/confirm" element={
				<PrivateRoute>
					<ConfirmTransaction/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/send" element={
				<PrivateRoute>
					<Transaction/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/integratesystem/*" element={
				<PrivateRoute>
					<IntegrateSystem/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/integratesystem" element={
				<PrivateRoute>
					<IntegrateSystemAdmin/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/wallet/types" element={
				<PrivateRoute>
					<Wallet/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/password/change" element={
				<PrivateRoute>
					<ChangePassword/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/sessions" element={
				<PrivateRoute>
					<Sessions/>
				</PrivateRoute>
			}></Route>	

			<Route exact path="/familycard" element={
				<PrivateRoute>
					<FamilyCard/>
				</PrivateRoute>
			}></Route>	

			<Route exact path="/dashboard" element={
				<PrivateRoute>
					<Dashboard/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/roles" element={
				<PrivateRoute>
					<Roles/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/users" element={
				<PrivateRoute>
					<Users/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/user/new" element={
				<PrivateRoute>
					<AffiliateUser/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/permissions" element={
				<PrivateRoute>
					<Permissions/>
				</PrivateRoute>
			}></Route>
			
			<Route exact path="/products" element={
				<PrivateRoute>
					<Products/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/products/order/:id" element={
				<PrivateRoute>
					<Order/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/system/*" element={
				<PrivateRoute>
					<System/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/user/:userId" element={
				<PrivateRoute>
					<User/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/products" element={
				<PrivateRoute>
					<ProductsAdmin/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/affiliationcodes" element={
				<PrivateRoute>
					<AffiliationCodes/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/affiliationcodes" element={
				<PrivateRoute>
					<AffiliationCodes/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/old/mk_recharge_history" element={
				<PrivateRoute>
					<MkRechargeHistory/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/old/mk_remove_history" element={
				<PrivateRoute>
					<MkRemoveHistory/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/comingsoon" element={
				<PrivateRoute>
					<ComingSoon/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/withdrawals" element={
				<PrivateRoute>
					<Withdrawals/>
				</PrivateRoute>
			}></Route>

			<Route exact path="/admin/withdrawals" element={
				<PrivateRoute>
					<WithdrawalsAdmin/>
				</PrivateRoute>
			}></Route>

			{/* <Route exact path="/services" element={
				<PrivateRoute>
					<Services/>
				</PrivateRoute>
			}></Route> */}

			<Route exact path="/affiliates" element={
				<PrivateRoute>
					<Affiliates/>
				</PrivateRoute>
			}></Route>

			{/* <Route exact path="/change_avatar" element={
				<PrivateRoute>
					<ChangeAvatar/>
				</PrivateRoute>
			}></Route> */}

			<Route exact path="/about" element={
				<AboutPage/>
			}></Route>	

			<Route exact path="/contact" element={
				<ContactPage/>
			}></Route>	

			<Route exact path="/admin/contact" element={
				<PrivateRoute>
					<ContactPageAdmin/>
				</PrivateRoute>
			}></Route>	

			<Route exact path="/onboarding" element={
				<PrivateRoute>
					<Onboarding/>
				</PrivateRoute>
			}>

			</Route>

			{/* <Route exact path="/404" element={
				<NotFound/>
			}></Route>

			<Route exact path="/*" element={
				<Navigate to="/404"/>
			}></Route> */}
		</Routes>
	);
}

export default Router;