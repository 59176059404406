import React from "react";
import {Table,TableItem} from "../Table";
import Pagination from "components/Pagination/Pagination";
import "./Transactions.scss";
import { ReactComponent as EyeIcon } from "../../../assets/icons/visibilityIcon.svg";
import {formatCurrency, formatDate} from "Utils/TextFormat";

const columns = {
	"":"",
	type:"Tipo",
	amount:"Cantidad",
	userId:"User ID",
	email:"Correo electonico",
	createdAt:"Fecha de creacion",
}

export function WithdrawalsTable({
	withdrawals,
	pagination,
	setPage,
	setWithdrawalModal
}){

	return (<>
		
		<Table
			columns={columns}
		>

			{withdrawals.map(w => {
				return <TableItem key={w.id}>
					<td className="link" onClick={()=>{
						setWithdrawalModal(w)
					}}>
						<EyeIcon/>
					</td>
					<td>{w.type}</td>
					<td>{formatCurrency(w.amount)}</td>
					<td>{w.userId}</td>
					<td>{w.email}</td>
					<td>{formatDate(w.createdAt)}</td>
				</TableItem>
			})}

		</Table>

		{pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null}
		

	</>)


	
				
}