import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import combinedReducers from "./Reducers";

const root = ReactDOM.createRoot(document.getElementById('root'));
export const store = createStore(combinedReducers, applyMiddleware(thunk));

root.render(
//   <React.StrictMode>
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
