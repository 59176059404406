import React, {useState,useEffect} from "react";
import Header from "components/Header";
import Button from "components/Button";
import {TransactionsTable} from "components/Table";
import Dropdown from "components/Dropdown";
import InputField from "components/InputField";
import {useDispatch, useSelector} from "react-redux";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Checkbox from "components/Checkbox";
import Confirmation from "components/Confirmation";
import {useNavigate} from "react-router";
import TransactionModal from "Modals/TransactionModal";
import Pagination from "components/Pagination";
import {selectWallet} from "Actions/UserActions";
import "./Transactions.scss";
import {formatCurrency} from "Utils/TextFormat";
import DateRange from "components/DateRange/DateRange";
import {TransactionsList} from "components/Table/Common/Transactions";
import {ListIcon, TableIcon} from "assets";
import useFetchTransactions from "Utils/hooks/useFetchTransactions";



function Transactions() {

	const {
		handleSearch,
		transactions,
		dateRange,
		setDateRange,
		status,
		setStatus,
		query,
		setQuery,
		setPage,
		pagination,
		fetchTransactions
	} = useFetchTransactions()

	const [cancelItem,setCancelItem] = useState(null);
	const [verifyItem,setVerifyItem] = useState(null);
	const [viewTransaction,setViewTransaction] = useState(null);
	const [viewType,setViewType] = useState("list");
	
	const dispatch = useDispatch();
	const selectedWallet = useSelector(store=>store.UserReducer).selectedWallet;
	const userWallets = useSelector(store=>store.UserReducer).userWallets;
	const navigate = useNavigate();

	const cancelTransaction = (id) => {
		axiosWithAuth().post("transactions/cancel",{
			transactionId:id
		}).then(res=>{
			if(res.status === 200){
				// setTransactions(transactions.filter(t => t.uuid !== id))\
				fetchTransactions()
				setViewTransaction(null)
			}
		})
	}

const getNewBalance = () => {
	const senderIdbalance = userWallets?.filter(({uuid}) => uuid === cancelItem.senderId)[0]["balance"];
	return Number(senderIdbalance) + Number(cancelItem.amount)
}

	const toggleWallet = (id) => {
		dispatch(selectWallet(id));
	}


	return (
		<div className="Transactions">
			<Header title={"Mis Transacciones"} subtitle={selectedWallet.uuid}/>
		
			<div className="wrapper">
				<div className="options">
					<div className="row -wrap -fw">
						<div className="search">
							<InputField
								name="search"
								value={query}
								setValue={(nVal) => {setQuery(nVal)}}
								slot={<Button variant="tertiary" action={handleSearch}>
									Buscar
								</Button>}
							/>
						</div>
						<div className="walletTypes">
							{userWallets?.map(wallet => (
								<Button 
								variant={selectedWallet?.uuid===wallet.uuid?"primary":"secondary"}
								key={wallet.uudid}
								action={()=>{toggleWallet(wallet.uuid)}}>
									{wallet.walletType}
								</Button>
							))}
						</div>
					</div>


					<div className="row -wrap -fw gp-1">
						<DateRange
							dateRange={dateRange}
							setDateRange={setDateRange}
						/>

						<div className="viewType row -fw -wrap">
							<div 
								className={`viewTypeIcon ${viewType==="list"?"active":""}`}
								onClick={()=>{setViewType("list")}}
							>
								<ListIcon/>
								
							</div>
							<div
								className={`viewTypeIcon ${viewType==="table"?"active":""}`}
								onClick={()=>{setViewType("table")}}
							>
								<TableIcon/>	
							</div>
						</div>

						<div className="statuses row gp-1">
							<Checkbox
								label="Completedas"
								value={status==="completed"}
								action={()=>{setStatus("completed")}}
							/>
							<Checkbox
								label="Pendientes"
								value={status==="pending"}
								action={()=>{setStatus("pending")}}
							/>
							<Checkbox
								label="Canceladas"
								value={status==="cancelled"}
								action={()=>{setStatus("cancelled")}}
							/>
						</div>
					</div>
				</div>
			
				<TransactionDispatcher
					type={viewType}
					transactions={transactions}
					setViewTransaction={setViewTransaction}
					setCancelItem={setCancelItem}
					setVerifyItem={setVerifyItem}
					pagination={pagination}
					setPage={setPage}
					status={status}
					selectedWallet={selectedWallet}			
				/>

			</div>

			{viewTransaction?
				<TransactionModal transaction={viewTransaction} setViewTransaction={setViewTransaction} cancelTransaction={setCancelItem}/>
			:null}

			{cancelItem?
				<Confirmation
					title="Desea cancelar esta transaccion?"
					description={`La cantidad de "${formatCurrency(cancelItem.amount)}" sera regresada a la wallet "${cancelItem.senderId}"?\nLa nueva cantidad sera ${formatCurrency(getNewBalance())}`}
					closeModal={()=>{setCancelItem(null)}}
					accept={()=>{cancelTransaction(cancelItem.uuid)}}
				/>
			:null}
			{verifyItem?
				<Confirmation
					title="Desea verficiar esta transaccion?"
					description="Seras redirigido a la pagina de verificacion"
					closeModal={()=>{setVerifyItem(null)}}
					accept={()=>{navigate("/transactions/confirm",{ 
						state:verifyItem
					})}}
				/>
			:null}

		</div>
	);
}

export default Transactions;

export function TransactionDispatcher({type="list",...props}){
	if(type==="list"){
		return <TransactionsList {...props}/>
	}else{
		return <TransactionsTable {...props}/>
	}
}