import React from "react";
import {Table,TableItem} from "../Table";
import Pagination from "components/Pagination/Pagination";
import Button from "components/Button/Button";
import "./Transactions.scss";
import { ReactComponent as EyeIcon } from "../../../assets/icons/visibilityIcon.svg";

import {formatCurrency,formatDate} from "Utils/TextFormat";
import {ArrowIcon} from "assets";


const columns = {
	"":"",
	uuid:"id",
	senderId:"Emisor",
	receiverId:"Receptor",
	amount:"Cantidad",
	currentBalance:"Balance actual",
	lastBalance:"Balance antiguo",
	" ":"Tipo",
	description:"Descripcion",
	createdAt:"Fecha de creacion",
	"A":" "
}

export function TransactionsTable({
	transactions,
	setViewTransaction,
	setCancelItem,
	setVerifyItem,
	pagination,
	setPage,
	status,
	selectedWallet
}){

	return (<>
		<Table columns={columns}>
			{transactions.map(transaction => <TableItem key={transaction.uuid}>
				<td className="link" onClick={()=>{
					setViewTransaction(transaction)
				}}>
					<EyeIcon/>
				</td>
				<td>{transaction.uuid}</td>
				<td>{transaction.senderId}</td>
				<td>{transaction.receiverId}</td>
				<td>{formatCurrency(transaction.amount)}</td>
				<td>{formatCurrency(transaction.currentBalance)}</td>
				<td>{formatCurrency(transaction.lastBalance)}</td>
				<td className="description">{String(transaction.description).split("\n")[0]}</td>
				<td>{transaction.senderId === selectedWallet.uuid?"Envio":"Recarga"}</td>
				<td>{formatDate(transaction.createdAt)}</td>
				<td className="actions">
				{status==="pending"?<>
					<Button className="verify" variant="secondary" action={()=>{setVerifyItem(transaction)}}>Verificar</Button>
					<Button variant="secondary" action={()=>{setCancelItem(transaction)}}>Eliminar</Button>
				</>:null}
				</td>
			</TableItem>)}	
		</Table>
		
		{pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null}
	</>
	)
		
}

export function TransactionsList({
	transactions,
	setViewTransaction,
	pagination,
	setPage,
	selectedWallet
}){

	return (<>
		<div className="list-wrapper">
			{transactions.map(transaction => <div key={transaction.uuid} className="list-item"
				onClick={()=>{setViewTransaction(transaction)}}
			>
				<div className="transactionInfo">
					<div className="author">
						{transaction.senderId}
					</div>

					<div className="date">
						{formatDate(transaction.createdAt)}
					</div>
				</div>

				<div className="balanceInfo">
					<div className={`ammount ${transaction.senderId === selectedWallet.uuid?"payment":"charge"}`}>
						{transaction.senderId === selectedWallet.uuid?"-":""}
						{formatCurrency(transaction.amount)}
					</div>
					<div className="newBalance">
						{formatCurrency(transaction.currentBalance)}
					</div>
				</div>

				<div className="icon-wrapper">
					<ArrowIcon/>
				</div>

			</div>)}
		</div>


		{pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null}
	</>)
}