import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import UsersReducer from "./UsersReducer";
import RolesReducer from "./RolesReducer";
import WalletReducer from "./WalletsReducer";
import ProductsReducer from "./ProductsReducer";
import SystemReducer from "./SystemReducer";


const combinedReducers = combineReducers({
	UserReducer,
	UsersReducer,
	RolesReducer,
	WalletReducer,
	ProductsReducer,
	SystemReducer
})

export default combinedReducers;