import {popAlert, removeAlert} from "Actions/SystemActions";
import React,{useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTimeout} from "Utils/hooks";
import "./Alerts.scss"

const lifetime = 1000;

function AlertArea() {
	const alerts = useSelector(store=>store.SystemReducer).alerts;
	const [inTimer,setInTimer] = useState(null); // Defines how long the message will appear on screen, add 1s to animation pop in
	const [outTimer,setOutTimer] = useState(null); // Used to animate alert hide
	const dispatch = useDispatch();

	useEffect(()=>{
		if(!inTimer && alerts.length){
			setInTimer(lifetime*1.5);
		}
	},[alerts]);

	useTimeout(()=>{
		setOutTimer(lifetime); // Always keep at 1s or change animation time in css aswell
		setInTimer(null);
	},inTimer);

	useTimeout(()=>{
		setOutTimer(null);
		dispatch(popAlert());
	},outTimer);

	if(alerts.length > 0){
		return (
			<div className="Alert-area">
				{alerts.map((alert,i)=><Alert key={i} removedAnim={(i==0 && outTimer)?true:false} type={alert.type} text={alert.text} />)}
			</div>
		);
	}else{
		return null;
	}

}

export default AlertArea;

function Alert({text,type="normal",removedAnim}){
	return (<div className={`Alert-wrapper ${type} ${removedAnim?"fadeOut":""}`}>
		<p>{text}</p>
	</div>)
}