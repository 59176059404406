import React, {useState} from "react";
import Header from "components/Header";
import { ReactComponent as Logo } from "../../assets/logos/login-logo.svg";
import "./Send.scss";
import Button from "components/Button";
import InputField from "components/InputField";
import { useNavigate } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import Textarea from "components/Textarea";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";

const subtitle = {
	"abbacoin":{
		2:"Puedes poner el wallet id o el correo de la persona",
		3:"Motivo de la transaccion",
		4:"Verifica los detalles de la transaccion",
	},
	"products":{
		2:"Lamentablemente esta opcion todavia no esta implementada"
	},
	null:"Para continuar escoge una opcion"
}
const title = {
	"abbacoin":"Enviar abbacoins",
	"products":"Enviar productos",
	null:"Enviar"
}

function Send() {

	const [sendOption,setSendOption] = useState(null);
	const [walletId,setWalletId] = useState("");
	const [description,setDescription] = useState("");
	const [quantity,setQuantity] = useState(0);
	const [phase,setPhase] = useState(1);

	const selectedWallet = useSelector(store=>store.UserReducer).selectedWallet;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const renderPhase = () => {
		if(phase === 2){
			return <Phase2 selectedWallet={selectedWallet} phase={phase} next={next} sendOption={sendOption} walletId={walletId} setWalletId={setWalletId} quantity={quantity} setQuantity={setQuantity}/>;
		}else if(phase === 3){
			return <Phase3 phase={phase} next={next} description={description} setDescription={setDescription}/>;
		}else if(phase === 4){
			return <Phase4 selectedWallet={selectedWallet} phase={phase} send={send} walletId={walletId} setWalletId={setWalletId} quantity={quantity}/>;
		}else{
			return <Phase1 selectedWallet={selectedWallet} phase={phase} next={next} sendOption={sendOption} setSendOption={setSendOption}/>;
		}
	}

	const next = () => {
		if(phase<4){
			setPhase(phase+1);
		}else{
			setPhase(1);
		}
	}

	const send = () => {
		axiosWithAuth().post("transactions", {
			receiverId:walletId,
			senderId:selectedWallet.uuid,
			amount:quantity,
			description
		}).then(res=>{
			if(res.status===201){
				dispatch(addAlert({text:"Transaccion creada. Por favor transaccion usando el codigo enviado a su correo", type:"success"}))
				navigate("/transactions/confirm",{ 
					state:res.data
				})
			}
		})
	}

	const previous = () => {
		if(phase===1){
			// We could use navigate(-1) but it will return to an empty page is there is no history;
			navigate("/dashboard", {replace: true});
		}else{
			setPhase(phase-1);
		}
	}
	
	return (
		<div className="Send">
			<div className="border-background">
				<div className="wrapper">
					<Logo/>
					<Header title={title[sendOption]} subtitle={phase>1?subtitle[sendOption][phase]:subtitle[null]} alignText="center"/>					
					{renderPhase()}
				</div>
			</div>
			<div className="back-button-wrapper">
				<Button className="back" variant="tertiary" action={previous}>Regresar</Button>
			</div>
		</div>
	);
}

export default Send;

const Phase1 = ({next,setSendOption,sendOption}) => {
	return (<>
		<div className="options">
			<Button className={sendOption==="abbacoin"?"selected":""} action={()=>{setSendOption("abbacoin")}}>Abbacoins</Button>
			{/* <Button className={sendOption==="products"?"selected":""} action={()=>{setSendOption("products")}}>Productos</Button> */}
		</div>
		<Button className="submit" disabled={!sendOption} action={next}>Siguiente</Button>
	</>)
}

const Phase2 = ({next,sendOption,walletId,setWalletId,quantity,setQuantity,selectedWallet}) => {
	if(sendOption==="abbacoin"){
		return <form action="sendAbbacoin" className="walletId">
			<InputField
				label="Enviar a"
				value={walletId}
				setValue={setWalletId}
			/>
			<div className="quantity-input">
				<InputField
					label="Cantidad"
					value={quantity}
					setValue={setQuantity}
					min={0}
					max={selectedWallet.balance}
					type="Number"
				/>
				<p className="available-quantity">Abbacoins Disponibles: ${selectedWallet.balance}</p>
			</div>
			<Button className="submit" disabled={!walletId || !quantity} action={next}>Siguiente</Button>

		</form>
	}else{
		return (<>
			<p>Aun no implementado</p>
		</>)
	}

}

const Phase3 = ({next,description,setDescription}) => {
	return <div  className="description">
		<Textarea
			value={description}
			setValue={setDescription}
		/>
		<Button className="submit" action={next} disabled={!description || description?.length<5}>Siguiente</Button>
	</div>
}

const Phase4 = ({send,walletId,quantity,selectedWallet}) => {
	return <div  className="verify">
		<div className="receiver">Destinatario <span className="walletId">{walletId}</span></div>
		<div className="quantity">${quantity}</div>
		<p className="available-quantity">Nuevos abbacoins Disponibles: ${selectedWallet.balance - quantity}</p>
		<Button className="submit" action={send}>Enviar</Button>
	</div>
}