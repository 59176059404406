import React, {useState,useEffect} from "react";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Header from "components/Header";
import Checkbox from "components/Checkbox";
import {ProductsTable,OrdersTable} from "components/Table";
import "./Products.scss";
import OrderModal from "Modals/OrderModal";
import {useDispatch, useSelector} from "react-redux";
import {addAlert} from "Actions/SystemActions";


function Products() {
	const [showHistory,setShowHistory] = useState(false);
	const [products,setProducts] = useState([]);
	const [orders,setOrders] = useState([]);
	const [pagination, setPagination] = useState({});
	const [page, setPage] = useState(1);
	const [viewOrder,setViewOrder] = useState(null);
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const dispatch = useDispatch();

	useEffect(()=>{
		// As this could change multiple times and will not be used elsewhere I decided to keep it here instead of putting it into redux
		fetchUserOrders();
		fetchUserTotalProducts();
	},[selectedOrganization.id,page]);

	const fetchUserTotalProducts = () => {
		axiosWithAuth().get("/users/products",{
			params:{
				organizationId: selectedOrganization?.id
			}
		}).then(res=>{
			setProducts(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				dispatch(addAlert({
					text:"Parece que no tienes ningun producto comprado para esa organizacion"
				}));
				setProducts([]);
			}else{
				console.log(err)
				dispatch(addAlert({
					text:"Ha ocurrido un error. Intenta mas tarde", 
					type:"error"
				}))
			}
		})
	}

	const fetchUserOrders = () => {
		// Todo - merge this with admin api call. Do it as a dispatcher look into orders for ideas
		axiosWithAuth().get("/users/orders",{
			params:{
				organizationId: selectedOrganization?.id,
				page
			}
		}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}))
			setOrders(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setOrders([]);
			}
		})
	}

	return (
		<div className="User-Products">
			<Header title={"Ordenes de compra"}/>
			<div className="wrapper">
				<div className="options">
					{/* <div className="search">
						<InputField
							name="search"
							value={query}
							setValue={(nVal) => {setQuery(nVal)}}
						/>
					</div> */}
					<div className="statuses">
						<Checkbox
							label="Mostrar total de productos"
							value={showHistory}
							toggleValue={setShowHistory}
						/>
					</div>
				</div>

				{showHistory?
					<ProductsTable 
						products={products}
					/>
				:<>
					<OrdersTable
						orders={orders}
						pagination={pagination}
						setViewOrder={setViewOrder}
						setPage={setPage}
					/>
				</>}

				{viewOrder?
					<OrderModal
						order={viewOrder}
						setViewOrder={setViewOrder}
					
					/>
				:null}

			</div>

		</div>
	);
}

export default Products;
