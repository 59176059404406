import {addAlert} from "Actions/SystemActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import PaymentModal from "components/PaymentModal/PaymentModal";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Navigate, useParams} from "react-router";

function PaymentPage() {

	const {paymentId} = useParams();
	const [payment,setPayment] = useState(null);
	const disapatch = useDispatch();

	useEffect(()=>{
		axiosWithAuth().get(`/payments/${paymentId}`).then(res => {
			setPayment(res.data);
		}).catch(err => {
			// In case of error redirect
			console.log(err);
		})
	},[paymentId]);

	const handlePayment = (walletId) => {
		axiosWithAuth().post(`payments/complete/${payment.uuid}`,{
			walletId
		}).then(res => {
			window.location.href = res.data.successUrl
		}).catch(err => {
			if(err?.response?.status === 409){
				if(err.response.data?.code=="NoFunds"){
					disapatch(addAlert({type:"error", text:"La wallet que has selecionado no tiene suficiente fondos. Por favor refresca la pagina para actualizar tu balance."}))
				}else if(err.response.data?.code=="AlreadyPaid"){
					disapatch(addAlert({type:"success", text:"Pago ya realizado. Si no eres redirigido vuelve a la tienda para ver tu pago."}))
				}
			}else if(err?.response?.status==403){
				disapatch(addAlert({type:"error", text:"El pago no esta vinculado a tu cuenta."}))
			}else if(err?.response?.status==410){
				disapatch(addAlert({type:"error", text:"El pago ha expirado."}))
			}else{
				disapatch(addAlert({type:"error", text:"Ha ocurrido un error. Contacta a un adminsitrador"}))
				console.log(err);
			}
		})
	}

	if(!payment){
		// Todo - handle 404
		return <div className="notFound">
			Cargando...
		</div>
	}

	if(payment.status === "paid"){
		disapatch(addAlert({type:"success", text:"Pago ya realizado"}))
		return <Navigate to={`/payments?paymentId=${payment.uuid}`}/>
	}

	return (
		<div className="PaymentPage">
			<PaymentModal isFullPage paymentInfo={payment} handlePayment={handlePayment}/>
		</div>
	);
}

export default PaymentPage;