import { useSelector } from "react-redux";

const usePermissions = () => {
	const permissions = useSelector(store => store.UserReducer.permissions)

	const hasPermission = (name) => {
		if(!permissions) return false
		return permissions.some(permission => permission.name === name && permission.assigned);
	};

	return { hasPermission };
};

export default usePermissions;
