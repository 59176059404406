import {addAlert} from "Actions/SystemActions";
import {UserInfoContext} from "Context/AdminDashboardContext";
import {formatDate} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Confirmation from "components/Confirmation";
import Dropdown from "components/Dropdown";
import Header from "components/Header";
import InputField from "components/InputField";
import Modal from "components/Modal";
import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";

function UserPhoneModal({phone, closeModal}){
	
	const [newPhone,setNewPhone] = useState(phone);
	const [showVerify,setShowVerify] = useState(null);
	const { fetchUserInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const { userId } = useParams();
	

	const updatePhone = (newPhone) => {
		if(!newPhone){
			return null;
		}
		if(String(newPhone).charAt(0) !== '+'){
			newPhone = '+' + newPhone;
		}

		axiosWithAuth().patch("users/admin/"+userId+"/phone",{
			phone:newPhone
		}).then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:`Usuario editado de manera exitosa`, type:"success"}));
				fetchUserInfo();
				closeModal()
			}
		}).catch(err => {
			if(err.response.status === 409){
				dispatch(addAlert({text:`Ese correo electronico ya esta en uso`, type:"error"}));
			}else{
				console.log(err);
				dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}));
			}
		})
	}


	return <Modal className="editPhoneModal">
		<div className="card p-1">
			<Header title="Editar telefono"/>

			<InputField.PhoneInput
				phone={newPhone}
				setPhone={(nVal)=>{setNewPhone(nVal)}}
			/>

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={()=>{setShowVerify(true)}}
					disabled={phone === `+${newPhone}`}
				>Aceptar</Button>
			</div>
		</div>

		{showVerify && <Confirmation
			title={"Deseas cambiar el numero de telefono?"}
			description={"El usuario dejara de recibir SMS hasta que el numero de telefono sea verificado."}
			accept={()=>{
				setShowVerify(false);
				updatePhone(newPhone);
			}}
		></Confirmation>}

	</Modal>
}

function UserEmailModal({email, closeModal}){
	
	const [newEmail,setNewEmail] = useState(email);
	const [showVerify,setShowVerify] = useState(null);
	const { fetchUserInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const { userId } = useParams();

	const updateEmail = () => {
		if(!newEmail){
			return null;
		}

		axiosWithAuth().patch("users/admin/"+userId+"/email",{
			email:newEmail
		}).then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:`Usuario editado de manera exitosa`, type:"success"}));
				fetchUserInfo();
				closeModal();
			}
		}).catch(err => {
			if(err.response.status === 409){
				dispatch(addAlert({text:`Ese correo electronico ya esta en uso`, type:"error"}));
			}else{
				console.log(err);
				dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}));
			}
		})
	}
	
	return <Modal className="editEmailModal">
		<div className="card p-1">
			<Header title="Editar correo electronico"/>

			<InputField
				name="email"
				label="Correo Electronico"
				value={newEmail}
				setValue={(nVal)=>{setNewEmail(nVal)}}
			/>

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={()=>{setShowVerify(true)}}
					disabled={email === newEmail}
				>Aceptar</Button>
			</div>
		</div>

		{showVerify && <Confirmation
			title={"Deseas cambiar el correo electronico?"}
			description={"El usuario podria perder acceso si has ingresado un correo electronico invalido."}
			accept={()=>{
				updateEmail()
			}}
			closeModal={()=>{setShowVerify(false)}}
		></Confirmation>}

	</Modal>
}

function UserAbbasoonCodeModal({abbasoonCode, closeModal}){

	const [newAbbasoonCode,setNewAbbasoonCode] = useState(abbasoonCode?.toUpperCase());
	const [showVerify,setShowVerify] = useState(null);
	const { fetchUserInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const { userId } = useParams();

	const updateAbbasoonCode = () => {
		if(!newAbbasoonCode){
			return null;
		}

		axiosWithAuth().patch("users/admin/"+userId+"/abbasoonCode",{
			abbasoonCode:newAbbasoonCode
		}).then(res => {
			if(res.status === 200){
				dispatch(addAlert({text:`Usuario editado de manera exitosa`, type:"success"}));
				closeModal(false);
				fetchUserInfo();
			}
		}).catch(err => {
			if(err.response.status === 409){
				dispatch(addAlert({text:`Ese codigo abbasoon ya esta en uso`, type:"error"}));
			}else{
				console.log(err);
				dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}));
			}
		})
	}

	return <Modal className="editAbbasoonCodemodal">
		<div className="card p-1">
			<Header title="Editar codigo de abbasoon"/>

			<InputField
				name="abbasoon_code"
				label="Codigo "
				value={newAbbasoonCode}
				setValue={(nVal)=>{setNewAbbasoonCode(nVal.toUpperCase())}}
				autoComplete={false}
			/>

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={()=>{setShowVerify(true)}}
					disabled={abbasoonCode === newAbbasoonCode}
				>Verificar y guardar</Button>
			</div>
		</div>

		{showVerify && <Confirmation
			title={"Deseas cambiar el codigo abbasoon?"}
			description={"Asegurate de que escribes el codigo bien."}
			accept={()=>{
				setShowVerify(false);
				updateAbbasoonCode(newAbbasoonCode);
			}}
			closeModal={()=>{setShowVerify(false)}}
		></Confirmation>}

	</Modal>
}

function UserReferrerIdModal({referrerId, closeModal}){

	const [newReferrerId,setNewReferrerId] = useState(referrerId?.toUpperCase());
	const [showVerify,setShowVerify] = useState(null);
	const { fetchUserInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const { userId } = useParams();
	

	const updateReferrerId = () => {
		if(!newReferrerId){
			return null;
		}

		axiosWithAuth().patch("users/admin/"+userId+"/referrerId",{
			referrerId:newReferrerId
		}).then(res => {
			if(res.status === 200){
				fetchUserInfo()
				dispatch(addAlert({text:`Usuario editado de manera exitosa`, type:"success"}));
				closeModal(false);
			}
		}).catch(err => {
			if(err.response.status === 409){
				dispatch(addAlert({text:`Ese codigo abbasoon no existe`, type:"error"}));
			}else{
				console.log(err);
				dispatch(addAlert({text:`Ha ocurrido un error`, type:"error"}));
			}
		})
	}

	return <Modal className="editReferrerIdModal">
		<div className="card p-1">
			<Header title="Editar codigo de referido"/>

			<InputField
				name="referrerCode"
				label="Codigo Referido"
				value={String(newReferrerId).toUpperCase()}
				setValue={(nVal)=>{setNewReferrerId(nVal.toUpperCase())}}
				autoComplete={false}
			/>

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={()=>{setShowVerify(true)}}
					disabled={referrerId === newReferrerId}
				>Verificar y guardar</Button>
			</div>
		</div>

		{showVerify && <Confirmation
			title={"Deseas cambiar el codigo de referido?"}
			description={"Asegurate de que escribes el codigo bien de lo contrario toda la linea de este referido podria verse afectada."}
			accept={()=>{
				setShowVerify(false);
				updateReferrerId(newReferrerId);
			}}
			closeModal={()=>{setShowVerify(false)}}
		></Confirmation>}

	</Modal>
}

function UserFieldsModal({closeModal}){

	const { userInfo,fetchUserInfo } = useContext(UserInfoContext); // Access userInfo from UserInfoContext
	const dispatch = useDispatch();
	const { userId } = useParams();
	const [newData,setNewData] = useState({
		fullName:userInfo.fullName,
		dob:userInfo.dob,
		gender: userInfo.gender,
		username: userInfo.username,
		city: userInfo.city,
		state: userInfo.state,
		country: userInfo.country,
	});

	const editFields = () => {
		axiosWithAuth().patch("users/admin/"+userId, newData).then(res=>{
			if(res.status === 200){
				dispatch(addAlert({text:"Usuario editado correctamente", type:"success"}));
				fetchUserInfo();
				closeModal();
			}
		}).catch(err=>{
			if(err?.response?.status === 404){
				dispatch(addAlert({text:"Usuario no encontrado", type:"error"}));
			}else if(err?.response?.status === 409){
				if(err.response?.data?.resource == 'email'){
					dispatch(addAlert({text:"Ya existe un usuario con ese correo.", type:"error"}));
				}else  if(err.response?.data?.resource == 'ref'){
					dispatch(addAlert({text:"El codigo de referido no existe.", type:"error"}));
				}else if(err.response?.data?.resource === 'code'){
					dispatch(addAlert({text:"Ya existe un usuario con ese codigo.", type:"error"}));
				}else{
					dispatch(addAlert({text:"Los datos no pueden ser validados. Revisalos de nuevo.", type:"error"}));
				}	
			}
		})
	}

	return <Modal className="editReferrerIdModal">
		<div className="card p-1">
			<Header title="Editar campos"/>
			



			<div className="row -wrap-tablet gp-1">
				<InputField
					name="fullName"
					label="Nombre completo"
					value={newData?.fullName}
					setValue={(nVal)=>{setNewData({...newData,fullName:nVal})}}
				/>
				<InputField
					name="username"
					label="Username"
					value={newData?.username}
					setValue={(nVal)=>{setNewData({...newData,username:nVal})}}
				/>
			</div>

			<div className="row -wrap-tablet gp-1">
				<InputField
					name="city"
					label="Ciudad"
					value={newData.city}
					setValue={(nVal) => {setNewData({...newData, city:nVal})}}
				/>
				<InputField
					name="state"
					label="Estado"
					value={newData.state}
					setValue={(nVal) => {setNewData({...newData, state:nVal})}}
				/>
				<InputField
					name="country"
					label="Pais"
					value={newData.country}
					setValue={(nVal) => {setNewData({...newData, country:nVal})}}
				/>
			</div>


			<div className="row wrap gp-1">
				<InputField
					name="dob"
					label="Fecha nacimiento"
					value={formatDate(newData.dob || null,"isoDateOnly")}
					setValue={(nVal)=>{setNewData({...newData,dob:nVal})}}
					type="date"
				/>
			</div>

			<div className="row gp-1 fw wrap dropdown">
				<Dropdown title={
							newData.gender==="M"?"Masculino":
							newData.gender==="F"?"Femenino":
							newData.gender==="X"?"Otro"
							:"Sexo"
					} variant="secondary">
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'M'})}}
							selected={newData.gender==='M'}
							>Masculino</Dropdown.Item>
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'F'})}}
							selected={newData.gender==='F'}
							>Femenino</Dropdown.Item>
						<Dropdown.Item 
							action={()=>{setNewData({...newData,gender:'X'})}}
							selected={newData.gender==='X'}
							>Otro</Dropdown.Item>
				</Dropdown>
			</div>

			<div className="row gp-1 actions">
				<Button
					className="secondary"
					action={closeModal}
				>Cancelar</Button>
				<Button
					action={editFields}
				>Guardar</Button>
			</div>

		</div>

	</Modal>
}

const UserPasswordModal = ({closeModal}) => {


	const [showConfirm,setShowConfirm] = useState(false);
	const [newPassword,setNewPassord] = useState('');
	const [password,setPassword] = useState(null); // Stores new generated password from server
	const [showPassword,setShowPassword] = useState(false);
	const [option,setOption] = useState("auto");
	const dispatch = useDispatch();
	const { userId } = useParams();

	const getNewPassword = () => {

		axiosWithAuth().patch(`auth/admin/${userId}/password`).then(res=>{	
			if(res.status === 201){
				setPassword(res.data);
			}
		}).catch(err=>{
			dispatch(addAlert({text:`Ha ocurrido un error`}));
		})
	}

	const copyText = text => {
		navigator.clipboard.writeText(text);
		dispatch(addAlert({ text:`Contraseña copiada`}));
	}	


	if(password){
			return (<Modal className="editReferrerIdModal">
				<div className="card p-1">
					<Header title="Nueva contraseña"/>
		
					<div className="passwordReset-info">
						<InputField
							name="password"
							readOnly
							value={password}
							type={showPassword?"text":"password"}
							slot={<Button variant="primary" action={()=>copyText(password)}>Copiar</Button>}
						/>
						<Checkbox value={showPassword} toggleValue={setShowPassword} label="Mostrar contraseña"/>
					</div>
	
					<div className="actions">
						<Button variant="secondary" action={closeModal}>Cerrar</Button>
					</div>
				</div>
			</Modal>)
		
	}


	return (<Modal className="editReferrerIdModal">
		<div className="card p-1">
			<Header title="Reestablecer contraseña"/>
			


			<div className="passwordReset-options">
				<Checkbox
					label="Autogenerada"
					value={option==="auto"}
					action={()=>{setOption("auto")}}
				/>
				<Checkbox
					label="Introducir manualmente (Proximamente)"
					value={option==="manual"}
					action={()=>{setOption("manual")}}
					readonly
				/>
			</div>



			<div className="row gp-1 actions">
				<Button variant="secondary" action={()=>{closeModal(null)}}>Cancelar</Button>
				<Button variant="primary" action={()=>setShowConfirm(true)}>Reestablecer</Button>
			</div>

		</div>

		{showConfirm?
			<Confirmation
				title="Esta seguro de que desea reestablecer la contraseña"
				description={`El usuario debera ser notificado manualmente`}
				closeModal={()=>{setShowConfirm(false)}}
				accept={getNewPassword}
			/>

		:null}

	</Modal>)

}

export {
	UserPhoneModal,
	UserEmailModal,
	UserAbbasoonCodeModal,
	UserReferrerIdModal,
	UserFieldsModal,
	UserPasswordModal
}