import React, {useState} from "react";
import Button from "components/Button";
import AuthWrapper from "../AuthWrapper";
import InputField from "components/InputField";
import "./ForgotPassword.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";
import {useNavigate} from "react-router";


function ForgotPassword() {

	const [email,setEmail] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const requestCode = (e) => {
		e.preventDefault();
		if(email){
			axiosWithAuth().post("auth/password/forgot",{email}).finally(()=>{
				dispatch(addAlert({text:"Se han enviado instrucciones para el reinicio de la contraseña"}));
				navigate("/forgot_password/code?code=")
			});
		}
	}

	return (
		<AuthWrapper useContainer>
			<div className="ForgotPassword">
		<div className="header">
			<h1 className="title">Recuperacion<br/>de la cuenta</h1>
			<p className="subtitle">Para proteger tu cuenta, Es nesesario verificar que eres tú<br/> quien está intentando iniciar sesión</p>
		</div>

		<form className="requestCode" action="requestCode" onSubmit={requestCode}>
			<div className="inputs">
				<InputField
					name="email"
					value={email}
					setValue={(nVal) => setEmail(nVal)}
					label="Correo electronico"
					type="email"
				/>
			</div>
			<div className="cta-actions">
				<Button className="sendCode" variant="secondary" type="submit">
					Enviar
				</Button>
			</div>
		</form>

	</div>

		</AuthWrapper>
	);
}

export default ForgotPassword;
