import {axiosWithAuth} from "Utils/axiosWithAuth";
import Button from "components/Button";
import Header from "components/Header";
import {TableItem} from "components/Table";
import {Table} from "components/Table";
import React, {useEffect, useState} from "react";

function Webhooks() {

	const [events,setEvents] = useState([]);
	const [isLoading,setIsLoading] = useState(false);

	useEffect(()=>{
		fetchWebhookEvents()
	},[]);

	const fetchWebhookEvents = () => {
		setIsLoading(true);
		axiosWithAuth().get('/webhooks',{
			params:{
				query: 'status:failed'
			}
		}).then(res=>{
			setEvents(res.data);
			console.log(res);
		}).catch(err=>{
			console.log(err);
		}).finally(() => {
			setIsLoading(false);
		})
	}

	const retry = (id) => {
		setIsLoading(true);
		axiosWithAuth().post('/webhooks/'+id).then(res=>{
			console.log(res);
		}).catch(err=>{
			console.log(err);
		}).finally(() => {
			setIsLoading(false);
		})

	
		// TODO -> This will only create a post for ONE event, using name on the body and not passing an id we can retry all of type
	}


	return (
		<div className="Webhooks">

			<Header title="Webhooks"/>

			<div className="content-left">
				{/* Controls */}
				{/* Table */}
				<div className="actions">
					
				</div>
				<Table columns={["id", "type", "status",""]}>
					{events.map(e => <TableItem key={e.id}>
						<td>
							{e.id}
						</td>
						<td>
							{e.payload.type}
						</td>
						<td>
							{e.status}
						</td>
						
						{e.status === "success" || 
							<td>
								<Button 
									disabled={isLoading || e.status === "success"} 
									action={() => {retry(e.id)}}
								>
									Retry
								</Button>
							</td>
						}
					</TableItem>)}
				</Table>
			</div>

		</div>
	);
}

export default Webhooks;