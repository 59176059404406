import React from "react";
import "./Textarea.scss";

function TextArea({
	label,
	value,
	setValue,
	name,
	cols="30", 
	rows="3", 
	maxLength,
	error,
	onChange
}) {


	const handleChange = (e) => {
		e.preventDefault();
		if(onChange){
			onChange(e);
		}else{
			setValue(e.target.value)
		}
	}

	return (
		<div className="textarea-wrapper">

			{label?<div className="label">
				{label}  
				{error?<span className="error"> - {error}</span>:null}
			</div>:null}

			<textarea
				value={value}
				name={name}
				onChange={handleChange}
				cols={cols}
				rows={rows}
				maxLength={maxLength}
			/>

			{maxLength?<span className="wordCount">{String(value).length}/{maxLength}</span>:null}
		</div>
	);
}

export default TextArea;