import React from "react";
import {Table} from "components/Table";
import Pagination from "components/Pagination/Pagination";
import {TableItem} from "../Table";
import {formatDate} from "Utils/TextFormat";
import { EyeIcon } from "assets";

export function OrdersTable({orders,pagination,setPage,setViewOrder}) {
	return (
		<>
			<Table columns={{
				"":"",
				id:"ID",
				orderId:"Order ID",
				status:"Estado",
				description:"Descripcion",
				createdAt:"Fecha de creacion",
			}}>
				
				{orders.map(o=> <TableItem key={o.orderId}>
					<td className="link" onClick={()=>{
						setViewOrder(o)
					}}>
						<EyeIcon/>
					</td>
					<td>{o.id}</td>
					<td>{o.orderId}</td>
					<td>{o.status}</td>
					<td>{o.description}</td>
					<td>{formatDate(o.createdAt)}</td>
			
				</TableItem>	
				)}


			</Table>

		{pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null}
		</>
	);
}
