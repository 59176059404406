import React, {useEffect, useState} from "react";
import Header from "components/Header";
import {Table, TableItem} from "components/Table";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Pagination from "components/Pagination";
import {EyeIcon} from "assets";
import Modal from "components/Modal";
import InputField from "components/InputField";
import {formatDate} from "Utils/TextFormat";
import Textarea from "components/Textarea";

function Log() {

	const [logs,setLogs] = useState([]);
	const [page,setPage] = useState(1);
	const [pagination,setPagination] = useState(null);
	const [showActionModal,setShowActionModal] = useState(null)

	useEffect(()=>{
		axiosWithAuth().get("/adminLog",{
			params:{
				page
			}
		}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}));
			setLogs(res.data);
		})
	},[page]);


	return (
		<div className="Log">
			<Header title={"Historial de acciones"} subtitle={""}/>
			<div className="wrapper">
				<Table columns={{
					"":"",
					"userId":"userId",
					"table":"Tabla",
					"action":"Accion",
					"value":"Valor",
					"createdAt":"Creado en"
				}} >
					{logs.map(row=>{
						return <TableItem key={row.id}>
							<td onClick={()=>{setShowActionModal(row)}}>
								<EyeIcon/>
							</td>
							<td>{row.userId}</td>
							<td>{row.table}</td>
							<td>{row.action}</td>
							<td className="value">{JSON.stringify(row.value)}</td>
							<td>{new Date(row.createdAt).toLocaleString()}</td>
						</TableItem>
					})}
				</Table>

				{pagination?.lastPage > 1?
					<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
				:null}

				{showActionModal?
					<Modal 
						className="actionModal" 
						closeModal={()=>{setShowActionModal(null)}}
						showCloseIcon
					>
						<ActionModal adminAction={showActionModal}/>
					</Modal>
				:null}

			</div>
		</div>
	);
}

export default Log;

function ActionModal({adminAction}){

	return <div className="actionModal-content">
		<div className="row gp-1">
			<InputField
				label="Action Id"
				value={adminAction.id}
				readOnly
			/>
			<InputField
				label="User Id"
				value={adminAction.userId}
				readOnly
			/>
		</div>

		<div className="row gp-1">
			<InputField
				label="Recurso"
				value={adminAction.table}
				readOnly
			/>
			<InputField
				label="Accion"
				value={adminAction.action}
				readOnly
			/>
		</div>

		<InputField
			label="Fecha creacion"
			value={formatDate(adminAction.createdAt)}
			readOnly
		/>

		<Textarea
			label="Datos"
			value={JSON.stringify(adminAction.value,null,2)}
			rows={10}
		/>
		

	</div>
}