import React, {useEffect} from "react";
import Header from "components/Header";
import InputField from "components/InputField";
import "./FamilyCard.scss";
import {useState} from "react";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {Link} from "react-router-dom";

function FamilyCard() {

	const [data,setData] = useState({});

	useEffect(()=>{
		axiosWithAuth().get("/FamilyCard").then(res=>{
			setData(res.data);
		}).catch(err=>{
			if(err?.repsonse?.status===404){
				console.log("NOT FOUND");
			}
		})
	},[]);

	return (	
		<div className="FamilyCard">
			<Header title={"Family Card"}/>
			<div className="content">
				<InputField
					label="Identificador"
					value={data.nit}
					readonly
				/>
				<InputField
					label="Numero poliza"
					value={data.policy}
					readonly
				/>
				<InputField
					label="Correo electronico"
					value={data.email}
					readonly
				/>
				{/* <InputField
					label="Ciudad"
					value={data.city}
					readonly
				/> */}
				<InputField
					label="Ciudad"
					value={data.city}
					readonly
				/>
				<div className="split">
					<InputField
						label="Fecha nacimiento"
						value={(new Date(data.dob).toLocaleDateString())}
						readonly
					/>
					<InputField
						label="Numero de telefono"
						value={data.phone}
						readonly
					/>
					<InputField
						label="Sexo"
						value={data.gender}
						readonly
					/>
					<InputField
						label="Estado"
						value={data.family_card?"Contratado":"No contratado"}
						readonly
					/>
					<InputField
						label="Fecha vigencia desde"
						value={(new Date(data.issuedAt).toLocaleDateString())}
						readonly
					/>
					<InputField
						label="Fecha vigencia hasta"
						value={(new Date(data.expiresAt).toLocaleDateString())}
						readonly
					/>
				</div>


				{data.family_card?
					<div className="link">
						<a href="https://www.familycardservices.com/sunrisecare" target="_blank" rel="noreferrer" >
							Visita la plataforma
						</a>
					</div>
				:null}

			</div>
		</div>
	);
}

export default FamilyCard;