import React from "react";
import {Table} from "components/Table";
import Pagination from "components/Pagination/Pagination";
import {TableItem} from "../Table";

export function ProductsTable({products,pagination,setPage}) {
	return (
		<>
			<Table columns={{
				"productId":"Id",
				"name":"Nombre",
				"total":"Cantidad total"
			}}>
				
				{products.map(p=> <TableItem key={p.productId}>
					<td>{p.productId}</td>
					<td>{p.name}</td>
					<td>{p.total}</td>
				</TableItem>	
				)}


			</Table>

		{/* {pagination?.lastPage > 1?
			<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
		:null} */}
		</>
	);
}
