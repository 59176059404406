import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
// import Card from "components/Card/Card.js";
import Button from "components/Button";
import InputField from "components/InputField";
import Textarea from "components/Textarea";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SendIcon } from "../../assets/icons/sendIcon.svg";
import "./Dashboard.scss";
import {useDispatch, useSelector} from "react-redux";
import {fetchLoggedUserWallets, selectWallet} from "Actions/UserActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {addAlert} from "Actions/SystemActions";
import {formatCurrency} from "Utils/TextFormat";
import Dropdown from "components/Dropdown";
import Confirmation from "components/Confirmation/Confirmation";
import {TransactionsIcon} from "assets";
import classNames from "classnames";


function Card({children,size,className}) {
	return (
		<div className={`card ${size} ${className}`}>
			{children}
		</div>
	);
}

function Dashboard() {
	return (
		<div className="Dashboard">
			<Header title="Dashboard" subtitle={"Bienvenido a tu oficina virtual!"}/>

			<div className="content-wrapper">
				{/* Move to other components ideally */}
				<Card className="balance">
					<Balance/>
				</Card>
				<Card className="quick-transaction" size="lg">
					<QuickTransaction/>
				</Card>
				<Card className="link send">
					<Link to="/send">
						<SendIcon/>
						<p>Enviar fondos</p>
					</Link>
				</Card>
				<Card  className="info">
					<Info/>
				</Card>
				<Card  className="link transactions">
					<Link to="/transactions">
						<TransactionsIcon/>
						<p>Transacciones</p>
					</Link>
				</Card>
				<Card className="shares">
					<Shares/>
				</Card>
				<Card  className="link help">
					<Link to="/send">
						<ComingSoonCard/>
					</Link>
				</Card>
			</div>
		</div>
	);
}

export default Dashboard;

const Balance = () => {
	
	const {userWallets,selectedWallet} = useSelector(store=>store.UserReducer);
	const dispatch = useDispatch();

	const toggleWallet = (id) => {
		dispatch(selectWallet(id));
	}

	useEffect(()=>{
		if(userWallets[0] && !selectedWallet){
			dispatch(selectWallet(userWallets[0].uuid));
		}
		// eslint-disable-next-line
	},[userWallets]);

	useEffect(()=>{
		dispatch(fetchLoggedUserWallets());
	// eslint-disable-next-line
	},[])


	if(!Object.keys(selectedWallet).length){
		return <>
			<p>Por favor selecciona una organizacion en la que tengas wallets</p>
		</>
	}

	return (<>
		<h1 className="card-title">
			Balance general - <span className="wallet-name">{selectedWallet?.walletType}</span>
		</h1>	
		<h1 className="card-balance">
			{formatCurrency(selectedWallet?.balance)}
		</h1>

		<div className="walletTypes">
			{userWallets?.map(wallet=> 
				<Button 
					className={`Wallet-primary ${selectedWallet?.uuid===wallet.uuid?"selected":""}`} 
					key={wallet.uudid}
					action={()=>{toggleWallet(wallet.uuid)}}
				>
					{wallet.walletType}
				</Button>)}
		</div>
	</>
	);
}

const Info = () => {

	const selectedWallet = useSelector(store=>store.UserReducer.selectedWallet);

	if(selectedWallet){
		return (<>
			<h1 className="card-title">Informacion general</h1>	
			<p className="description">{selectedWallet?.description}</p>
			<p className="id">id: {selectedWallet?.uuid}</p>
		</>
		)
	}else{
		return (<>
			<h1 className="card-title">Informacion general</h1>	
			<p className="description">Primero selecciona una wallet</p>
		</>)
	}

	
}

const QuickTransaction = () => {

	const [receiverData,setReceiverData] = useState("");
	const [quantity,setQuantity] = useState(0);
	const [description,setDesciption] = useState("");
	const [sendConfirm,setSendConfirm] = useState(false);
	const [senderOption,setSenderOption] = useState("WalletId");
	const selectedWallet = useSelector(store=>store.UserReducer).selectedWallet;
	
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const sendTransaction = () => {
		const getObjectKey = () => {
			switch(senderOption){
				case 'WalletId':
					return "receiverId"
				case 'UserId':
					return "receiverUserId"
				case 'AffiliateCode':
					return "receiverAbbasoonCode"
				case 'Email':
					return "receiverEmail"
			}	
		}

		axiosWithAuth().post("transactions", {
			[getObjectKey()]:receiverData,
			senderId:selectedWallet.uuid,
			amount:quantity,
			description
		}).then(res=>{
			if(res.status===201){
				dispatch(addAlert({text:"Transaccion creada. Por favor transaccion usando el codigo enviado a su correo", type:"success"}))
				navigate("/transactions/confirm",{ 
					state:res.data
				})
			}
		}).catch(err => {
			if(err.response.status === 404){
				dispatch(addAlert({text:"No se ha encontrado el destinatario", type:"error"}))
			}else if(err.response.status === 429){
				dispatch(addAlert({text:"Espera un momento e intenta de nuevo", type:"error"}))
			}else if(err.response.status === 409){
				dispatch(addAlert({text:"La cantidad que intentas enviar es superior al balance", type:"error"}))
			}else{
				console.log(err)
				dispatch(addAlert({text:"Ha ocurrido un error. Intenta de nuevo mas tarde", type:"error"}))
			}
		})
	}

	if(!Object.keys(selectedWallet).length){
		return <>
			<h1 className="card-title">Envio rapido</h1>	
			<p>Primero seleciona una organizacion que tenga wallets</p>
		</>
	}

	return (<>
		<h1 className="card-title">Envio rapido</h1>	
		<form onSubmit={(e)=>{
			e.preventDefault()
			sendTransaction()
			// setSendConfirm(true) NOT Working mobile
		}}>

			<div className="row">
				<DropdownWrapper 
					setSenderOption={setSenderOption}
					senderOption={senderOption}
					type="mobile"
				/>

				<InputField 
					name="id"
					value={receiverData}
					setValue={setReceiverData}
					label={`Enviar a`}
					type={
						senderOption==="Email"?"email":"text"
					}
					slot={
						<DropdownWrapper 
							setSenderOption={setSenderOption}
							senderOption={senderOption}
							type="desktop"
						/>
					}
				/>
			</div>
			
			<InputField 
				name="quantity"
				value={quantity}
				setValue={setQuantity}
				label="Cantidad"
				type="number"
				max={Number(selectedWallet.balance)}
				min={0}
				step="any"
			/>
			<Textarea 
				label="Descripcion"
				value={description}
				setValue={setDesciption}
			/>

			<p className="total">Total: {quantity}</p>

			<Button type="submit">
				Enviar
			</Button>
		</form>


		{sendConfirm?<Confirmation
				title="Confirmar transaccion"
				description={`Esta seguro que desea enviar la cantidad de ${formatCurrency(quantity)} a "${receiverData}"?`}
				closeModal={()=>{
					setSendConfirm(false);
				}}
				accept={()=>{
					sendTransaction()
				}}
		/>:null}
	</>
	);
}

function DropdownWrapper({setSenderOption,senderOption,type}){

	const getSenderText = () => {
		switch(senderOption){
			case 'WalletId':
				return "Wallet id"
			case 'UserId':
				return "User id"
			case 'AffiliateCode':
				return "Codigo de afiliado"
			case 'Email':
				return "Correo"
		}
	}

	return (
		<Dropdown
			variant="secondary"
			title={getSenderText()}
			className={classNames("receiverType", type)}
		>
			<Dropdown.Item
				action={() => {
					setSenderOption("WalletId");
				}}
				selected={senderOption === "WalletId"}
			>
				Wallet id
			</Dropdown.Item>

			<Dropdown.Item
				action={() => {
					setSenderOption("UserId");
				}}
				selected={senderOption === "UserId"}
			>
				User Id
			</Dropdown.Item>

			<Dropdown.Item
				action={() => {
					setSenderOption("AffiliateCode");
				}}
				selected={senderOption === "AffiliateCode"}
			>
				Codigo Afiliado
			</Dropdown.Item>

			<Dropdown.Item
				action={() => {
					setSenderOption("Email");
				}}
				selected={senderOption === "Email"}
			>
				Email
			</Dropdown.Item>
		</Dropdown>
	);
}

const Shares = () => {
	// Todo, find a better way to write this
	const shares = useSelector(store=>store.UserReducer.userShares);

	return (<>
		<h1 className="card-title">
			Acciones
		</h1>	
		<h1 className="card-balance">
			{shares.map(s => <p>
				{s.balance} - {s.organization}
			</p>)}
		</h1>
	</>
	);
}

function ComingSoonCard(){
	return <div className="comingSoon">
		Proximamente
	</div>
}