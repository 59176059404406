import { useState, useEffect } from "react";
import axios from "axios";
import {axiosWithAuth} from "Utils/axiosWithAuth";

const useIntegrateSystemData = (dataType, intType, isAdmin, userId) => {
	
	const endpoint = isAdmin
	? `/integratesystem/p/${intType}/admin/${userId}/${dataType}`
	: `/integratesystem/p/${intType}/${dataType}`;
	
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [pagination, setPagination] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchData = async (page) => {
		setLoading(true);
		setError(null);

		try {
			const response = await axiosWithAuth().get(endpoint, {
				params: { page },
			});
			setData(response.data);
			setPagination(JSON.parse(response.headers["pagination"]) || {});
		} catch (err) {
			console.log(err);
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData(page);
	}, [page, intType, isAdmin, userId]);


	const nextPage = () => {
		setPage(page + 1)
	}

	const prevPage = () => {
		setPage(page - 1);
	}

	return {
		data,
		page,
		setPage,
		pagination,
		loading,
		error,
		nextPage,
		prevPage
	};
};

export default useIntegrateSystemData;
