/**
 * This manages data across multiple admin dashboard widgets and children
 * We decided to use this context instead of redux state as this will be localized in a handfull components
 */

import {addAlert} from "Actions/SystemActions";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import React, { createContext, useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

// UserInfo context
const UserInfoContext = createContext();
const UserInfoProvider = ({ children }) => {
	const [userInfo, setUserInfo] = useState(null);
	const { userId } = useParams();
	
	const fetchUserInfo = () => {
		axiosWithAuth().get("/users/admin",{
			params:{
				id:userId
			}
		}).then(res=>{
			setUserInfo(res.data[0]);
		}).catch(err=>{
			console.log(err)
		})
	};

	useEffect(()=>{
		fetchUserInfo();	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])



	return (
		<UserInfoContext.Provider
			value={{ userInfo, fetchUserInfo }}
		>
			{children}
		</UserInfoContext.Provider>
	);
};

export { UserInfoContext, UserInfoProvider };

// Wallet context
const WalletContext = createContext();
const WalletProvider = ({ children }) => {

	const [selectedWallet, setSelectedWallet] = useState(null);
	const [wallets, setWallets] = useState([]);
	const [recentTransactions, setRecentTransactions] = useState([]);
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);
	const { userId } = useParams();
	const dispatch = useDispatch();


	useEffect(()=>{
		if(selectedWallet?.uuid){
			fetchRecentTransactions();
		}else{
			setRecentTransactions([]);
		}
	},[selectedWallet])

	useEffect(()=>{
		fetchWallets();
	},[selectedOrganization]);

	const fetchRecentTransactions = () => {
		setRecentTransactions([]); // Necessary to clean older trnasaction
	
		axiosWithAuth().get("/transactions/"+selectedWallet.uuid,{
			params:{
				resultsPerPage: 3,
				status: "completed"
		}}).then(res=>{
			setRecentTransactions(res.data);
		}).catch(err=>{
			if(err.response.status === 404){
				setRecentTransactions([]);				
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
				console.log(err);
			}
		})
	}

	const fetchWallets = () => {
		axiosWithAuth().get("/users/wallets/"+userId,{params:{
			organizationId: selectedOrganization?.id
		}}).then(res=>{
			setWallets(res.data);
			setSelectedWallet(res.data[0]);
		}).catch(err=>{
			setWallets([]);
			setSelectedWallet(null)

			if(err?.response?.status === 404){
				setWallets([]);
			}else{
				dispatch(addAlert({text:"Ha ocurrido un error"}))
				console.log(err)
			}
		})
	};

	return (
		<WalletContext.Provider value={{ wallets, selectedWallet, setSelectedWallet, recentTransactions }}>
			{children}
		</WalletContext.Provider>
	);
};

// Shares context
const SharesContext = createContext();
const SharesProvider = ({ children }) => {
	const [shares, setShares] = useState([]);
	const { userId } = useParams();
	const selectedOrganization = useSelector(store=>store.SystemReducer.selectedOrganization);

	useEffect(()=>{
		fetchShares()
		// No need to import fetchshares...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[selectedOrganization])

	const fetchShares = () => {
		axiosWithAuth().get(`/users/admin/${userId}/shares`,{params:{
			showHistory:true,
			organizationId: selectedOrganization?.id
		}}).then(res=>{
			setShares(res.data);
		}).catch(err=>{
			console.log(err)
		})
	};

	return (
		<SharesContext.Provider value={{ shares, fetchShares }}>
			{children}
		</SharesContext.Provider>
	);
};

// AdminDashboard context combining both
const AdminDashboardProvider = ({ children }) => {
	return (
		<UserInfoProvider>
			<WalletProvider>
				<SharesProvider>{children}</SharesProvider>
			</WalletProvider>
		</UserInfoProvider>
	);
};

export { AdminDashboardProvider, WalletContext, SharesContext };
