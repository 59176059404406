
import { ReactComponent as EyeIcon } from "./icons/visibilityIcon.svg";
import { ReactComponent as 	LoginIllustration} from "./illustrations/login.svg";
import { ReactComponent as 	AvatarIllustration} from "./illustrations/avatar-sample.svg";
import { ReactComponent as 	CrossIcon} from "./icons/crossIcon.svg";
import { ReactComponent as 	ArrowIcon} from "./icons/arrowIcon.svg";
import { ReactComponent as 	ListIcon} from "./icons/listIcon.svg";
import { ReactComponent as 	TableIcon} from "./icons/tableIcon.svg";
import { ReactComponent as 	LockIcon} from "./icons/lockIcon.svg";
import { ReactComponent as 	MenuIcon} from "./icons/menuIcon.svg";
import { ReactComponent as 	CopyIcon} from "./icons/copyIcon.svg";


import { ReactComponent as 	AdminIcon} from "./icons/adminIcon.svg";
import { ReactComponent as 	AdminLogIcon} from "./icons/adminLogIcon.svg";
import { ReactComponent as 	AffiliatesIcon} from "./icons/affiliatesUserIcon.svg";
import { ReactComponent as 	AffiliatesUserIcon} from "./icons/affiliatesUserIcon.svg";
import { ReactComponent as 	AffiliationCodesIcon} from "./icons/affiliationCodesIcon.svg";

import { ReactComponent as 	UsersIcon} from "./icons/usersIcon.svg";
import { ReactComponent as 	RolesIcon} from "./icons/rolesIcon.svg";
import { ReactComponent as 	WalletTypeIcon} from "./icons/walletTypeIcon.svg";
import { ReactComponent as 	ProductsIcon} from "./icons/productsIcon.svg";
import { ReactComponent as 	ContactRequestIcon} from "./icons/contactRequestIcon.svg";
import { ReactComponent as 	WithdrawalsIcon} from "./icons/withdrawalsIcon.svg";
import { ReactComponent as 	IntegrateSystemIcon} from "./icons/integrateSystem.svg";
import { ReactComponent as 	EditIcon} from "./icons/editIcon.svg";
import { ReactComponent as 	TransactionsIcon} from "./icons/transactionsIcon.svg";
import { ReactComponent as 	DasboardIcon} from "./icons/dashboardIcon.svg";
import { ReactComponent as 	MyAffiliatesIcon} from "./icons/myAffiliatesIcon.svg";
import { ReactComponent as 	SettingsIcon} from "./icons/settingsIcon.svg";
import { ReactComponent as 	ChangePasswordIcon} from "./icons/changePasswordIcon.svg";
import { ReactComponent as 	SessionsIcon} from "./icons/sessionsIcon.svg";
import { ReactComponent as 	MyProductsIcon} from "./icons/myProductsIcon.svg";
import { ReactComponent as 	SharesHistoryIcon} from "./icons/sharesHistoryIcon.svg";
import { ReactComponent as 	PermissionsIcon} from "./icons/permissionsIcons.svg";

const NavigationIcons = {
	Admin:{
		Admin: AdminIcon,
		Log: AdminLogIcon,
		AffiliateUser: AffiliatesUserIcon,
		AffiliateCodes: AffiliationCodesIcon,
		Products: ProductsIcon,
		ContactRequest: ContactRequestIcon,
		Withdrawals: WithdrawalsIcon,
		IntegrateSystem: IntegrateSystemIcon,
		Permissions: PermissionsIcon,
		Roles: RolesIcon,
	},
	Users: UsersIcon,
	WalletType: WalletTypeIcon,
	Withdrawals: WithdrawalsIcon,
	Transactions: TransactionsIcon,
	Dashboard: DasboardIcon,
	Affiliates: MyAffiliatesIcon,
	Products: MyProductsIcon,
	SharesHistory: SharesHistoryIcon,
	ContactRequestIcon,
	Settings: {
		Settings: SettingsIcon,
		ChangePassword: ChangePasswordIcon,
		Sessions: SessionsIcon
	}
}

const Illustrations = {
	Avatar: AvatarIllustration
}

export{
	EyeIcon,
	LoginIllustration,
	CrossIcon,
	ArrowIcon,
	ListIcon,
	TableIcon,
	LockIcon,
	NavigationIcons,
	MenuIcon,
	EditIcon,
	TransactionsIcon,
	Illustrations,
	CopyIcon
}