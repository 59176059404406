import {
	FETCH_WALLET_TYPES_START,
	FETCH_WALLET_TYPES_SUCCESS,
	FETCH_WALLET_TYPES_FAILURE,
	UPDATE_WALLET_TYPE_START,
	UPDATE_WALLET_TYPE_SUCCESS,
	UPDATE_WALLET_TYPE_FAILURE,
} from "../Actions/WalletsActions.js"

const initialState = {
	walletTypes:[],
	error:null,
	getStatus:null,
	postStatus:null,
	response: null,
}

const WalletReducer = (state=initialState,action) => {
	switch(action.type){
		case FETCH_WALLET_TYPES_START:
			return {...state, getStatus:true, response:null, error:null}
		case FETCH_WALLET_TYPES_SUCCESS:
			return {...state, getStatus:false, walletTypes:action.payload.data, response:action.payload.response}
		case FETCH_WALLET_TYPES_FAILURE:
			return {...state, getStatus:false, error:action.payload.data, response:action.payload.response}

		case UPDATE_WALLET_TYPE_START:
			return {...state, getStatus:true, response:null, error:null}
		case UPDATE_WALLET_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				walletTypes: state.walletTypes.map(walletType =>
					walletType.id === action.payload.data.id ? action.payload.data : walletType
				),
			};
		case UPDATE_WALLET_TYPE_FAILURE:
			return {...state, getStatus:false, error:action.payload.data, response:action.payload.response}

		default:
			return state;
	}
}

export default WalletReducer;