import Button from "components/Button";
import Confirmation from "components/Confirmation";
import React,{useState} from "react";
import {useNavigate} from "react-router";
import "./AuthWrapper.scss";

function AuthWrapper({children}) {

	const [showConfirm,setShowConfirm] = useState(false);
	const navigate = useNavigate();

	const navigateHome = () => {
		if(String(window.location.pathname).split("/").includes("register")){
			setShowConfirm(true);
		}else{
			navigate("/");
		}
	}

	return (
		<div className="Authwrapper">
			<div className="container">
				<div className="container-inner">
					{children}
				</div>
			</div>

			<div className="return">
				<Button action={navigateHome} variant="tertiary">
					Regresar a inicio
				</Button>
			</div>

			{showConfirm?<Confirmation
				title="Desea regresar al inicio?"
				description={`Se perderan todos los datos agregados`}
				closeModal={()=>{
					setShowConfirm(false);
				}}
				accept={()=>{
					navigate("/")
				}}
			/>:null}

		</div>
	);
}

export default AuthWrapper;