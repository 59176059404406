import React from "react";
import "./System.scss";
import {Route, Routes, useLocation} from "react-router";
import OrganizationsWidget from "./Widgets/Organizations";
import OrganizationsPage from "./Subpages/Organizations";
import AdminLogWidget from "./Widgets/AdminLog";
import Log from "./Subpages/Log";
import WebhooksWidget from "./Widgets/Webhooks";
import Webhooks from "./Subpages/Webhooks";
import {Link} from "react-router-dom";


function System() {
	const location = useLocation();

	return (
		<div className="System">
			<div className="navigator">
				{location.pathname !== "/admin/system" && <Link to="/admin/system">
					Volver atras
				</Link>}
			</div>

			<div className="content">
				<Routes>
					<Route path="log" element={<Log/>}/>
					<Route path="webhook" element={<Webhooks/>}/>
					<Route path="organizations" element={<OrganizationsPage/>}/>
					<Route exact path = "/" element={<MainMenu/>}/>
				</Routes>
			</div>
		</div>
	);
}

export default System;

const MainMenu = () => {
	return <div className="mainMenu">
		<OrganizationsWidget/>
		<AdminLogWidget/>
		<WebhooksWidget/>
	</div>
}