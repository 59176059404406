import React,{useEffect,useState} from "react";
import {addAlert} from "Actions/SystemActions";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Header from "components/Header";
import {TableItem,Table} from "components/Table";
import {useDispatch} from "react-redux";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copyIcon.svg";
import "./AffiliationCodes.scss";

const columns = {
	'id':'id',
	'value':'Codigo',
	'createdAt':'Creado en',
	'valid':'Utilizado',
}

function AffiliationCodes() {

	const [codes,setCodes] = useState([]);
	const dispatch = useDispatch();

	useEffect(()=>{
		axiosWithAuth().get("auth/codes/registration").then(res=>{
			if(res.status === 200){
				setCodes(res.data);
			}
		}).catch(err=>{
			dispatch(addAlert({ type:"error", text:`Ha ocurrido un error`}));
		})
	},[])

	const createCode = () => {
		axiosWithAuth().post("auth/codes/registration").then(res=>{
			console.log(res);
			if(res.status === 201){
				dispatch(addAlert({ type:"success", text:`Codigo de afiliacion generado. ${res.data.value}`}));
				setCodes([res.data,...codes])
			}
		}).catch(err=>{
			dispatch(addAlert({ type:"error", text:`Ha ocurrido un error`}));
		})
	}

	const copyText = (text) => {
		navigator.clipboard.writeText(text);
		dispatch(addAlert({ text:`Codigo copiado`}));
	}

	return (
		<div className="AffiliationCodes">
			<Header title={"Codigos de afiliacion"}/>
			<div className="wrapper">
				{/* Top actions to generate new code */}
				<div className="options">
					<Button action={createCode}>
						Crear codigo
					</Button>
				</div>

				{/* Render all codes here as a table */}
				<div className="data">
					<Table columns={columns} data={codes}>
				
						{codes.map(row=><TableItem key={row.id}>
							<td>
								{row.id}	
							</td>
							<td className="code">
								<span onClick={()=>{copyText(row.value)}}>
									{row.value}
									<CopyIcon/>
								</span>
							</td>
							<td>
								{new Date(row.createdAt).toLocaleString()}
							</td>
							<td>	
								<Checkbox value={row.valid} readonly/>
							</td>
						</TableItem>)}

					</Table>

					{!codes.length?
						<div className="noData">
							<p>Parece que aun no hay ningun codigo</p>
							<Button action={createCode} variant="tertiary">
								Crear codigo
							</Button>
						</div>:null
					}
				</div>

			</div>
		</div>
	);
}

export default AffiliationCodes;