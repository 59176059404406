
import {UserInfoContext} from "Context/AdminDashboardContext";
import {AdminDashboardProvider} from "Context/AdminDashboardContext";
import Button from "components/Button";
import React, {useContext} from "react";
import UserInfoPanel from "./Widgets/UserInfo";
import "./User.scss"
import WalletPanel from "./Widgets/Wallet";
import {WalletContext} from "Context/AdminDashboardContext";
import SharesPanel from "./Widgets/Shares";
import OrdersPanel from "./Widgets/Orders";
import ReferrersPanel from "./Widgets/Referrers";

function User() {

	return (<AdminDashboardProvider>
		<div className="User">

			<div className="main">
				{/* Todo work as grid to have better control */}
					<SharesPanel/>
					<ReferrersPanel/>
					<OrdersPanel/>
				<WalletPanel/>

			</div>
		

			<div className="right">
				<UserInfoPanel/>

			</div>

		</div>
	</AdminDashboardProvider>);
}

export default User;
