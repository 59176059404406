import Header from "components/Header";
import React,{useEffect,useState} from "react";
import { ReactComponent as Logo } from "../../assets/logos/login-logo.svg";
import {useLocation, useNavigate} from "react-router";
import InputField from "components/InputField";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrowIcon.svg";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./ConfirmTransaction.scss";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";
import Button from "components/Button/Button";

function ConfirmTransaction() {

	const [transactionData,setTransactionData] = useState({});
	const [code,setCode] = useState("");
	const [error,setError] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {state} = useLocation();

	useEffect(()=>{
		if(state?.uuid){
			setTransactionData(state);
		}
	},[state]);

	useEffect(()=>{
		if(code.length<8 && error){
			setError(null)
		}
	},[code])

	useEffect(()=>{
		if(!transactionData?.senderId && transactionData.uuid){
			axiosWithAuth().get("transactions/transaction/"+transactionData.uuid).then(res=>{
				setTransactionData(res.data);
			})
		}
	},[transactionData.uuid])

	useEffect(()=>{
		if(code.length===8){
			axiosWithAuth().post("transactions/complete",{code,transactionId:transactionData.uuid}).then(res=>{
				if(res.status===200){
					dispatch(addAlert({text:"Transaccion verificada!", type:"success"}))
					navigate("/dashboard");
				}
			}).catch(err=>{
				if(err?.response?.status === 409){
					setError("Codigo no valido")
				}
			})
		}
	},[code])

	const resendVerificationCode = () => {
		axiosWithAuth().get(`transactions/${transactionData.uuid}/send_verification`).then(res=>{
			if(res.status===200){
				dispatch(addAlert({text:"Codigo enviado de nuevo", type:"success"}))
			}
		}).catch(err=>{
			console.log(err)
			dispatch(addAlert({text:"Ha ocurrido un error", type:"error"}))
		})
	}

	return (
		<div className="Confirm">
			<div className="border-background">
				<div className="wrapper">
					<Logo/>
					<Header title="Verificar transaccion" subtitle="Debes introducir el codigo enviado a tu correo"/>
					<Body transactionData={transactionData} setTransactionData={setTransactionData} code={code} setCode={setCode} error={error}/>

					<div className="bottom-wrapper">
						<Button action={resendVerificationCode} variant="tertiary">
							Reenviar codigo
						</Button>
					</div>
				
				</div>
			</div>

		</div>
	);
}

export default ConfirmTransaction;

function Body({transactionData,setTransactionData,code,setCode,error}){

	console.log({error});

	const [isExpanded,setIsExpanded] = useState(false);

	if(transactionData?.uuid && transactionData?.senderId){
		return <div className="body">
			<InputField
				label="Identificador de transaccion"
				value={transactionData.uuid}
				readonly
			/>
			<div className={`other-details ${isExpanded?"expanded":""}`}>
				<div className="menu-header"  onClick={(e)=>{e.stopPropagation();setIsExpanded(!isExpanded)}}>
					<h3>Mas detalles</h3>
					<ArrowIcon/>
				</div>
				<div className="scrollable">
					<InputField
						label="Receptor"
						value={transactionData.receiverId}
						readonly
					/>
					<InputField
						label="Emisor"
						value={transactionData.senderId}
						readonly
					/>
					<InputField
						label="Creado en"
						value={(new Date(transactionData.createdAt)).toLocaleString()}
						readonly
					/>
					<InputField
						label="Cantidad"
						value={transactionData.amount}
						readonly
					/>
					<InputField
						label="Estado"
						value={transactionData.status}
						readonly
					/>
				</div>
			</div>

			<InputField
				label="Codigo de verificacion"
				value={code}
				setValue={setCode}
				maxLength={8}
				error={error}
			/>

		</div>
	}else{
		return <div className="body">
			<InputField
				label="Introduce tu codigo de transaccion"
				value={transactionData.uuid}
				setValue={(nVal)=>{setTransactionData({...transactionData,uuid:nVal})}}
			/>
		</div>
	}
}