import {addAlert} from "Actions/SystemActions";
import Header from "components/Header";
import {TableItem} from "components/Table";
import {Table} from "components/Table";
import React,{useEffect,useState} from "react";
import {useDispatch} from "react-redux";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import "./MkRemoveHistory.scss";

function MkRemoveHistory() {
	
	const [rHistory,setRHistory] = useState([]);
	const dispatch = useDispatch();

	useEffect(()=>{
		axiosWithAuth().get("/old/mk_remove_history").then(res=>{
			setRHistory(res.data);
		}).catch(err=>{
			if(err?.response?.status === 404){
				dispatch(addAlert({text:"Historial no encontrado para dicho usuario", type:"error"}));
			}
		})
	},[])

	
	return (
		<div className="MkRemoveHistory">
			<Header title="Historial recargas ADUS CI"/>
			<div className="wrapper">
				<Table columns={{
					removed_amount:"Monto cobrado",
					previous_amount:"Saldo anterior",
					description:"Descripcion",
					created:"Creado",
					updated:"Modificado",
				}} data={rHistory}>
					{rHistory.map(row => <TableItem key={row.id}>
						<td>{row.removed_amount}</td>
						<td>{row.previous_amount}</td>
						<td className="description">
							<p>{row.description}</p>
						</td>
						<td>{row.created}</td>
						<td>{row.updated}</td>
					</TableItem>)}

				</Table>
			</div>
		</div>
	);
}

export default MkRemoveHistory;