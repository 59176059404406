import React, {useEffect, useState} from "react";
import { EyeIcon } from "assets";
import {Table} from "components/Table";
import {TableItem} from "components/Table";
import Checkbox from "components/Checkbox/Checkbox";
import "./ContactPage.scss";
import Header from "components/Header/Header";
import Modal from "components/Modal/Modal";
import InputField from "components/InputField/InputField";
import TextArea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import Pagination from "components/Pagination/Pagination";
import {useDispatch} from "react-redux";
import {addAlert} from "Actions/SystemActions";

function ContactPage() {


	const [contacts,setContacts] = useState([]);
	const [contactModal,setContactModal] = useState(false);
	const [pagination,setPagination] = useState(null);
	const [page,setPage] = useState(null);
	const dispatch = useDispatch();

	useEffect(()=>{
		getContactRequests();
	},[page]);

	const getContactRequests = () => {

		axiosWithAuth().get("/contact/requests",{
			params:{
				page
			}
		}).then(res=>{
			setPagination(JSON.parse(res.headers["pagination"] || {}))
			setContacts(res.data);
		}).catch(err => {
			if(err.response.status === 429){
				dispatch(addAlert({type:"error", text:"Estas yendo muy rapido. Intentalo de nuevo mas tarde"}))
			}
		})
	}

	return (
		<div className="ContactAdminPage">

			<Header
				title="Usuarios a contactar"
			/>

			<Table
				columns={{
					"":"",
					"name":"nombre",
					"email":"Correo electronico",
					"referredBy":"En contacto con",
					"contacted":"Contactado"
				}}
			>
				{contacts.map(contact=>{
					return <TableItem>
						<td className="view" onClick={()=>{setContactModal(contact)}}>
							<EyeIcon/>
						</td>
						<td>
							{contact.name}
						</td>
						<td>
							{contact.email}
						</td>
						<td>
							{contact.referredBy}
						</td>
						<td>
							<Checkbox
								value={contact.contacted}
								readOnly
							/>
						</td>
					</TableItem>
				})}
			</Table>
			{pagination?.lastPage > 1?
				<Pagination page={Number(pagination.currentPage)} setPage={setPage} maxPages={pagination.lastPage}/>
			:null}


			{contactModal?
				<ContactModal contact={contactModal} closeModal={()=>{setContactModal(false)}}/>
			:null}

		</div>
	);
}

export default ContactPage;

const ContactModal = ({contact,closeModal}) => {
	
	return <Modal closeModal={closeModal}>
		<div className="contactModal">
			<Header title="Contacto"/>
			<div className="info">
				<div className="row">
					<InputField
						value={contact.name}
						readOnly
						label="Nombre(*)"
						name="name"
					/>
					<InputField
						value={contact.phone}
						readOnly
						label="Telefono(*)"
						name="phone"
						type="phone"
				/>
				</div>
				<InputField
					value={contact.email}
					readOnly
					label="Correo electronico(*)"
					name="email"
					type="email"
				/>
				<InputField
					value={contact.referredBy}
					readOnly
					label="Persona que dio la informacion(*)"
					name="referredBy"
				/>
				<TextArea
					value={contact.comments}
					readOnly
					label="Comentarios"
					name="comments"
					rows={5}
				/>

				<Checkbox
					value={contact.acceptContact}
					label={"Desea ser contactado"}
					readOnly
				/>
			</div>
			<div className="actions">
				<Checkbox
					value={false}
					label={"Fue ya contactado (*)"}
					readOnly
				/>
			</div>

			<div className="bottom-wrapper">
				<Button variant="secondary">
					Cerrar
				</Button>
				<Button>
					Aceptar
				</Button>
			</div>
		</div>
	</Modal>
}